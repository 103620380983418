import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import "../../pagestyles/FeedbackView.css";

// LANGUAGE
import profile_file from "../../lang/profile.json";

// COMPONENTS
import { InfoField } from '../../components/FormComponents';
import { MultilineTextField } from '../../components/TextInputs';
import { BlueButton } from '../../components/Buttons';

// API IMPORT
import UserService from "../../services/UserService";
import EmailService from '../../services/EmailService';

const userService = new UserService();
const emailService = new EmailService();

const profile_lang = profile_file.lang;

export default function FeedbackView({ lang=1 }) {
    const token = useOutletContext(); 

    const [name, setName] = useState("");
    const [mail, setMail] = useState("");

    const [content, setContent] = useState("");
    const [inputError, setInputError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [displaySuccessMsg, setSuccessState] = useState(false);

    useEffect(() => {
        userService.getUser(token, function(response) {
            if(response.user !== undefined) {
                setName(`${response.user.first_name} ${response.user.last_name}`);
                setMail(response.user.email);
            }
        })
    }, []);

    function sendSupportRequest() {
        if(content === "") {
            setErrorMsg(profile_lang[33][lang]);
            setInputError(true);
        } else if(content.length > 10000) {
            setErrorMsg("Пожалуйста, сократите ваш запрос до 10000 знаков");
            setInputError(true);
        } else {
            emailService.sendSupportRequest(name, mail, content, "ru", function(response) {
                setSuccessState(response);
            });
        }
    }

    useEffect(() => {
        if(inputError) {
            if(content.length < 10000 && content !== "") {
                setInputError(false);
            }
        }
    }, [content]);

    return(
        <div className="FeedbackView__root">
            <div className="FeedbackView__header-container">
                <p className="FeedbackView__header-title">{profile_lang[4][lang]}</p>
            </div>

            <div className="FeedbackView__body">
                {!displaySuccessMsg ?
                <>
                    <InfoField
                        label={profile_lang[10][lang]}
                        value={name}
                        editIconHidden
                    />

                    <InfoField
                        label={profile_lang[11][lang]}
                        value={mail}
                        editIconHidden
                    />

                    <MultilineTextField
                        label={profile_lang[31][lang]}
                        placeholder={profile_lang[32][lang]}
                        errorMessage={errorMsg}
                        error={inputError}
                        indicatorShown={false}

                        value={content}
                        onChangeText={setContent}
                    />

                    <BlueButton
                        text={profile_lang[34][lang]}
                        style={{ 
                            width: "232px",
                            alignSelf: "flex-end",
                            marginRight: "32px",
                            marginBottom: "32px",
                            marginTop: "16px"
                        }}
                        textStyle={{ letterSpacing: "0.32px" }}
                        onClick={() => sendSupportRequest()}
                    />
                </>   
                :
                <>
                    <p className="FeedbackView__success-label">Спасибо за обратную связь!</p>
                    <p className="FeedbackView__success-text">В ближайшее время Вы получите ответ на обращение от специалиста поддержки платформы Fortio. Вы сможете продолжить взаимодействие с поддержкой любым удобным Вам способом.</p>
                    <BlueButton
                        text="Отправить новое обращение"
                        style={{ 
                            width: "calc(100% - 32px)",
                            marginBottom: "32px",
                            marginTop: "14px"
                        }}
                        textStyle={{ letterSpacing: "0.32px" }}
                        onClick={() => {
                            setContent("");
                            setSuccessState(false);
                        }}
                    />
                </> 
                }
            </div>
        </div>
    );
}