import React, { useState, useEffect } from 'react';
import "../../pagestyles/TransactionsView.css";

// ASSETS
import { IoChevronDown } from 'react-icons/io5';

// IMPORT API
import TransactionService from '../../services/TransactionService';
import CompanyService from '../../services/CompanyService';
import { TransactionModal } from '../../components/Modals';

// IMPORT LANGUAGE
import cb_file from "../../lang/cabinet.json";
import mkt_file from "../../lang/market.json";
import { translateCatAndItem } from '../../services/FirebaseApi';
const cab_lang = cb_file.lang;
const market_lang = mkt_file.lang;

// INIT API
const transactionService = new TransactionService();
const companyService = new CompanyService();

export default function TransactionsView({ token, lang=1 }) {
    const [companyID, setCompanyID] = useState(0);

    const [transactions, setTransactions] = useState([]);
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [prevPage, setPrevPage] = useState(1);
    const [nextPage, setNextPage] = useState(1);
    const [count, setCount] = useState(0);

    const [filters, setFilters] = useState({});

    useEffect(() => {
        if(token !== "") {
            companyService.getCompaniesList(token, function(companies) {
                setCompanyID(companies.companies[0].pk);
                loadFirstPage(companies.companies[0].pk);
            });
        }
    }, []);

    function loadFirstPage(cid) {
        transactionService.getPagedTransactionsList(cid, 1, token, function(response) {
            setTransactions(response.data);
            setCount(response.count);
            setCurrentPage(1);
            setPages(response.numpages);
            setPrevPage(response.prevpage);
            setNextPage(response.nextpage);
        });
    }

    function loadPage(index) {
        
    }

    const onFiltersUpdate = (_filters) => {
        
    }

    function parseEnding(value) {
        if(value === 0) {
            return "ок";
        }

        if(value === 1) {
            return "ка";
        }

        if(value < 5) {
            return "ки";
        }

        return "ок";
    }

    return(
        <div className="TransactionsView__root">
            <div className="TransactionsView__header-container">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="TransactionsView__title-container">
                        <p className="TransactionsView__title">{cab_lang[21][lang]}</p>
                        <p className="TransactionsView__counter">{cab_lang[27][lang]} {count}{/* сдел{parseEnding(count%10)} */}</p>
                    </div>
                </div>
            </div>

            <TransactionTable transactions={transactions} selfID={companyID} token={token} lang={lang} />
        </div>
    );
}

function TransactionTable({ transactions, selfID, token, lang=1 }) {
    const [transactionModalState, setTransactionModalState] = useState(false);
    const onTransactionModalClose = () => {
        setTransactionModalState(false);
        setCurrentTransaction(null);
    }

    function onTransactionModalOpen(index) {
        let newctx = "";
        if(selfID == transactions[index].started_by) {
            switch(transactions[index].state) {
                case "new":
                    newctx = "none";
                    break;
                case "active":
                    newctx = "active";
                    break;
                case "cancelled":
                    newctx = "cancelled";
                    break;
                default:
                    return;
            }
        } else {
            switch(transactions[index].state) {
                case "new":
                    newctx = "product-accept";
                    break;
                case "active":
                    newctx = "active";
                    break;
                case "cancelled":
                    newctx = "cancelled";
                    break;
                default:
                    return;
            }
        }
        let newtr = Object.assign(transactions[index], {context:newctx});
        console.log(newctx);
        setCurrentTransaction(newtr);
    }

    const [currentTransaction, setCurrentTransaction] = useState(null);
    useEffect(() => {
        if(currentTransaction !== null) {
            setTransactionModalState(true);
        }
    }, [currentTransaction]);

    return(
        <div className="TransactionTable__container">
            <TransactionModal
                visible={transactionModalState}
                onRequestClose={onTransactionModalClose}
                selfID={selfID}
                transactionData={currentTransaction}
                token={token}
                context={currentTransaction !== null ? currentTransaction.context : "none"}
                lang={lang}
            />

            <div className="TransactionTable__header-container">
                <p className="TransactionTable__header-label" style={{marginLeft:"32px"}}>{cab_lang[24][lang]}</p>
                <p className="TransactionTable__header-label">{market_lang[11][lang]}</p>
                <p className="TransactionTable__header-label">{cab_lang[20][lang]}</p>
                <p className="TransactionTable__header-label">{market_lang[21][lang]}</p>
                <p className="TransactionTable__header-label">{cab_lang[25][lang]}</p>
                <p className="TransactionTable__header-label">{cab_lang[22][lang]}</p>
                <p className="TransactionTable__header-label">{cab_lang[26][lang]}</p>
            </div>

            <div className="TransactionTable__items-wrapper">
                {transactions.length > 0 && transactions.map((item, index) => {
                    return(
                        <TransactionTableLine item={item} key={index} selfID={selfID} last={index === transactions.length-1} onTransactionClick={() => onTransactionModalOpen(index)} lang={lang} />
                    );
                })}
            </div>
        </div>
    );
}

function TransactionTableLine({ item, selfID, last, onTransactionClick, lang=1 }) {
    const [opened, setOpened] = useState(false);

    const [translatedName, setTName] = useState("");

    useEffect(() => {
        translateCatAndItem(item.data.name, function(r) {
            setTName(r.nameLang[lang]);
        });
    }, [item]);

    useEffect(() => {
        if(item !== undefined) {
            translateCatAndItem(item.data.name, function(r) {
                setTName(r.nameLang[lang]);
            });
        }
    }, [lang]);

    function parseDate(date) {
        return `${date.slice(8, 10)}.${date.slice(5, 7)}.${date.slice(2, 4)}`
    }

    function parseStatus(value) {
        if(item.started_by == selfID) {
            switch(value) {
                case "new":
                    return ["#161616", lang===0?"Согласование товара":(lang===1?"Product approval":"待商定")];
                case "active":
                    return ["#161616", lang===0?"В работе":(lang===1?"In process":"在工作")];
                case "cancelled":
                    return ["#DA1E28", lang===0?"Отклонена":(lang===1?"Cancelled":"取消")];
                default:
                    return ["#DA1E28", lang===0?"Ошибка":(lang===1?"Error":"错误")];
            }
        } else {
            switch(value){
                case "new":
                    return ["#0F62FE", lang===0?"Получена заявка":(lang===1?"New application":"新申请")];
                case "active":
                    return ["#161616", lang===0?"В работе":(lang===1?"In process":"在工作")];
                case "cancelled":
                    return ["#DA1E28", lang===0?"Отклонена":(lang===1?"Cancelled":"取消")];
                default:
                    return ["#DA1E28", lang===0?"Ошибка":(lang===1?"Error":"错误")];
            }
        }
    }

    const monthlang = {
        "Январь": [
            "Январь",
            "January",
            "1月"
        ],
        "Февраль": [
            "Февраль",
            "February",
            "2月"
        ],
        "Март": [
            "Март",
            "March",
            "3月"
        ],
        "Апрель": [
            "Апрель",
            "April",
            "4月"
        ],
        "Май": [
            "Май",
            "May",
            "5月"
        ],
        "Июнь": [
            "Июнь",
            "June",
            "6月"
        ],
        "Июль": [
            "Июль",
            "July",
            "7月"
        ],
        "Август": [
            "Август",
            "August",
            "8月"
        ],
        "Сентябрь": [
            "Сентябрь",
            "September",
            "9月"
        ],
        "Октябрь": [
            "Октябрь",
            "October",
            "10月"
        ],
        "Ноябрь": [
            "Ноябрь",
            "November",
            "11月"
        ],
        "Декабрь": [
            "Декабрь",
            "December",
            "12月"
        ]
    };

    return(
        <>
            <div className={"TransactionLine__container"+(opened?"-opened":"")} onClick={onTransactionClick}>
                <div className="TransactionLine__header-container">
                    <p className="TransactionLine__label-id" style={{marginLeft:"32px"}}>{item._id}</p>
                    <p className="TransactionLine__label">{translatedName}</p>
                    <p className="TransactionLine__label">{item.data.seller_volume} т</p>
                    <p className="TransactionLine__label">{monthlang[item.data.period1.split(" ")[0]][lang]} {item.data.period1.split(" ")[1]} - {monthlang[item.data.period2.split(" ")[0]][lang]} {item.data.period2.split(" ")[1]}</p>
                    <p className="TransactionLine__label">{item.data.basis}</p>
                    <p className="TransactionLine__label">{parseDate(item.created_at)}</p>
                    <p className="TransactionLine__label-status" style={{color:parseStatus(item.state)[0]}}>{parseStatus(item.state)[1]}</p>

                    <IoChevronDown
                        size={16}
                        color="#0F62FE"
                        className={"TransactionLine__chevron"+(opened ? "-rotated": "")}
                    />
                </div>
            </div>

            {!last && <div className="TransactionLine__divider"/>}
        </>
    );
}