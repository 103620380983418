import React, { useState, useEffect } from 'react';
import "../../pagestyles/FiltersView.css";

// LANGUAGE
import market_file from "../../lang/market.json";

// COMPONENTS
import { BlueButton } from "../../components/Buttons";
import { SliderPickerLarge } from '../../components/SilderPicker';

// ASSETS
import crossIcon from "../../assets/cross.png";
import heartIcon from "../../assets/heart.png";
import { IoChevronDown } from 'react-icons/io5';

// IMPORT API
import { getCategoriesList, getIndexesForItem, indexLanguage } from '../../services/FirebaseApi';

const market_lang = market_file.lang;

export default function FiltersView({ onResponse, style, lang=1 }) {
    const [selectedType, setSelectedType] = useState("Сельскохозяйственная");

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categories, setCategories] = useState([]);
    const [categoryActivityState, setCategoryActivityState] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null);
    const [items, setItems] = useState([]);
    const [itemActivityState, setItemActivityState] = useState(false);

    const [globalSearchValue, setGlobalSearchValue] = useState("");
    const [globalSearchState, setGlobalSearchState] = useState(false);
    const [globalSearchResults, setGlobalSearchResults] = useState([]);

    useEffect(() => {
        getCategoriesList(function(response) {
            setCategories(response);
        });

        indexLanguage(function(response) {
            setIndexLang(response);
        });
    }, []);

    const [indexLang, setIndexLang] = useState({});

    useEffect(() => {
        console.log(globalSearchValue);
        if(globalSearchValue !== "") {
            let res = [];
            for(const cat of categories) {
                for(const item of cat.items) {
                    if(item.lang !== undefined) {
                        if(item.lang[lang].toLowerCase().includes(globalSearchValue.toLowerCase())) {
                            res.push({
                                category: cat,
                                item: item,
                                items: cat.items
                            });
                        }
                    } else {
                        if(item.name.toLowerCase().includes(globalSearchValue.toLowerCase())) {
                            res.push({
                                category: cat,
                                item: item,
                                items: cat.items
                            });
                        }
                    }
                }
            }

            setGlobalSearchResults(res);
        } else {
            setGlobalSearchResults([]);
        }
    }, [globalSearchValue]);

    useEffect(() => {
        if(selectedItem !== null) {
            getIndexesForItem(selectedItem.name, function(response) {
                setItemIndexes(response);
                let _vals = [];
                for(const _i of response) {
                    _vals.push("");
                }
    
                setItemIndexValues(_vals);
                parseResponse();
            });
        } else {
            parseResponse();
        }
    }, [selectedItem]);

    useEffect(() => {
        parseResponse();
    }, [selectedCategory]);

    const clearIndexValues = () => {
        let _vals = [];
        for(const _i of itemIndexValues) {
            _vals.push("");
        }

        setItemIndexValues(_vals);
    }

    function parseResponse() {
        let response = {};
        if(selectedCategory !== null) {response = Object.assign(response, {"category":selectedCategory.label})}
        if(selectedItem !== null) {response = Object.assign(response, {"item":selectedItem.name})}

        let ifs = [];
        let index = 0;
        for(const ind of itemIndexes) {
            if(itemIndexValues[index] !== "") {
                ifs.push({
                    value: itemIndexValues[index],
                    name: ind.name
                });
            }

            index += 1;
        }

        response = Object.assign(response, {"indexes":ifs});
        onResponse(response);
    }

    const catRef = useOutsideClick(() => setCategoryActivityState(false));
    const itemRef = useOutsideClick(() => setItemActivityState(false));
    const globalRef = useOutsideClick(() => setGlobalSearchState(false));

    const [filtersDisplayed, setFiltersState] = useState(true);
    const [itemIndexes, setItemIndexes] = useState([]);
    const [itemIndexValues, setItemIndexValues] = useState([]);

    const [trigger, setTrigger] = useState(0);

    function updateItemIndexValue(index, value) {
        let ixs = itemIndexValues;
        ixs[index] = value;
        setItemIndexValues(ixs);

        setTrigger(1-trigger);
    }

    function checkIndexes() {
        let flag = true;
        for(const index of itemIndexValues) {
            if(index !== "") {
                flag = false;
            }
        }

        return !flag;
    }

    return(
        <div className="FiltersView__root">
            <div className="FiltersView__header-container" style={style}>
                <div className="FiltersView__header-item" style={{borderBottomLeftRadius:"16px",borderTopLeftRadius:"16px"}}>
                    <p className="FiltersView__header-item-label">{market_lang[7][lang]}</p>
                    <div className="FiltersView__header-item-input-wrapper" style={{cursor:"default"}}>
                        {selectedType === "" ?
                        <p className="FiltersView__header-item-input-placeholder">{market_lang[10][lang]}</p>
                        :
                        <p className="FiltersView__header-item-input-value">{market_lang[38][lang]}</p> }

                        {selectedType !== "" && <img src={crossIcon} className="FiltersView__header-item-input-clear"/>}

                        <IoChevronDown
                            color="#0F62FE"
                            size="18px"
                            style={{
                                marginLeft: "8px"
                            }}
                        />
                    </div>
                </div>

                <div className="FiltersView__header-item" onClick={() => setCategoryActivityState(!categoryActivityState)} ref={catRef}>
                    <p className="FiltersView__header-item-label">{market_lang[9][lang]}</p>
                    <div className="FiltersView__header-item-input-wrapper">
                        {selectedCategory === null ?
                        <p className="FiltersView__header-item-input-placeholder">{market_lang[10][lang]}</p>
                        :
                        <p className="FiltersView__header-item-input-value">{selectedCategory.lang !== undefined ? selectedCategory.lang[lang] : selectedCategory.label}</p>}

                        {selectedCategory !== null && 
                        <img 
                            src={crossIcon} 
                            className="FiltersView__header-item-input-clear"
                            onClick={() => {
                                setSelectedItem(null);
                                setItems([]);
                                setSelectedCategory(null);
                                setGlobalSearchValue("");
                            }}
                        />}

                        <IoChevronDown
                            color="#0F62FE"
                            size="18px"
                            style={{
                                marginLeft: "8px"
                            }}
                        />
                    </div>

                    {categoryActivityState &&
                    <div className="FiltersView__search-items-container">
                        <div className="FiltersView__search-items-wrapper">
                            {categories.map((cat, index) => {
                                return(
                                    <div className="FiltersView__search-item-container" onClick={() => {
                                        setSelectedCategory(cat);
                                        setItems(cat.items);
                                        //console.log(cat.items);
                                        setGlobalSearchValue("");
                                    }}>
                                        <p className="FiltersView__search-item-label">{cat.lang !== undefined ? cat.lang[lang] : cat.label}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    }
                </div>

                <div className="FiltersView__header-item" onClick={() => setItemActivityState(!itemActivityState)} ref={itemRef}>
                    <p className="FiltersView__header-item-label">{market_lang[11][lang]}</p>
                    <div className="FiltersView__header-item-input-wrapper" style={selectedCategory === "" ? {cursor:"default"}:{}}>
                        {selectedItem === null ?
                        <p className="FiltersView__header-item-input-placeholder">{market_lang[10][lang]}</p>
                        :
                        <p className="FiltersView__header-item-input-value">{selectedItem.lang !== undefined ? selectedItem.lang[lang] : selectedItem.name}</p>}

                        {selectedItem !== null && 
                        <img 
                            src={crossIcon} 
                            className="FiltersView__header-item-input-clear"
                            onClick={() => {
                                setSelectedItem(null);
                                setGlobalSearchValue("");
                            }}
                        />}

                        <IoChevronDown
                            color={selectedCategory === null ? "#525252":"#0F62FE"}
                            size="18px"
                            style={{
                                marginLeft: "8px"
                            }}
                        />
                    </div>

                    {itemActivityState &&
                    <div className="FiltersView__search-items-container">
                        <div className="FiltersView__search-items-wrapper">
                            {items.map((item, index) => {
                                return(
                                    <div className="FiltersView__search-item-container" onClick={() => {
                                        setSelectedItem(item);
                                        setGlobalSearchValue("");
                                    }}>
                                        <p className="FiltersView__search-item-label">{item.lang !== undefined ? item.lang[lang] : item.name}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    }
                </div>

                <div className="FiltersView__header-item-search" ref={globalRef}>
                    <input
                        className="FiltersView__header-item-search-input"
                        placeholder={market_lang[12][lang]}
                        onFocus={() => setGlobalSearchState(true)}
                        value={globalSearchValue}
                        onChange={event => setGlobalSearchValue(event.target.value)}
                    />

                    <BlueButton
                        text={market_lang[13][lang]}
                        textStyle={{
                            fontWeight: "700",
                            letterSpacing: "0.32px",
                            marginLeft: "32px",
                            marginRight: "32px",
                            whiteSpace: "nowrap"
                        }}
                        style={{
                            marginRight: "16px"
                        }}
                        //disabled={!(selectedCategory !== "" && selectedItem !== "")}
                        onClick={() => {
                            //if(selectedCategory !== "" && selectedItem !== "") {
                                parseResponse();
                            //}
                        }}
                    />

                    {globalSearchState && globalSearchValue !== "" &&
                    <div className="FiltersView__search-items-container">
                        <div className="FiltersView__search-items-wrapper">
                            {globalSearchResults.map((result, index) => {
                                return(
                                    <div className="FiltersView__search-item-container" onClick={() => {
                                        setSelectedCategory(result.category);
                                        setSelectedItem(result.item);
                                        setItems(result.category.items);
                                        setGlobalSearchValue(result.item.lang !== undefined ? result.item.lang[lang] : result.item.name);
                                        setGlobalSearchState(false);
                                    }}>
                                        <p className="FiltersView__search-item-label">{result.category.lang !== undefined ? result.category.lang[lang] : result.category.label}/ {result.item.lang !== undefined ? result.item.lang[lang] : result.item.name}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    }
                </div>
            </div>

            {selectedItem !== null &&
            <>
                <div className="FiltersView__body-wrapper" style={style}>
                    <div className="FiltersView__body-actions-container">
                        <p className="FiltersView__body-title">{market_lang[36][lang]}</p>

                        <div className="FiltersView__body-actions-block">
                            {checkIndexes() &&
                            <>
                                <div className="FiltersView__body-action-button">
                                    <p className="FiltersView__body-action-button-label">{market_lang[37][lang]}</p>
                                    <img src={heartIcon} className="FiltersView__body-action-button-icon"/>
                                </div>

                                <div className="FiltersView__body-action-button" onClick={clearIndexValues}>
                                    <p className="FiltersView__body-action-button-label">Очистить все</p>
                                    <img src={crossIcon} className="FiltersView__body-action-button-icon"/>
                                </div>
                            </>
                            }

                            <div className="FiltersView__body-action-button" onClick={() => setFiltersState(!filtersDisplayed)}>
                                <p className="FiltersView__body-action-button-label" style={{color:"#161616"}}>{filtersDisplayed?market_lang[40][lang]:market_lang[41][lang]}</p>
                                <IoChevronDown
                                    color="#0F62FE"
                                    size="14px"
                                    style={{
                                        marginRight: "16px"
                                    }}
                                    className={"FiltersView__body-chevron"+(filtersDisplayed?"-reversed":"")}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {filtersDisplayed &&
                <div className="FiltersView__indexes-wrapper" style={style}>
                    {itemIndexes.map((ind, index) => {
                        return(
                            <div className="FiltersView__index-container">
                                <p className="FiltersView__index-label">{parseIndexLabel(ind)}</p>
                                {ind.format !== "picker" ?
                                <input
                                    className="FiltersView__index-input"
                                    placeholder={market_lang[39][lang]}
                                    value={itemIndexValues[index]}
                                    onChange={(event) => {
                                        updateItemIndexValue(index, event.target.value);
                                    }}
                                />
                                :
                                <SliderPickerLarge
                                    items={ind.base_values}
                                    value={itemIndexValues[index]}
                                    onChange={(value) => {
                                        updateItemIndexValue(index, value);
                                    }}
                                    style={{
                                        marginLeft: "16px",
                                        width: "auto"
                                    }}
                                />
                                }
                            </div>
                        );
                    })}
                </div>
                }
            </>
            }
        </div>
    );

    function parseIndexLabel(index) {
        if(index.format !== "picker") {
            let str = `${indexLang.lang[index.name][lang]}`;
            switch(index.filter) {
                case "from":
                    str += market_lang[35][lang];
                    break;
                case "to":
                    str += market_lang[34][lang];
                    break;
                default:
                    break;
            }

            str += ", " + index.format;
            return str;
        } else {
            return index.name;
        }
    }
}

const useOutsideClick = (callback) => {
    const ref = React.useRef();
  
    React.useEffect(() => {
        const handleClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };
  
        document.addEventListener('click', handleClick, true);
  
        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [ref]);
  
    return ref;
};