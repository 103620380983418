import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import "../../pagestyles/CompanyView.css";

// IMPORT COMPONENTS
import { 
    InfoField, 
    PlainField, 
    StoreField 
} from '../../components/FormComponents';

import { 
    InfoModal, 
    CompanyModal, 
    StockModal, 
    DeleteModal, 
    RoleModal 
} from '../../components/Modals';

// IMPORT ICONS
import addIcon from "../../assets/add.png";

// IMPORT API
import CompanyService from '../../services/CompanyService';
import StockService from '../../services/StockService';

// IMPORT LANGUAGE
import ps_file from "../../lang/profile.json";
const profile_lang = ps_file.lang;

const companyService = new CompanyService();
const stockService = new StockService();

export default function CompanyView({ lang=1 }) {
    const token = useOutletContext(); 
    const [inn, setInn] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [role, setRole] = useState("");
    const [roleIndexes, setRoleIndexes] = useState([]);
    const [productType, setProductType] = useState("");
    const [site, setSite] = useState("");
    const [companyNumber, setCompanyNumber] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");

    const [stores, setStores] = useState([]);

    const [currentTitle, setCurrentTitle] = useState("");
    const [currentValues, setCurrentValues] = useState([]);
    const [currentLabels, setCurrentLabels] = useState([]);
    const [currentPlaceholders, setCurrentPlaceholders] = useState([]);

    const [infoModalState, setInfoModalState] = useState(false);
    const [currentVariant, setCurrentVariant] = useState(0);
    const [infoModalType, setInfoModalType] = useState("basic");

    const [companyModalState, setCompanyModalState] = useState(false);
    const [companyValues, setCompanyValues] = useState(["", "", "", "", "", "", ""]);

    const [roleModalState, setRoleModalState] = useState(false);
    const onRoleModalClose = () => {
        setRoleModalState(false);
    }

    const onRoleModalAccept = (value) => {
        companyService.patchCompany(
            {
                "inn": inn,
                "role": value
            },
            token,
            function(response) {
                if(response.company !== undefined) {
                    setRole(translateRole(response.company.role));
                }
            }
        );
    }

    function translateRole(text) {
        let dict = [
            [
                "Покупатель",
                "Buyer",
                "买方"
            ],
            [
                "Поставщик",
                "Supplier",
                "供应者"
            ],
            [
                "Сервис",
                "Сервис",
                "Сервис"
            ]
        ];

        console.log(text);
        if(text.split(" ").length > 1) {
            let str = "";
            let index = 0;
            let ixs = [];
            for(const item of text.split(" ")) {
                let label = "";
                switch(item) {
                    case "buyer":
                        ixs.push(0);
                        label = dict[0][lang];
                        break;
                    case "seller":
                        ixs.push(1);
                        label = dict[1][lang];
                        break;
                    case "service":
                        ixs.push(2);
                        label = dict[2][lang];
                        break;
                    default:
                        break;
                }

                str += label;
                if(index !== text.split(" ").length-1) {
                    str += ", ";
                }

                index += 1;
            }

            setRoleIndexes(ixs);
            console.log(str);
            return str;
        } else {
            switch(text) {
                case "buyer":
                    setRoleIndexes([0]);
                    return dict[0][lang];
                case "seller":
                    setRoleIndexes([1]);
                    return dict[1][lang];
                case "service":
                    setRoleIndexes([2]);
                    return dict[2][lang];
                default:
                    return "";
            }
        }
    }

    const [stockModalState, setStockModalState] = useState(false);
    const closeStockModal = () => {
        setStockModalState(false);
    }

    const [renderTrigger, setRenderTrigger] = useState(0);

    function callRender() {
        setRenderTrigger(1-renderTrigger);
    }

    const closeInfoModal = () => {
        setInfoModalState(false);
    }

    const closeCompanyModal = () => {
        setCompanyModalState(false);
    }

    const handleValueChange = (value, index) => {
        let cv = currentValues;
        cv[index] = value;
        setCurrentValues(cv);
        callRender();
    }

    const handleCompanyValueChange = (value, index) => {
        let cv = companyValues;
        cv[index] = value;
        setCompanyValues(cv);
        callRender();
    }

    function handleModalAcception() {
        switch(currentVariant) {
            case 0:
                break;
            case 1:
                companyService.patchCompany(
                    {
                        "inn": inn,
                        "name": currentValues[0]
                    },
                    token,
                    function(response) {
                        if(response.company !== undefined) {
                            setCompanyName(response.company.name);
                        }
                    }
                );
                break;
            case 2:
                break;
            case 3:
                companyService.patchCompany(
                    {
                        "inn": inn,
                        "site": currentValues[0]
                    },
                    token,
                    function(response) {
                        if(response.company !== undefined) {
                            setSite(response.company.site);
                        }
                    }
                );
                break;
            case 4:
                companyService.patchCompany(
                    {
                        "inn": inn,
                        "phone": currentValues[0]
                    },
                    token,
                    function(response) {
                        if(response.company !== undefined) {
                            setCompanyNumber(response.company.phone);
                        }
                    }
                );
                break;
            case 5:
                companyService.patchCompany(
                    {
                        "inn": inn,
                        "address": currentValues[0]
                    },
                    token,
                    function(response) {
                        if(response.company !== undefined) {
                            setCompanyAddress(response.company.address);
                        }
                    }
                );
                break;
            default:
                break;
        }
    }

    function handleCompanyModalComplete() {
        console.log(`Token ${token}`);
        companyService.addCompany(
            {
                inn: companyValues[0],
                name: companyValues[1],
                address: companyValues[2],
                role: companyValues[3],
                product_type: companyValues[4],
                site: companyValues[5],
                phone: companyValues[6]
            },
            token,
            function(response) {
                //console.log(response);
                let data = response.company;
                setInn(data.inn);
                setCompanyName(data.name);
                setCompanyAddress(data.address);
                setCompanyNumber(data.phone);
                setSite(data.site);
                setProductType(data.product_type);
            }
        );
    }

    function removeStore(index) {
        let pk = stores[index].pk;
        stockService.deleteStock(pk, token, function(response) {
            console.log(response);
            if(response.stock !== undefined) {
                stockService.getStockList(token, function(stocks) {
                    setStores(stocks);
                    callRender();
                });
            }
        });
    }

    const [deleteModalState, setDeleteModalState] = useState(false);
    const [deleteStockIndex, setDeleteStockIndex] = useState(0);
    const onDeleteModalStateClose = () => {
        setDeleteModalState(false);
    }

    const onAcceptDeleteModal = () => {
        removeStore(deleteStockIndex);
    }

    useEffect(() => {
        companyService.getCompaniesList(token, function(response) {
            let list = response.companies;
            if(list.length > 0) {
                let data = list[0];
                setInn(data.inn);
                setCompanyName(data.name);
                setCompanyAddress(data.address);
                setCompanyNumber(data.phone);
                setSite(data.site);
                setProductType(data.product_type);
                setRole(translateRole(data.role));
            }
        });

        stockService.getStockList(token, function(response) {
            if(response.length > 0) {
                setStores(response);
            }
        });
    }, []);

    function parseStockAddress(stock) {
        var addr = "";
        addr += (stock.country + ", ");
        if(stock.region !== "") {
            addr += (stock.region + ", ");
        }
        if(stock.district !== "") {
            addr += (stock.district + ", ");
        }
        addr += ("г " + stock.town + ", ");
        addr += (stock.street + ", ");
        addr += ("д " + stock.house);
        if(stock.building !== "") {
            addr += (", стр " + stock.building);
        }

        if(stock.corpus !== "") {
            addr += (", к " + stock.corpus);
        }

        if(stock.room !== "") {
            addr += (", кв " + stock.room);

        }

        return addr;
    }

    return(
        <div className="CompanyView__root">
            <InfoModal
                visible={infoModalState}
                type={infoModalType}
                title={currentTitle}
                labels={currentLabels}
                values={currentValues}
                placeholders={currentPlaceholders}
                onChangeValue={handleValueChange}
                onRequestClose={closeInfoModal}
                onAcceptChanges={() => handleModalAcception()}
                lang={lang}
            />

            <CompanyModal
                visible={companyModalState}
                values={companyValues}
                onChangeValue={handleCompanyValueChange}
                onRequestClose={closeCompanyModal}
                onComplete={() => handleCompanyModalComplete()}
                lang={lang}
            />

            <StockModal
                visible={stockModalState}
                onClose={closeStockModal}
                onAccept={() => {
                    stockService.getStockList(token, function(stocks) {
                        setStores(stocks);
                        callRender();
                    })
                }}
                token={token}
                lang={lang}
            />

            <DeleteModal
                visible={deleteModalState}
                onClose={onDeleteModalStateClose}
                onAccept={onAcceptDeleteModal}
                label="Удаление склада"
                content={`Вы уверены, что хотите удалить склад "${stores[deleteStockIndex] !== undefined ? stores[deleteStockIndex].name : "null"}"? Восстановить удаленный склад будет невозможно`}
                lang={lang}
            />

            <RoleModal
                defaultValues={roleIndexes}
                onRequestClose={onRoleModalClose}
                onAccept={onRoleModalAccept}
                visible={roleModalState}
                lang={lang}
            />

            <div className="CompanyView__header-container">
                <p className="CompanyView__header-title">{profile_lang[44][lang]}</p>
            </div>

            <div className="CompanyView__body">
                <InfoField
                    label={profile_lang[35][lang]}
                    value={inn}
                    onClick={() => {
                        //setCurrentVariant(0);
                        if(inn === "") {
                            setCompanyModalState(true);
                        }
                        //openInfoModal(0);
                    }}
                    editIconHidden={inn !== ""}
                />

                <InfoField
                    label={profile_lang[36][lang]}
                    value={companyName}
                    onClick={() => {
                        setCurrentVariant(1);
                        setCurrentTitle(profile_lang[64][lang]);
                        setCurrentValues([companyName]);
                        setCurrentLabels([profile_lang[36][lang]]);
                        setCurrentPlaceholders([profile_lang[72][lang]]);
                        setInfoModalState(true);
                        //openInfoModal(1);
                    }}
                />

                <InfoField
                    label={profile_lang[37][lang]}
                    value={role}
                    onClick={() => {
                        setRoleModalState(true);
                    }}
                />

                <InfoField
                    label={profile_lang[40][lang]}
                    value={site}
                    onClick={() => {
                        setCurrentVariant(3);
                        setCurrentTitle(profile_lang[66][lang]);
                        setCurrentValues([site]);
                        setCurrentLabels([profile_lang[40][lang]]);
                        setCurrentPlaceholders([profile_lang[73][lang]]);
                        setInfoModalState(true);
                        //openInfoModal(3);
                    }}
                />

                <InfoField
                    label={profile_lang[41][lang]}
                    value={companyNumber}
                    onClick={() => {
                        setCurrentVariant(4);
                        setCurrentTitle(profile_lang[67][lang]);
                        setCurrentValues([companyNumber]);
                        setCurrentLabels([profile_lang[41][lang]]);
                        setCurrentPlaceholders(["Введите номер в формате +7 000 000-00-00"]);
                        setInfoModalState(true);
                    }}
                    style={{
                        borderBottomWidth: 0
                    }}
                />
            </div>

            <div className="CompanyView__header-container" style={{ marginTop: 32 }}>
                <p className="CompanyView__header-title">{profile_lang[42][lang]}</p>
            </div>

            <div className="CompanyView__body">
                <PlainField
                    value={companyAddress}
                    onClick={() => {
                        setCurrentVariant(5);
                        setCurrentTitle(profile_lang[68][lang]);
                        setCurrentValues([companyAddress]);
                        setCurrentLabels([profile_lang[42][lang]]);
                        setCurrentPlaceholders(["Введите адрес компании"]);
                        setInfoModalState(true);
                        //openInfoModal(3);
                    }}
                    style={{
                        borderBottomWidth: 0
                    }}
                />
            </div>

            <div 
                className="CompanyView__header-container" 
                style={{ 
                    marginTop: 32, 
                    marginBottom: (stores.length > 0 ? 0 : 226),
                    borderBottomLeftRadius: (stores.length > 0 ? 0 : 16),
                    borderBottomRightRadius: (stores.length > 0 ? 0 : 16),
                }}
            >
                <p className="CompanyView__header-title">{profile_lang[43][lang]}</p>

                <img 
                    src={addIcon} 
                    className="CompanyView__store-header-plus" 
                    onClick={() => setStockModalState(true)}
                />
            </div>

            {stores.length > 0 ?
            <div className="CompanyView__body" style={{ marginBottom: 98 }}>
                {stores.map((store, index) => {
                    return(
                        <StoreField
                            key={index}
                            label={store.name}
                            value={parseStockAddress(store)}
                            onDelete={() => {
                                setDeleteStockIndex(index);
                                setDeleteModalState(true);
                            }}
                            style={{
                                borderBottomWidth: (index+1 === stores.length ? 0 : 4)
                            }}
                        />
                    );
                })}
            </div>
            :
            <div/>
            }
        </div>
    );
}