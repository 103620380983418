import React from 'react';

import "./SliderPicker.css";

export default function SliderPicker({
    items,
    value,
    onChange,
    style,
    labelStyles
}) {
    return(
        <div className="SliderPicker__container" style={style}>
            <div className="SliderPicker__indicator" style={{ marginLeft: 8+value*98 }} />

            {items.map((item, index) => {
                return(
                    <p 
                        className="SliderPicker__item"
                        onClick={() => onChange(index)}
                        style={labelStyles ? (labelStyles.length > index ? labelStyles[index] : {}) : {}}
                    >{item}</p>
                );
            })}

            
        </div>
    );
}

export function SliderPickerLarge({
    items,
    value,
    onChange,
    style,
    labelStyles
}) {
    return(
        <div className="SliderPickerL__container" style={style}>
            <div className="SliderPickerL__indicator" style={{ marginLeft: `calc(8px + ${value}*(100% - 18px)/${items.length})`, width: `calc((100% - 12px) / ${items.length})` }} />

            {items.map((item, index) => {
                return(
                    <p 
                        className="SliderPickerL__item"
                        onClick={() => onChange(index)}
                        style={labelStyles ? (labelStyles.length > index ? labelStyles[index] : {}) : {}}
                    >{item}</p>
                );
            })}
        </div>
    );
}

export function MultiPickerLarge({
    items,
    values,
    onChange,
    style,
    labelStyles
}) {
    const [trigger, setTrigger] = React.useState(0);

    function parseResponse(index) {
        if(values.includes(index)) {
            let _values = values;
            let _index = _values.indexOf(index);
            _values.splice(_index, 1);
            onChange(_values);
            setTrigger(1-trigger);
        } else {
            let _values = values;
            _values.push(index);
            onChange(_values);
            setTrigger(1-trigger);
        }
    }

    return(
        <div className="MultiPickerL__container" style={style}>
            

            {items.map((item, index) => {
                return(
                    <div className={"MultiPickerL__item-container"+(values.includes(index)?"-active":"")}>
                        <p 
                            className="MultiPickerL__item"
                            onClick={() => parseResponse(index)}
                            style={labelStyles ? (labelStyles.length > index ? labelStyles[index] : {}) : {}}
                        >{item}</p>
                    </div>
                );
            })}
        </div>
    );
}
/*
{values.map((value, index) => {
                return(
                    <div className="SliderPickerL__indicator" style={{ marginLeft: `calc(8px + ${value}*(100% - 18px)/${items.length})`, width: `calc((100% - 12px) / ${items.length})` }} />
                );
            })}
*/