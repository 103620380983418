import React from 'react';
import "./Buttons.css";

export function BlueButton({
    text,
    style,
    textStyle,
    onClick,
    disabled
}) {
    return(
        <button
            className={"Buttons__blue-button" + (disabled ? "-disabled" : "")}
            onClick={onClick}
            style={style}
            disabled={disabled}
        >
            <p className="Buttons__blue-button-text" style={textStyle}>{text}</p>
        </button>
    );
}

export function GreyButton({
    text,
    style,
    onClick
}) {
    return(
        <button
            className="Buttons__grey-button"
            onClick={onClick}
            style={style}
        >
            <p className="Buttons__grey-button-text">{text}</p>
        </button>
    );
}

export function YellowButton({
    text,
    style,
    textStyle,
    onClick
}) {
    return(
        <button
            className={"Buttons__yellow-button"}
            onClick={onClick}
            style={style}
        >
            <p className="Buttons__yellow-button-text" style={textStyle}>{text}</p>
        </button>
    );
}