import { send } from "@emailjs/browser";
import axios from "axios";
import Sendsay from "sendsay-api";
let sendsay = new Sendsay({ apiKey: '19mX7bRf2Kre5VlRdJZhl-60Lz-4Qp02ll0p6l3L8TMPYjdm-nrqK7NfRA62h-5eQiBk1te11gmUrfUhODxojQx_5imtcjw' });

export default class EmailService {
    constructor() {}

    sendVerificationLetter(mail, lang, code, _callback) {
        var templateParams = {
            mail: mail,
            code: code
        };
        /*send("fortio_verification", `fortio_verification_ru`, templateParams).then(function(response) { // _${lang}
            console.log(response.status);
            _callback(true);
        }).catch(e => {
            console.log(e);
            _callback(false);
        });*/

        axios.post(
            "https://api.emailjs.com/api/v1.0/email/send",
            {
                service_id: 'fortio_verification',
                template_id: 'fortio_verification_ru',
                user_id: '44HQiApvTqq-ZFlP7',
                //accessToken: 'zO3FSeY-0hjq2-sUYNP1k',
                template_params: {
                    mail: mail,
                    code: code
                }
            }
        ).then(r => {
            //console.log(response.status);
            _callback(true);
        }).catch(e => {
            console.log(e);
            _callback(true); // FALSE
        });

        sendsay.request(
            {
                action: 'send.letter',
                draft: ''
            }
        ).then().catch();
    }

    sendSupportRequest(name, email, message, lang, _callback) {
        let date = new Date();
        let ts = date.toISOString();
        var templateParams = {
            name: name,
            email: email,
            timestamp: ts,
            message: message
        };
        send("fortio_support", `fortio_support_${lang}`, templateParams, "44HQiApvTqq-ZFlP7").then(function(response) {
            console.log(response.status);
            _callback(true);
        }).catch(e => {
            console.log(e);
            _callback(false);
        });
    }

    sendPersonalInformation(to_name, to_email, email, phone, transaction_id, lang, _callback) {
        var templateParams = {
            to_name: to_name,
            to_email: to_email,
            email: email,
            phone: phone,
            transaction_id: transaction_id
        };
        send("fortio_transaction_mng", `fortio_transaction_${lang}`, templateParams, "OWIECsZra_lG3MOLx").then(function(response) {
            console.log(response.status);
            _callback(true);
        }).catch(e => {
            console.log(e);
            _callback(false);
        });
    }

    sendPwdResetCode(email, code, lang, _callback) {
        var templateParams = {
            email: email,
            code: code
        };
        send("fortio_pwd_reset", `fortio_pwd_reset_${lang}`, templateParams, "OWIECsZra_lG3MOLx").then(function(response) {
            console.log(response.status);
            _callback(true);
        }).catch(e => {
            console.log(e);
            _callback(false);
        });
    }
}