import axios from "axios";
import { API_URL } from "./ApiCredentials";

axios.defaults.withCredentials = true;

export default class UserService {
    constructor() {}

    signInWithCookie(_callback) {
        const url = `${API_URL}/api/users/login`;
        const headers = {
            'content-type': 'application/json',
        }

        axios.post(
            url, 
            {  }, 
            { headers: headers, withCredentials: true }
        ).then(response => {
            console.log(response.data);
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback({});
            return;
        });

        return;
    }

    signUpWithEmailAndPassword(email, password, _callback) {
        const url = `${API_URL}/api/users/register`;
        const headers = {
            'content-type': 'application/json',
        }

        axios.post(
            url, 
            { 
                user: { 
                    email: email, 
                    password: password,
                    username: email
                } 
            }, 
            { headers: headers, withCredentials: true }
        ).then(response => {
            console.log(response);
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback({});
            return;
        });
    }

    signInWithEmailAndPassword(email, password, remember=false, _callback) {
        const url = `${API_URL}/api/users/login${remember ? "?remember" : ""}`;
        const headers = {
            'content-type': 'application/json',
        }

        axios.post(
            url, 
            { user: { email: email, password: password } }, 
            { headers: headers, withCredentials: true }
        ).then(response => {
            console.log(response.data);
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback({});
            return;
        });

        /*let xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.withCredentials = true;

        xhr.onreadystatechange = function() {
            if(xhr.readyState === 4) {
                console.log(xhr.status);
                console.log(xhr.response);
                _callback(JSON.parse(xhr.responseText));
            }
        }

        xhr.send(JSON.stringify({
            "user": { 
                "email": email, 
                "password": password 
            }
        }));*/
    }

    getUser(token, _callback) {
        const url = `${API_URL}/api/user`;
        const headers = {
            'content-type': 'application/json',
            'Authorization': `Token ${token}`
        }

        axios.get(
            url,
            { headers: headers }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback({});
            return;
        });
    }

    patchUser(token, data, _callback) {
        const url = `${API_URL}/api/user`;
        const headers = {
            'content-type': 'application/json',
            'Authorization': `Token ${token}`
        }

        axios.patch(
            url,
            data,
            { headers: headers }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback({});
            return;
        });
    }

    getUserKey(token, _callback) {
        const url = `${API_URL}/api/users/pk`;
        const headers = {
            'content-type': 'application/json',
            'Authorization': `Token ${token}`
        }

        axios.get(
            url,
            { headers: headers }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback({});
            return;
        });
    }

    getUserInfo(pk, token, _callback) {
        const url = `${API_URL}/api/users/info`;
        const headers = {
            'content-type': 'application/json',
            'Authorization': `Token ${token}`
        }

        axios.get(
            url,
            { headers: headers, params: {"pk":pk} }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback({});
            return;
        });
    }

    checkEmailExistance(email, _callback) {
        const url = `${API_URL}/api/users/pwdupdate`;
        const headers = {
            'content-type': 'application/json',
        }

        axios.get(
            url,
            { headers: headers, params: {"email":email} }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback(false);
            return;
        });
    }

    updatePassword(email, password, _callback) {
        const url = `${API_URL}/api/users/pwdupdate`;
        const headers = {
            'content-type': 'application/json',
        }

        axios.post(
            url,
            { "user": {
                "email": email,
                "password": password,
            }},
            { headers: headers }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback(false);
            return;
        });
    }
}