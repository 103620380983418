import React, { useState, useEffect } from 'react';
import "../../pagestyles/ProductionShowcase.css";

// IMPORT LANGUAGE
import market_file from "../../lang/market.json";
import mdls_file from "../../lang/modals.json";

// IMPORT ASSETS
import { IoChevronDown } from 'react-icons/io5';

// IMPORT COMPONENTS
import { SendOfferModalFromSeller, CompanyNotProvidedModal, SendOfferModalFromBuyer } from '../../components/Modals';

// IMPORT API
import StockService from '../../services/StockService';
import UserService from '../../services/UserService';
import CompanyService from '../../services/CompanyService';
import { indexLanguage, translateCatAndItem } from '../../services/FirebaseApi';

const stockService = new StockService();
const userService = new UserService();
const companyService = new CompanyService();

const market_lang = market_file.lang;
const modals_lang = mdls_file.lang;

export default function ProductionShowcaseView({
    token,
    production,
    lang
}) {
    const [selfID, setSelfID] = useState(0);
    useEffect(() => {
        if(token !== "") {
            userService.getUserKey(token, function(response) {
                setSelfID(response.pk);
            });
        }
    }, [token]);

    useEffect(() => {
        indexLanguage(function(r) {
            setIndexLang(r);
        });
    }, []);

    const [indexLang, setIndexLang] = useState({});

    function onOfferModalRequsetOpen(index) {
        companyService.getCompaniesList(token, function(companies) {
            if(companies.companies.length > 0) {
                let role = companies.companies[0].role;
                if(role.split(" ").includes("buyer")) {
                    setCurrentData(production[index]);
                } else {
                    setCompanyAlarmType("not-allowed");
                }
            } else {
                setCompanyAlarmType("not-provided");
            }
        });
    }

    const [companyAlarmType, setCompanyAlarmType] = useState("");
    const [companyAlarmState, setCompanyAlarmState] = useState(false);
    const onCAlarmRequestClose = () => {
        setCompanyAlarmState(false);
    }

    useEffect(() => {
        if(companyAlarmType !== "") {
            setCompanyAlarmState(true);
        }
    }, [companyAlarmType]);

    const [offerModalState, setOfferModalState] = useState(false);
    const [currentData, setCurrentData] = useState(null);

    useEffect(() => {
        if(currentData !== null) {
            setOfferModalState(true);
        }
    }, [currentData]);

    const onOfferModalRequestClose = () => {
        setOfferModalState(false);
        setCurrentData(null);
    }

    return(
        <div className="ProdShow__container">
            <SendOfferModalFromBuyer
                visible={offerModalState}
                token={token}
                onRequestClose={onOfferModalRequestClose}
                requestData={currentData}
                indexLang={indexLang}
                lang={lang}
            />

            <CompanyNotProvidedModal
                visible={companyAlarmState}
                alarmType={companyAlarmType}
                onRequestClose={onCAlarmRequestClose}
                indexLang={indexLang}
                lang={lang}
            />

            <div className="ProdShow__table-header-container">
                <p className="ProdShow__table-label" style={{marginLeft:'32px'}}>{market_lang[33][lang]}</p>
                <p className="ProdShow__table-label">{market_lang[9][lang]}</p>
                <p className="ProdShow__table-label">{market_lang[11][lang]}</p>
                <p className="ProdShow__table-label">{market_lang[14][lang]}</p>
                <p className="ProdShow__table-label">{market_lang[15][lang]}</p>
            </div>

            <div className="ProdShow__wrapper">
                { //token !== "" &&
                    production.map((item, index) => {
                        return(
                            <TableTileView item={item} key={index} token={token} last={index === production.length-1} selfID={selfID} onOfferRequest={() => onOfferModalRequsetOpen(index)} lang={lang} indexLang={indexLang} />
                        );
                    })
                }
            </div>
        </div>
    );
}

function TableTileView({ item, token, last, selfID, onOfferRequest, lang, indexLang }) {
    const [stockRegion, setStockRegion] = useState("");

    const [opened, setOpenedState] = useState(false);

    const [translatedCat, setTCat] = useState("");
    const [translatedName, setTName] = useState("");

    useEffect(() => {
        getStockRegion(item.stock);
        //console.log(item);
        translateCatAndItem(item.name, function(r) {
            setTCat(r.catLang[lang]);
            setTName(r.nameLang[lang]);
        });
        //console.log(item);
    }, [item]);

    useEffect(() => {
        if(item !== undefined) {
            translateCatAndItem(item.name, function(r) {
                setTCat(r.catLang[lang]);
                setTName(r.nameLang[lang]);
            });
        }
    }, [lang]);

    function getStockRegion(stockID) {
        stockService.getStockInfo(stockID, token, function(response) {
            if(response.stock.region !== "") {
                setStockRegion(response.stock.region);
            } else {
                setStockRegion(response.stock.town);
            }
        });
    }

    function parseDate(date) {
        return `${date.slice(8, 10)}.${date.slice(5, 7)}.${date.slice(2, 4)}`;
    }

    function parseParamValue(param) {
        let str = "";
        if(param.format !== "picker") {
            switch(param.filter) {
                case "from":
                    str += "> ";
                    break;
                case "to":
                    str += "< ";
                    break;
                default:
                    break;
            }
    
            str += param.value;
            str += param.format !== "%" && param.format !== "" ? indexLang.lang[param.format][lang] : (param.format);
        } else {
            if(indexLang.lang[param.base_values[param.value]] !== undefined) {
                str += indexLang.lang[param.base_values[param.value]][lang];
            } else {
                str += param.base_values[param.value];
            }
        }

        return str;
    }

    return(
        <>
            <div className={"ProdShow__tile-container"+(opened?"-opened":"")} onClick={() => setOpenedState(!opened)}>
                <p className="ProdShow__value-volume" style={{marginLeft:'32px'}}>{item.volume}</p>

                <div className="ProdShow__product-value-wrapper">
                    <p className="ProdShow__value">{translatedCat}</p>
                    {opened &&
                    (item.params.indexes[0] !== undefined &&
                    <div className="ProdShow__product-value-filter-wrapper">
                        {item.params.indexes[0].format !== "picker" && <p className="ProdShow__product-value-filter-label">{indexLang.lang[item.params.indexes[0].name][lang].slice(0, 20) + (indexLang.lang[item.params.indexes[0].name][lang].length > 20 ? "." : "")}:</p>}
                        <p className="ProdShow__product-value-filter-value">{parseParamValue(item.params.indexes[0])}</p>
                    </div>
                    )
                    }
                    {opened &&
                    (item.params.indexes[1] !== undefined &&
                    <div className="ProdShow__product-value-filter-wrapper" style={{marginTop: "66px", position:"absolute"}}>
                        {item.params.indexes[1].format !== "picker" && <p className="ProdShow__product-value-filter-label">{indexLang.lang[item.params.indexes[1].name][lang].slice(0, 20) + (indexLang.lang[item.params.indexes[1].name][lang].length > 20 ? "." : "")}:</p>}
                        <p className="ProdShow__product-value-filter-value">{parseParamValue(item.params.indexes[1])}</p>
                    </div>
                    )
                    }
                </div>

                <div className="ProdShow__product-value-wrapper">
                    <p className="ProdShow__value">{translatedName}</p>
                    {opened &&
                    (item.params.indexes[2] !== undefined &&
                    <div className="ProdShow__product-value-filter-wrapper">
                        {item.params.indexes[2].format !== "picker" && <p className="ProdShow__product-value-filter-label">{indexLang.lang[item.params.indexes[2].name][lang].slice(0, 20) + (indexLang.lang[item.params.indexes[2].name][lang].length > 20 ? "." : "")}:</p>}
                        <p className="ProdShow__product-value-filter-value">{parseParamValue(item.params.indexes[2])}</p>
                    </div>
                    )
                    }
                    {opened &&
                    (item.params.indexes[3] !== undefined &&
                    <div className="ProdShow__product-value-filter-wrapper" style={{marginTop: "66px", position:"absolute"}}>
                        {item.params.indexes[3].format !== "picker" && <p className="ProdShow__product-value-filter-label">{indexLang.lang[item.params.indexes[3].name][lang].slice(0, 20) + (indexLang.lang[item.params.indexes[3].name][lang].length > 20 ? "." : "")}:</p>}
                        <p className="ProdShow__product-value-filter-value">{parseParamValue(item.params.indexes[3])}</p>
                    </div>
                    )
                    }
                </div>

                <div className="ProdShow__product-value-wrapper">
                    <p className="ProdShow__value">{parseDate(item.created_at)}</p>
                    {opened &&
                    (item.params.indexes[4] !== undefined &&
                    <div className="ProdShow__product-value-filter-wrapper">
                        {item.params.indexes[4].format !== "picker" && <p className="ProdShow__product-value-filter-label">{indexLang.lang[item.params.indexes[4].name][lang].slice(0, 20) + (indexLang.lang[item.params.indexes[4].name][lang].length > 20 ? "." : "")}:</p>}
                        <p className="ProdShow__product-value-filter-value">{parseParamValue(item.params.indexes[4])}</p>
                    </div>
                    )
                    }
                    {opened &&
                    (item.params.indexes[5] !== undefined &&
                    <div className="ProdShow__product-value-filter-wrapper" style={{marginTop: "66px", position:"absolute"}}>
                        {item.params.indexes[5].format !== "picker" && <p className="ProdShow__product-value-filter-label">{indexLang.lang[item.params.indexes[5].name][lang].slice(0, 20) + (indexLang.lang[item.params.indexes[5].name][lang].length > 20 ? "." : "")}:</p>}
                        <p className="ProdShow__product-value-filter-value">{parseParamValue(item.params.indexes[5])}</p>
                    </div>
                    )
                    }
                </div>

                <div className="ProdShow__product-value-wrapper">
                    <p className="ProdShow__value-region">{stockRegion}</p>
                    {opened &&
                    (item.params.indexes[6] !== undefined &&
                    <div className="ProdShow__product-value-filter-wrapper">
                        {item.params.indexes[6].format !== "picker" && <p className="ProdShow__product-value-filter-label">{indexLang.lang[item.params.indexes[6].name][lang].slice(0, 20) + (indexLang.lang[item.params.indexes[6].name][lang].length > 20 ? "." : "")}:</p>}
                        <p className="ProdShow__product-value-filter-value">{parseParamValue(item.params.indexes[6])}</p>
                    </div>
                    )
                    }
                    {opened &&
                    (item.params.indexes[7] !== undefined &&
                    <div className="ProdShow__product-value-filter-wrapper" style={{marginTop: "66px", position:"absolute"}}>
                        {item.params.indexes[7].format !== "picker" && <p className="ProdShow__product-value-filter-label">{indexLang.lang[item.params.indexes[7].name][lang].slice(0, 20) + (indexLang.lang[item.params.indexes[7].name][lang].length > 20 ? "." : "")}:</p>}
                        <p className="ProdShow__product-value-filter-value">{parseParamValue(item.params.indexes[7])}</p>
                    </div>
                    )
                    }
                </div>

                <div className="ProdShow__action-container">
                    {selfID != item.parent && token !== "" && <p className="ProdShow__action-button" onClick={onOfferRequest}>{market_lang[16][lang]}</p>}

                    <IoChevronDown
                        color="#0F62FE"
                        size={16}
                        className={"ProdShow__tile-chevron"+(opened?"-rotated":"")}
                        onClick={() => setOpenedState(!opened)}
                    />
                </div>
            </div>
            
            {!last && <div className="ProdShow__tile-divider"/>}
        </>
    );
}


export function ProductionRequestShowcaseView({
    token,
    production,
    lang
}) {
    const [offerModalState, setOfferModalState] = useState(false);
    const [currentOffer, setCurrentOffer] = useState(null);
    const onOfferModalRequestClose = () => {
        setOfferModalState(false);
        setCurrentOffer(null);
    }

    useEffect(() => {
        indexLanguage(function(r) {
            setIndexLang(r);
        });
    }, []);

    const [indexLang, setIndexLang] = useState({});

    useEffect(() => {
        if(currentOffer !== null) {
            setOfferModalState(true);
        }
    }, [currentOffer]);

    const onOfferModalRequsetOpen = (index) => {
        companyService.getCompaniesList(token, function(companies) {
            if(companies.companies.length > 0) {
                let role = companies.companies[0].role;
                if(role.split(" ").includes("seller")) {
                    setCurrentOffer(production[index]);
                } else {
                    setCompanyAlarmType("not-allowed");
                }
            } else {
                setCompanyAlarmType("not-provided");
            }
        });
    }

    const [companyAlarmType, setCompanyAlarmType] = useState("");
    const [companyAlarmState, setCompanyAlarmState] = useState(false);
    const onCAlarmRequestClose = () => {
        setCompanyAlarmState(false);
    }

    useEffect(() => {
        if(companyAlarmType !== "") {
            setCompanyAlarmState(true);
        }
    }, [companyAlarmType]);

    const [selfID, setSelfID] = useState(0);
    useEffect(() => {
        if(token !== "") {
            userService.getUserKey(token, function(response) {
                setSelfID(response.pk);
            });
        }
    }, [token]);

    return(
        <div className="ProdShow__container">
            <SendOfferModalFromSeller
                visible={offerModalState}
                onRequestClose={onOfferModalRequestClose}
                requestData={currentOffer}
                token={token}
                lang={lang}
                indexLang={indexLang}
            />

            <CompanyNotProvidedModal
                visible={companyAlarmState}
                alarmType={companyAlarmType}
                onRequestClose={onCAlarmRequestClose}
                lang={lang}
                indexLang={indexLang}
            />

            <div className="ProdReqShow__table-header-container">
                <p className="ProdShow__table-label" style={{marginLeft:'32px'}}>{market_lang[33][lang]}</p>
                <p className="ProdShow__table-label">{market_lang[9][lang]}</p>
                <p className="ProdShow__table-label">{market_lang[11][lang]}</p>
                <p className="ProdShow__table-label">{market_lang[23][lang]}</p>
                <p className="ProdShow__table-label">{market_lang[24][lang]}</p>
                <p className="ProdShow__table-label">{market_lang[25][lang]}</p>
                <p className="ProdShow__table-label">{market_lang[26][lang]}</p>
            </div>

            <div className="ProdShow__wrapper">
                { //token !== "" &&
                    production.map((item, index) => {
                        return(
                            <RequestTableTileView item={item} key={index} token={token} last={index === production.length-1} onOfferRequest={() => onOfferModalRequsetOpen(index)} selfID={selfID} lang={lang} indexLang={indexLang} />
                        );
                    })
                }
            </div>
        </div>
    );
}

function RequestTableTileView({ item, token, last, onOfferRequest, selfID, lang, indexLang }) {
    const [opened, setOpenedState] = useState(false);

    const [translatedCat, setTCat] = useState("");
    const [translatedName, setTName] = useState("");

    useEffect(() => {
        translateCatAndItem(item.name, function(r) {
            setTCat(r.catLang[lang]);
            setTName(r.nameLang[lang]);
        });
    }, [item]);

    useEffect(() => {
        if(item !== undefined) {
            translateCatAndItem(item.name, function(r) {
                setTCat(r.catLang[lang]);
                setTName(r.nameLang[lang]);
            });
        }
    }, [lang]);

    function parseDate(date) {
        return `${date.slice(8, 10)}.${date.slice(5, 7)}.${date.slice(2, 4)}`;
    }

    function parseParamValue(param) {
        let str = "";
        if(param.format !== "picker") {
            switch(param.filter) {
                case "from":
                    str += "> ";
                    break;
                case "to":
                    str += "< ";
                    break;
                default:
                    break;
            }
    
            str += param.value;
            str += param.format !== "%" && param.format !== "" ? indexLang.lang[param.format][lang] : (param.format);
        } else {
            if(indexLang.lang[param.base_values[0]] !== undefined) {
                str += indexLang.lang[param.base_values[param.value]][lang];
            } else {
                str += param.base_values[param.value];
            }
        }

        return str;
    }

    function parseShipmentPeriod(p1, p2) {
        let monthLangDict = [
            [
                "Янв",
                "Jan",
                "1月"
            ],
            [
                "Фев",
                "Feb",
                "2月"
            ],
            [
                "Мар",
                "Mar",
                "3月"
            ],
            [
                "Апр",
                "Apr",
                "4月"
            ],
            [
                "Май",
                "May",
                "5月"
            ],
            [
                "Июн",
                "Jun",
                "6月"
            ],
            [
                "Июл",
                "Jul",
                "7月"
            ],
            [
                "Авг",
                "Aug",
                "8月"
            ],
            [
                "Сен",
                "Sep",
                "9月"
            ],
            [
                "Окт",
                "Oct",
                "10月"
            ],
            [
                "Нояб",
                "Nov",
                "11月"
            ],
            [
                "Дек",
                "Dec",
                "12月"
            ]
        ];

        let _p1 = (p1.length < 9 ? p1 : (p1.slice(0, 3)+". "+p1.slice(p1.length-4, p1.length)));
        let _p2 = (p2.length < 9 ? p2 : (p2.slice(0, 3)+". "+p2.slice(p2.length-4, p2.length)));

        _p1 = _p1.replaceAll("Янв", monthLangDict[0][lang]);
        _p1 = _p1.replaceAll("Фев", monthLangDict[1][lang]);
        _p1 = _p1.replaceAll("Мар", monthLangDict[2][lang]);
        _p1 = _p1.replaceAll("Апр", monthLangDict[3][lang]);
        _p1 = _p1.replaceAll("Май", monthLangDict[4][lang]);
        _p1 = _p1.replaceAll("Июн", monthLangDict[5][lang]);
        _p1 = _p1.replaceAll("Июл", monthLangDict[6][lang]);
        _p1 = _p1.replaceAll("Авг", monthLangDict[7][lang]);
        _p1 = _p1.replaceAll("Сен", monthLangDict[8][lang]);
        _p1 = _p1.replaceAll("Окт", monthLangDict[9][lang]);
        _p1 = _p1.replaceAll("Ноя", monthLangDict[10][lang]);
        _p1 = _p1.replaceAll("Дек", monthLangDict[11][lang]);

        _p2 = _p2.replaceAll("Янв", monthLangDict[0][lang]);
        _p2 = _p2.replaceAll("Фев", monthLangDict[1][lang]);
        _p2 = _p2.replaceAll("Мар", monthLangDict[2][lang]);
        _p2 = _p2.replaceAll("Апр", monthLangDict[3][lang]);
        _p2 = _p2.replaceAll("Май", monthLangDict[4][lang]);
        _p2 = _p2.replaceAll("Июн", monthLangDict[5][lang]);
        _p2 = _p2.replaceAll("Июл", monthLangDict[6][lang]);
        _p2 = _p2.replaceAll("Авг", monthLangDict[7][lang]);
        _p2 = _p2.replaceAll("Сен", monthLangDict[8][lang]);
        _p2 = _p2.replaceAll("Окт", monthLangDict[9][lang]);
        _p2 = _p2.replaceAll("Ноя", monthLangDict[10][lang]);
        _p2 = _p2.replaceAll("Дек", monthLangDict[11][lang]);

        return _p1 + " - " + _p2;
    }

    useEffect(() => {
        console.log(indexLang);
    }, [indexLang]);

    return(
        <>
            <div className={"ProdReqShow__tile-container"+(opened?"-opened":"")} onClick={() => setOpenedState(!opened)}>
                

                <div className="ProdShow__product-value-wrapper" style={{marginTop:0}}>
                    <p className="ProdShow__value-volume" style={{marginLeft:'32px'}}>{item.volume}</p>
                    {opened &&
                    (item.params.indexes[0] !== undefined &&
                    <div className="ProdShow__product-value-filter-wrapper" style={{marginLeft:"32px"}}>
                        <p className="ProdShow__product-value-filter-label">Цена за т:</p>
                        <p className="ProdShow__product-value-filter-value">{item.price} {item.currency}</p>
                    </div>
                    )
                    }
                </div>

                <div className="ProdShow__product-value-wrapper">
                    <p className="ProdShow__value">{translatedCat}</p>
                    {opened &&
                    (item.params.indexes[0] !== undefined &&
                    <div className="ProdShow__product-value-filter-wrapper">
                        {item.params.indexes[0].format !== "picker" && <p className="ProdShow__product-value-filter-label">{indexLang.lang[item.params.indexes[0].name][lang].slice(0, 16) + (indexLang.lang[item.params.indexes[0].name][lang].length > 20 ? "." : "")}:</p>}
                        <p className="ProdShow__product-value-filter-value">{parseParamValue(item.params.indexes[0])}</p>
                    </div>
                    )
                    }
                    {opened &&
                    (item.params.indexes[1] !== undefined &&
                    <div className="ProdShow__product-value-filter-wrapper" style={{marginTop: "66px", position:"absolute"}}>
                        {item.params.indexes[1].format !== "picker" && <p className="ProdShow__product-value-filter-label">{indexLang.lang[item.params.indexes[1].name][lang].slice(0, 20) + (indexLang.lang[item.params.indexes[1].name][lang].length > 20 ? "." : "")}:</p>}
                        <p className="ProdShow__product-value-filter-value">{parseParamValue(item.params.indexes[1])}</p>
                    </div>
                    )
                    }
                </div>

                <div className="ProdShow__product-value-wrapper">
                    <p className="ProdShow__value">{translatedName}</p>
                    {opened &&
                    (item.params.indexes[2] !== undefined &&
                    <div className="ProdShow__product-value-filter-wrapper">
                        {item.params.indexes[2].format !== "picker" && <p className="ProdShow__product-value-filter-label">{indexLang.lang[item.params.indexes[2].name][lang].slice(0, 20) + (indexLang.lang[item.params.indexes[2].name][lang].length > 20 ? "." : "")}:</p>}
                        <p className="ProdShow__product-value-filter-value">{parseParamValue(item.params.indexes[2])}</p>
                    </div>
                    )
                    }
                    {opened &&
                    (item.params.indexes[3] !== undefined &&
                    <div className="ProdShow__product-value-filter-wrapper" style={{marginTop: "66px", position:"absolute"}}>
                        {item.params.indexes[3].format !== "picker" && <p className="ProdShow__product-value-filter-label">{indexLang.lang[item.params.indexes[3].name][lang].slice(0, 20) + (indexLang.lang[item.params.indexes[3].name][lang].length > 20 ? "." : "")}:</p>}
                        <p className="ProdShow__product-value-filter-value">{parseParamValue(item.params.indexes[3])}</p>
                    </div>
                    )
                    }
                </div>

                <div className="ProdShow__product-value-wrapper">
                    <p className="ProdShow__value">{parseShipmentPeriod(item.period1, item.period2)}</p>
                    {opened &&
                    <div className="ProdShow__product-value-filter-wrapper">
                        <p className="ProdShow__product-value-filter-label">{modals_lang[28][lang]}:</p>
                        <p className="ProdShow__product-value-filter-value">{indexLang.lang !== undefined ? (indexLang.lang[item.package] !== undefined ? indexLang.lang[item.package][lang] : item.package) : item.package}</p>
                    </div>
                    }
                </div>

                <div className="ProdShow__product-value-wrapper">
                    <p className="ProdShow__value-region">{item.basis} {item.station}</p>
                    {opened &&
                    <>
                        <div className="RequestsView__product-value-filter-wrapper">
                            <p className="RequestsView__product-value-filter-label">{modals_lang[26][lang].slice(0, 6)}.:</p>
                            <p className="RequestsView__product-value-filter-value">{indexLang.lang !== undefined ? indexLang.lang[item.ttype1][lang] : item.ttype1}</p>
                        </div>

                        <div className="RequestsView__product-value-filter-wrapper" style={{marginTop: "66px", position:"absolute"}}>
                            <p className="RequestsView__product-value-filter-label" style={{width: "45px"}}>{modals_lang[27][lang].slice(0, 6)}.:</p>
                            <p className="RequestsView__product-value-filter-value">{indexLang.lang !== undefined ? indexLang.lang[item.ttype2][lang] : item.ttype2}</p>
                        </div>
                    </>
                    }
                </div>

                <div className="ProdShow__product-value-wrapper">
                    <p className="ProdShow__value">{indexLang.countries !== undefined ? indexLang.countries[item.country][lang] : item.country}</p>
                </div>

                <div className="ProdShow__product-value-wrapper">
                    <p className="ProdShow__value">{parseDate(item.created_at)}</p>
                </div>

                <div className="ProdShow__action-container">
                    {selfID != item.parent && token !== "" && <p onClick={onOfferRequest} className="ProdShow__action-button">{market_lang[27][lang]}</p>}

                    <IoChevronDown
                        color="#0F62FE"
                        size={16}
                        className={"ProdShow__tile-chevron"+(opened?"-rotated":"")}
                        onClick={() => setOpenedState(!opened)}
                    />
                </div>
            </div>
            
            {!last && <div className="ProdShow__tile-divider"/>}
        </>
    );
}