import React, { useEffect, useState } from 'react';
import "../../pagestyles/PersonalView.css";
import { useOutletContext } from 'react-router-dom';

// IMPORT COMPONENTS
import { InfoField } from '../../components/FormComponents';
import { InfoModal } from '../../components/Modals';

// IMPORT LANGUAGE
import profile_file from "../../lang/profile.json";

// API
import UserService from '../../services/UserService';
const userService = new UserService();

const profile_lang = profile_file.lang;

export default function PersonalView({ lang=1 }) {
    const token = useOutletContext();

    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mail, setMail] = useState("");
    const [number, setNumber] = useState("");

    const [currentTitle, setCurrentTitle] = useState("");
    const [currentValues, setCurrentValues] = useState([]);
    const [currentLabels, setCurrentLabels] = useState([]);
    const [currentPlaceholders, setCurrentPlaceholders] = useState([]);

    const [infoModalState, setInfoModalState] = useState(false);
    const [currentVariant, setCurrentVariant] = useState(0);
    const [infoModalType, setInfoModalType] = useState("basic");

    const [renderTrigger, setRenderTrigger] = useState(0);

    useEffect(() => {
        userService.getUser(token, function (response) {
            let user = response.user;
            setName(user.first_name);
            setLastName(user.last_name);
            setMail(user.email);
            setNumber(user.phone);
        });
    }, []);

    function callRender() {
        setRenderTrigger(1-renderTrigger);
    }

    function openInfoModal(variant) {
        switch(variant) {
            case 0:
                setCurrentTitle(profile_lang[14][lang]);
                setCurrentLabels([profile_lang[15][lang], profile_lang[17][lang]]);
                setCurrentPlaceholders([
                    profile_lang[16][lang],
                    profile_lang[18][lang]
                ]);
                setCurrentValues([name, lastName]);
                setInfoModalType("basic");
                break;
            case 1:
                setCurrentTitle("Изменение адреса электронной почты");
                setCurrentLabels(["Адрес электронной почты"]);
                setCurrentPlaceholders([
                    "Введите адрес электронной почты"
                ]);
                setCurrentValues([""]);
                setInfoModalType("mail");
                break;
            case 2:
                setCurrentTitle(profile_lang[21][lang]);
                setCurrentLabels([profile_lang[22][lang]]);
                setCurrentValues([""]);
                setCurrentPlaceholders([""]);
                setInfoModalType("number");
                break;
            case 3:
                setCurrentTitle(profile_lang[26][lang]);
                setCurrentLabels([
                    profile_lang[27][lang],
                    profile_lang[28][lang],
                    profile_lang[29][lang]
                ]);
                setCurrentPlaceholders([
                    "",
                    "Не менее 10 символов",
                    ""
                ]);
                setCurrentValues(["", "", ""]);
                setInfoModalType("secure");
                break;
            default:
                break;
        }

        setInfoModalState(true);
    }

    const closeInfoModal = () => {
        setInfoModalState(false);
    }

    const handleValueChange = (value, index) => {
        let cv = currentValues;
        cv[index] = value;
        setCurrentValues(cv);
        callRender();
    }

    function handleModalAcception() {
        switch(currentVariant) {
            case 0:
                userService.patchUser(token, {
                    user: {
                        first_name: currentValues[0],
                        last_name: currentValues[1]
                    }
                }, function(response) {
                    let user = response.user;
                    setName(user.first_name);
                    setLastName(user.last_name);
                });
                break;
            case 1:
                setMail(currentValues[0]);
                break;
            case 2:
                userService.patchUser(token, {
                    user: {
                        phone: currentValues[0]
                    }
                }, function(response) {
                    let user = response.user;
                    setNumber(user.phone);
                });
                break;
            case 3:
                // update password
                break;
            default:
                break;
        }
    }

    return(
        <div className="PersonalView__root">
            <InfoModal
                visible={infoModalState}
                type={infoModalType}
                title={currentTitle}
                labels={currentLabels}
                values={currentValues}
                placeholders={currentPlaceholders}
                onChangeValue={handleValueChange}
                onRequestClose={closeInfoModal}
                onAcceptChanges={() => handleModalAcception()}
            />

            <div className="PersonalView__header-container">
                <p className="PersonalView__header-title">{profile_lang[9][lang]}</p>
            </div>

            <div className="PersonalView__body">
                <InfoField
                    label={profile_lang[10][lang]}
                    value={name+" "+lastName}
                    onClick={() => {
                        setCurrentVariant(0);
                        openInfoModal(0);
                    }}
                />

                <InfoField
                    label={profile_lang[11][lang]}
                    value={mail}
                    onClick={() => {
                        setCurrentVariant(1);
                        openInfoModal(1);
                    }}
                />

                <InfoField
                    label={profile_lang[12][lang]}
                    value={number}
                    onClick={() => {
                        setCurrentVariant(2);
                        openInfoModal(2);
                    }}
                />

                <InfoField
                    label={profile_lang[13][lang]}
                    secure={true}
                    onClick={() => {
                        setCurrentVariant(3);
                        openInfoModal(3);
                    }}
                />
            </div>
        </div>
    );
}