import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCOrBcD3XNdbgYz0FgX0NYJGtuJJVly2Sw",
    authDomain: "containers-cfff2.firebaseapp.com",
    projectId: "containers-cfff2",
    storageBucket: "containers-cfff2.appspot.com",
    messagingSenderId: "151823046002",
    appId: "1:151823046002:web:299d63a3121cf8788839eb"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export function getProductList(_callback) {
    let products = [];

    db.collection("products").where("name", "!=", "alias").get().then((qs) => {
        qs.forEach((doc) => {
            let data = doc.data();
            let indexes = [];
            db.collection("products").doc(doc.id).collection("indexes").get().then((indexqs) => {
                indexqs.forEach((item) => {
                    indexes.push(Object.assign(item.data(), { id: item.id }));
                });

                products.push(Object.assign(data, { indexes: indexes, id: doc.id }));
            }).catch(error => {
                console.log(error);
                _callback([]);
                return;
            });
        });

        _callback(products);
        return;
    }).catch(error => {
        console.log(error);
        _callback([]);
        return;
    });
}

export function getCategoriesList(_callback) {
    let categories = [];
    db.collection("products").where("name", "!=", "alias").get().then((qs) => {
        qs.forEach((doc) => {
            let data = doc.data();
            if(categories.length === 0) {
                categories.push({
                    label: data.category,
                    lang: data.cat_lang,
                    items: [
                        {
                            name: data.name,
                            lang: data.name_lang
                        }
                    ]
                });
            } else {
                let flag = false;
                categories.forEach((cat, index) => {
                    if(cat.label === data.category) {
                        let ctold = cat;
                        ctold.items.push({
                            name: data.name,
                            lang: data.name_lang
                        });
                        categories[index] = ctold;
                        flag = true;
                    }
                });

                if(!flag) {
                    categories.push({
                        label: data.category,
                        lang: data.cat_lang,
                        items: [
                            {
                                name: data.name,
                                lang: data.name_lang
                            }
                        ]
                    });
                }
            }
        });

        //console.log(categories);
        _callback(categories);
        return;
    }).catch(error => {
        console.log(error);
        _callback([]);
        return;
    });
}

export function getIndexesForItem(item, _callback) {
    //console.log(`Requested index list for item "${item}", processing...`);

    db.collection("products").where("name", "==", item).get().then((rootqs) => {
        rootqs.forEach(doc => {
            let id = doc.id;
            let indexes = [];
            db.collection("products").doc(id).collection("indexes").get().then((qs) => {
                qs.forEach((idoc) => {
                    indexes.push(Object.assign(idoc.data(), { id: idoc.id }));
                });

                //console.log("Request result:");
                //console.log(indexes);
                _callback(indexes);
                return;
            }).catch(error => {
                console.log(error);
                _callback([]);
                return;
            });
        });
    }).catch(error => {
        console.log(error);
        _callback([]);
        return;
    });
}

export function indexLanguage(_callback) {
    db.collection("products").doc("alias").get().then(doc => {
        let data = doc.data();
        //console.log(data);
        _callback(data);
        return;
    }).catch(e => {
        console.log(e);
        _callback({});
        return;
    });
}

export function translateCatAndItem(item, _callback) {
    db.collection("products").where("name", "==", item).get().then(qs => {
        qs.forEach((doc) => {
            //console.log(doc.data());
            _callback({
                catLang: doc.data().cat_lang,
                nameLang: doc.data().name_lang
            });
            return;
        });
    }).catch(e => {
        console.log(e);
        _callback({});
        return;
    });
}