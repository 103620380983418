import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import "../pagestyles/MainPage.css";

// LANGUAGE
import market_file from "../lang/market.json";

// API IMPORT
import UserService from '../services/UserService';
import ProductionService from '../services/ProductionService';
import ProductionRequestService from "../services/ProductionRequestService";

// COMPONENTS
import ProductionShowcaseView, { ProductionRequestShowcaseView } from './main/ProductionShowcase';
import SliderPicker, { SliderPickerLarge } from '../components/SilderPicker';
import FiltersView from './main/FiltersView';

// ASSETS
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

// API INITIALIZATION
const userService = new UserService();
const productionService = new ProductionService();
const productionRequestService = new ProductionRequestService();

const market_lang = market_file.lang;

export default function MainPage({ token, lang=1 }) {
    const [name, setName] = useState("");
    const { action } = useParams();

    const [tab, setTab] = useState(0);
    const [productionTab, setProductionTab] = useState(0);

    useEffect(() => {
        getUserInfo();
    }, []);
    
    function getUserInfo() {
        if(token !== "") {
            userService.getUser(token, function(response) {
                setName(response.user.first_name);
            });
        }
    }

    return(
        <div className="MainPage__container">
            {token === "abcabc" && // ha ha fix it later, it was token !== ""
            <SliderPickerLarge
                items={[market_lang[1][lang], market_lang[2][lang]]}
                value={tab}
                onChange={setTab}

                style={{
                    position: "fixed",
                    left: 0,
                    right: 0,
                    marginLeft: "auto",
                    marginRight: "auto",
                    top: "24px",
                    zIndex: "11",
                    width: "413px",
                    height: "48px"
                }}
                labelStyles={[
                    {
                        color: (tab===0?"#0F62FE":"#525252"),
                        fontWeight: "700",
                        fontSize: "14px",
                        lineHeight: "18px",
                        width: `calc((100% - 12px) / 2)`
                    },
                    {
                        color: (tab===1?"#0F62FE":"#525252"),
                        fontWeight: "700",
                        fontSize: "14px",
                        lineHeight: "18px",
                        width: `calc((100% - 12px) / 2)`
                    }
                ]}
            />
            }

            <p className="MainPage__greeting">{market_lang[3][lang]}{name !== "" ? `, ${name}` : ""}!</p>
            {
                token==="f"? // token === ""
                <p className="MainPage__sub-greeting" style={{marginBottom:0}}>Войдите, чтобы получить полный доступ к платформе</p>
                :
                <>
                    
                </>
            }

            <p className="MainPage__sub-greeting">{market_lang[4][lang]}</p>
                    {tab === 0 ?
                    <>
                        <SliderPickerLarge
                            items={[market_lang[5][lang], market_lang[6][lang]]}
                            value={productionTab}
                            onChange={setProductionTab}

                            style={{
                                width: "300px",
                                height: "48px",
                                marginLeft: "64px",
                                borderColor: "#f00",
                                borderWidth: "2px"
                            }}
                            labelStyles={[
                                {
                                    color: (productionTab===0?"#0F62FE":"#525252"),
                                    fontWeight: "700",
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    width: `50%`
                                },
                                {
                                    color: (productionTab===1?"#0F62FE":"#525252"),
                                    fontWeight: "700",
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    width: `50%`,
                                    marginLeft: "6px"
                                }
                            ]}
                        />
                        {productionTab===0 ?
                        <ProductionSegment token={token} lang={lang}/>
                        :
                        <ProductionRequestSegment token={token} lang={lang}/>
                        }
                    </>
                    :
                    <div/>
                    }
        </div>
    );
}

function ProductionSegment({
    token,
    lang
}) {
    const [production, setProduction] = useState([]);
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [prevPage, setPrevPage] = useState(1);
    const [nextPage, setNextPage] = useState(1);
    const [count, setCount] = useState(0);

    const [filters, setFilters] = useState({});

    useEffect(() => {
        //if(token !== "") {
            loadFirstPage();
        //}
    }, []);

    function loadFirstPage() {
        productionService.getPagedFilteredProductionsList(token, filters, 1, function(response) {
            setProduction(response.data);
            setCount(response.count);
            setCurrentPage(1);
            setPages(response.numpages);
            setPrevPage(response.prevpage);
            setNextPage(response.nextpage);
        });
    }

    function loadPage(index) {
        productionService.getPagedFilteredProductionsList(token, filters, index, function(response) {
            setProduction(response.data);
            setCount(response.count);
            setCurrentPage(index);
            setPages(response.numpages);
            setPrevPage(response.prevpage);
            setNextPage(response.nextpage);
        });
    }

    const onFiltersUpdate = (_filters) => {
        setFilters(_filters);
        productionService.getPagedFilteredProductionsList(token, _filters, 1, function(response) {
            setProduction(response.data);
            setCount(response.count);
            setCurrentPage(1);
            setPages(response.numpages);
            setPrevPage(response.prevpage);
            setNextPage(response.nextpage);
        });
    }

    return(
        <>
            <FiltersView onResponse={onFiltersUpdate} lang={lang}/>

            <ProductionShowcaseView production={production} token={token} lang={lang} />

            <p className="MainPage__pages-label">{market_lang[17][lang]} {(currentPage-1)*10+1}-{(currentPage-1)*10+production.length} / {count}</p>
            <div className="MainPage__pages-control">
                <IoChevronBack
                    color={prevPage !== currentPage ? "#161616" : "#A8A8A8"}
                    size={16}
                    style={prevPage !== currentPage ? {cursor:"pointer"} : {cursor:"default"}}
                    onClick={() => {
                        if(prevPage !== currentPage) {
                            loadPage(prevPage);
                        }
                    }}
                />
                <p
                    style={pages > 1 ? {color: "#161616"} : {color: "#A8A8A8"}}
                >{currentPage} / {pages}</p>
                <IoChevronForward
                    color={nextPage !== currentPage ? "#161616" : "#A8A8A8"}
                    size={16}
                    style={nextPage !== currentPage ? {cursor:"pointer"} : {cursor:"default"}}
                    onClick={() => {
                        if(nextPage !== currentPage) {
                            loadPage(nextPage);
                        }
                    }}
                />
            </div>

            <p className="MainPage__advice">{market_lang[19][lang]} <Link to="/my" style={{textDecoration:"none"}}><span className="MainPage__advice-action">{market_lang[20][lang]}</span></Link></p>
        </>
    );
}


function ProductionRequestSegment({
    token,
    lang
}) {
    const [production, setProduction] = useState([]);
    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [prevPage, setPrevPage] = useState(1);
    const [nextPage, setNextPage] = useState(1);
    const [count, setCount] = useState(0);

    const [filters, setFilters] = useState({});

    useEffect(() => {
        //if(token !== "") {
            loadFirstPage();
        //}
    }, []);

    function loadFirstPage() {
        productionRequestService.getPagedFilteredProductionsList(token, filters, 1, function(response) {
            setProduction(response.data);
            setCount(response.count);
            setCurrentPage(1);
            setPages(response.numpages);
            setPrevPage(response.prevpage);
            setNextPage(response.nextpage);
        });
    }

    function loadPage(index) {
        productionRequestService.getPagedFilteredProductionsList(token, filters, index, function(response) {
            setProduction(response.data);
            setCount(response.count);
            setCurrentPage(index);
            setPages(response.numpages);
            setPrevPage(response.prevpage);
            setNextPage(response.nextpage);
        });
    }

    const onFiltersUpdate = (_filters) => {
        setFilters(_filters);
        productionRequestService.getPagedFilteredProductionsList(token, _filters, 1, function(response) {
            setProduction(response.data);
            setCount(response.count);
            setCurrentPage(1);
            setPages(response.numpages);
            setPrevPage(response.prevpage);
            setNextPage(response.nextpage);
        });
    }

    return(
        <>
            <FiltersView onResponse={onFiltersUpdate} lang={lang}/>

            <ProductionRequestShowcaseView token={token} production={production} lang={lang} />

            <p className="MainPage__pages-label">{market_lang[17][lang]} {(currentPage-1)*10+1}-{(currentPage-1)*10+production.length} / {count}</p>
            <div className="MainPage__pages-control">
                <IoChevronBack
                    color={prevPage !== currentPage ? "#161616" : "#A8A8A8"}
                    size={16}
                    style={prevPage !== currentPage ? {cursor:"pointer"} : {cursor:"default"}}
                    onClick={() => {
                        if(prevPage !== currentPage) {
                            loadPage(prevPage);
                        }
                    }}
                />
                <p
                    style={pages > 1 ? {color: "#161616"} : {color: "#A8A8A8"}}
                >{currentPage} / {pages}</p>
                <IoChevronForward
                    color={nextPage !== currentPage ? "#161616" : "#A8A8A8"}
                    size={16}
                    style={nextPage !== currentPage ? {cursor:"pointer"} : {cursor:"default"}}
                    onClick={() => {
                        if(nextPage !== currentPage) {
                            loadPage(nextPage);
                        }
                    }}
                />
            </div>

            <p className="MainPage__advice">Не нашли подходящую заявку? <Link to="/my" style={{textDecoration:"none"}}><span className="MainPage__advice-action">Добавить продукцию</span></Link></p>
        </>
    );
}