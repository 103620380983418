import axios from "axios";
import { API_URL } from "./ApiCredentials";

export default class StockService {
    constructor() {}

    addStock(data, token, _callback) {
        const url = `${API_URL}/api/stocks/add`;
        const headers = {
            'content-type': 'application/json',
            'Authorization': `Token ${token}`
        };

        axios.post(
            url,
            { "stock": data },
            { headers: headers }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback({});
            return;
        });
    }

    getStockInfo(pk, token, _callback) {
        const url = `${API_URL}/api/stock`;
        let headers = {
            'content-type': 'application/json'
        };

        if(token !== "") {
            headers = Object.assign({'Authorization': `Token ${token}`});
        }

        axios.post(
            url,
            { "stock": { "pk": pk } },
            { headers: headers }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback({});
            return;
        });
    }

    deleteStock(pk, token, _callback) {
        const url = `${API_URL}/api/stock`;
        const headers = {
            'content-type': 'application/json',
            'Authorization': `Token ${token}`,
        };

        axios.delete(
            url,
            {
                headers: headers,
                data: { "stock": { "pk": pk } }
            }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback({});
            return;
        });
    }

    getStockList(token, _callback) {
        const url = `${API_URL}/api/stocks/list`;
        const headers = {
            'content-type': 'application/json',
            'Authorization': `Token ${token}`
        };

        axios.get(
            url,
            { headers: headers }
        ).then(response => {
            _callback(response.data.stocks);
            return;
        }).catch(e => {
            console.log(e);
            _callback([]);
            return;
        })
    }
}