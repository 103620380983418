import React, { useEffect, useState, useRef } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom'; 

// IMPORT LANGUAGE
import auth_file from "../lang/auth.json";
import profile_file from "../lang/profile.json";
import mdls_file from "../lang/modals.json";
import ds_file from "../lang/deals.json";
import mk_file from "../lang/market.json";

// IMPORT STYLES
import "./Modals.css";

// IMPORT COMPONENTS
import SliderPicker, { MultiPickerLarge, SliderPickerLarge } from './SilderPicker';
import { TextField, SecureTextField, NumberField } from './TextInputs';
import { BlueButton, GreyButton, YellowButton } from "./Buttons";
import { DropdownPicker, SquareDropdownPicker } from './FormComponents';

// IMPORT ASSETS
import doneIcon from "../assets/done.png";
import editIcon from "../assets/edit.png";
import { IoChevronDown } from 'react-icons/io5';

// FIREBASE API
import { getCategoriesList, getIndexesForItem, getProductList, indexLanguage, translateCatAndItem } from '../services/FirebaseApi';

// EMAIL SERVICE
import EmailService from '../services/EmailService';

// DJANGO BACKEND
import axios from 'axios';

import UserService from '../services/UserService';
import CompanyService from '../services/CompanyService';
import ProductionService from '../services/ProductionService';
import ProductionRequestService from '../services/ProductionRequestService';
import StockService from '../services/StockService';
import TransactionService from '../services/TransactionService';

const userService = new UserService();
const companyService = new CompanyService();
const productionService = new ProductionService();
const productionRequestService = new ProductionRequestService();
const stockService = new StockService();
const transactionService = new TransactionService();

const emailService = new EmailService();

const auth_lang = auth_file.lang;
const profile_lang = profile_file.lang;
const modal_lang = mdls_file.lang;
const deals_lang = ds_file.lang;
const market_lang = mk_file.lang;

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function LogInModal({
    visible,
    onRequestClose,
    handleModeChange,
    onSuccess,
    onRestorePassword,
    lang=1 // 0 - ru, 1 - en, 2 - cn
}) {
    const [logInMode, setLogInMode] = useState(0);

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");

    const [savePassword, setSavingState] = useState(false);

    const [number, setNumber] = useState("");

    const [displayed, setDisplayed] = useState(false);

    const [mailError, setMailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [numberError, setNumberError] = useState(false);

    const toggleClose = async () => {
        restoreErrors();
        setDisplayed(false);
        await timeout(300);
        onRequestClose();
    }

    const toggleModeChange = () => {
        toggleClose();
        handleModeChange();
    }

    const changeLogInMode = (value) => {
        setLogInMode(value);
    }

    const handleLoginInput = (text) => {
        setLogin(text);
    }

    const handlePasswordInput = (text) => {
        setPassword(text);
    }

    const handleNumberInput = (text) => {
        setNumber(text);
    }

    const tryAuth = () => {
        var correct = true;
        if(login === "" && logInMode === 0) {
            setMailError(true);
            correct = false;
        }
        
        if(password.length < 8 && logInMode === 0) {
            setPasswordError(true);
            correct = false;
        }

        if(number.length !== 13 && logInMode === 1) {
            setNumberError(true);
            correct = false;
        }

        if(correct) {
            //console.log("correct -> try auth");
            userService.signInWithEmailAndPassword(login, password, savePassword, function (userCredentials) {
                //console.log(userCredentials);
                
                if(userCredentials.user === undefined) {
                    setPasswordError(true);
                } else {
                    //console.log(`SUCCESS: email ${userCredentials.user.email}`);
                    onSuccess(userCredentials.user.email, userCredentials.user.token);
                    toggleClose();
                }
            });

            /*
.then( function(credentials) {
                console.log(credentials.token);
                onSuccess(login, credentials.token);
            });
            */
        }
    }

    function restoreErrors() {
        setMailError(false);
        setPasswordError(false);
        setNumberError(false);
    }

    useEffect(() => {
        if(visible) {
            toggleOpen();
        }
    }, [visible]);

    async function toggleOpen() {
        await timeout(10);
        setDisplayed(true);
    }

    if(visible) {
        return(
            <div className="LogInModal__root">
                <div 
                    className={displayed ? "LogInModal__background" : "LogInModal__background-transparent"}
                    onClick={toggleClose}
                />

                <div className={displayed ? "LogInModal__container" : "LogInModal__container-hidden"}>
                    <div className="LogInModal__header">
                        <div className="LogInModal__header-left">
                            <p className="LogInModal__label">{auth_lang[28][lang]}</p>
                            <p className="LogInModal__switch-signup">Нет аккаунта? <span onClick={toggleModeChange} className="LogInModal__switch-signup-link">{auth_lang[3][lang]}</span></p>
                        </div>

                        {/*
                        <SliderPicker 
                            items={["Пароль", "Смс-Код"]} 
                            value={logInMode}
                            onChange={changeLogInMode}
                        />
                        */}
                    </div>

                    <div className="LogInModal__body">
                        {logInMode === 0 ? 
                        <div className="LogInModal__body-password">
                            <TextField
                                label={auth_lang[2][lang]}
                                value={login}
                                onChangeText={handleLoginInput}
                                placeholder={auth_lang[4][lang]}
                                error={mailError}
                                errorMessage={modal_lang[32][lang]}
                            />

                            <SecureTextField
                                label={auth_lang[5][lang]}
                                value={password}
                                onChangeText={handlePasswordInput}
                                style={{
                                    marginTop: 32
                                }}
                                error={passwordError}
                                errorMessage="Неправильный пароль"
                            />

                            <div className="LogInModal__options-container">
                                <div className="LogInModal__options-checkbox-wrapper" onClick={() => setSavingState(!savePassword)}>
                                    <div className={"LogInModal__option-checkbox"+(savePassword?"-active":"")}>
                                        {savePassword && <div className="LogInModal__option-checkbox-inner"/>}
                                    </div>

                                    <p className="LogInModal__option-checkbox-label">{auth_lang[6][lang]}</p>
                                </div>

                                <p className="LogInModal__option-forget" onClick={onRestorePassword}>{auth_lang[7][lang]}</p>
                            </div>

                            <BlueButton
                                text={auth_lang[0][lang]}
                                style={{
                                    width: 480,
                                    marginTop: 32
                                }}
                                onClick={tryAuth}
                            />
                        </div> 
                        : 
                        <div className="LogInModal__body-number">
                            <NumberField
                                label="Телефон"
                                placeholder="999 999-99-99"
                                value={number}
                                onChangeText={handleNumberInput}
                                countryCodes={["+7 RU", "+380 UA", "+1 US", "+4 EU"]}
                                error={numberError}
                                errorMessage="Введите корректный номер телефона"
                            />

                            <BlueButton
                                text="Отправить код"
                                style={{
                                    width: 480,
                                    marginTop: 32
                                }}
                                onClick={tryAuth}
                            />
                        </div>
                        }
                    </div>    
                </div>
            </div>
        );
    }
}

export function SignUpModal({
    visible,
    onRequestClose,
    handleModeChange,
    onSuccess,
    lang=1
}) {
    const [signUpMode, setSignUpMode] = useState(0);

    const [mail, setMail] = useState("");
    const [password, setPassword] = useState("");

    const [number, setNumber] = useState("");

    const [displayed, setDisplayed] = useState(false);

    const [signUpStage, setSignUpStage] = useState(0);

    const [mailError, setMailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [numberError, setNumberError] = useState(false);

    const [verificationIsActive, setVerificationState] = useState(false);

    const [verificationCode, setVerificationCode] = useState("");
    const [correctVerificationCode, setCorrectVerificationCode] = useState("");
    const [codeError, setCodeError] = useState(false);
    const checkVerificationCode = () => {
        
        if(verificationCode === correctVerificationCode) {
            // continue
            userService.signUpWithEmailAndPassword(
                mail,
                password,
                function(response) {
                    if(response.user !== undefined) {
                        userService.patchUser(response.user.token, {
                            "user": {
                                "first_name": firstName,
                                "last_name": lastName,
                                "phone": phone
                            }
                        }, function(_r) {});
                        onSuccess(response.user.email, response.user.token);
                        toggleClose();
                        setSignUpStage(0);
                        setMail("");
                        setPassword("");
                        setCorrectVerificationCode("");
                        setVerificationCode("");
                    }
                }
            );
        } else {
            setCodeError(true);
            setVerificationCode("");
        }
    }

    function initVerification() {
        if(!verificationIsActive) {
            setVerificationState(true);

            let vcode = Math.round(Math.random() * (999999 - 100000) + 100000);
            let codestr = vcode.toString();
            //console.log(typeof codestr);
            setCorrectVerificationCode(codestr);
            emailService.sendVerificationLetter(
                mail,
                "ru",
                codestr,
                function(response) {
                    //console.log(response);
                    if(response) {
                        setSignUpStage(1);
                    }
                }
            );
        }
    }

    useEffect(() => {
        if(verificationCode.length > 0 && verificationCode.length < 6 && codeError) {
            setCodeError(false);
        }
    }, [verificationCode]);

    /*useEffect(() => {
        if(verificationCode.length === 6) {
            if(!checkVerificationCode()) {
                setCodeError(true);
                setVerificationCode("");
            } else {
                // continue
            }
        } else if(verificationCode.length > 0) {
            setCodeError(false);
        }
    }, [verificationCode]);*/

    const toggleClose = async () => {
        restoreErrors();
        setDisplayed(false);
        setSignUpStage(0);
        setMail("");
        setPassword("");
        setVerificationState(false);
        await timeout(300);
        onRequestClose();
    }

    const toggleModeChange = () => {
        toggleClose();
        handleModeChange();
    }

    const changeSignUpMode = (value) => {
        setSignUpMode(value);
    }

    const handleMailInput = (text) => {
        setMail(text);
    }

    const handlePasswordInput = (text) => {
        setPassword(text);
    }

    const handleNumberInput = (text) => {
        setNumber(text);
    }

    const updateStage = () => {
        var correct = true;
        if(mail === "" && signUpMode === 0) {
            setMailError(true);
            correct = false;
        }
        
        if(password.length < 10 && signUpMode === 0) {
            setPasswordError(true);
            correct = false;
        }

        if(number.length !== 13 && signUpMode === 1) {
            setNumberError(true);
            correct = false;
        }

        if(correct) {
            initVerification();
        }
    }

    function restoreErrors() {
        setMailError(false);
        setPasswordError(false);
        setNumberError(false);
    }

    useEffect(() => {
        if(visible) {
            toggleOpen();
        }
    }, [visible]);

    async function toggleOpen() {
        await timeout(10);
        setDisplayed(true);
    }

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");

    const [isMobile, setMobile] = useState(false);
    useEffect(() => {
        setMobile(window.innerWidth < 600);
    }, []);

    if(visible) {
        return(
            <div className="SignUpModal__root">
                <div 
                    className={displayed ? "SignUpModal__background" : "SignUpModal__background-transparent"}
                    onClick={toggleClose}
                />

                <div className={displayed ? "SignUpModal__container" : "SignUpModal__container-hidden"}>
                    <div className="SignUpModal__stage-header">
                        <div className="SignUpModal__stage-item">
                            <div className="SignUpModal__stage-item-check">
                                {signUpStage > 0 ? 
                                <img src={doneIcon} className="SignUpModal__stage-item-check-icon" /> 
                                : <div/>}
                            </div>

                            <div className="SignUpModal__stage-item-text-wrapper">
                                <p className="SignUpModal__stage-item-label">{auth_lang[8][lang]}</p>
                                {!isMobile && <p className="SignUpModal__stage-item-desc">{auth_lang[26][lang]}</p>}
                            </div>
                        </div>

                        <div className={"SignUpModal__stage-item"+(signUpStage === 0 ? "-inactive" : "")}>
                            <div className={"SignUpModal__stage-item-check"+(signUpStage === 0 ? "-inactive" : "")}>
                                {signUpStage > 1 ? 
                                <img src={doneIcon} className="SignUpModal__stage-item-check-icon" /> 
                                : <div/>}
                            </div>

                            <div className="SignUpModal__stage-item-text-wrapper">
                                <p className="SignUpModal__stage-item-label">{auth_lang[16][lang]}</p>
                                {!isMobile && <p className="SignUpModal__stage-item-desc">{auth_lang[45][lang]}</p>}
                            </div>
                        </div>

                        <div className={"SignUpModal__stage-item"+(signUpStage < 2 ? "-inactive" : "")}>
                            <div className={"SignUpModal__stage-item-check"+(signUpStage < 2 ? "-inactive" : "")}>

                            </div>

                            <div className="SignUpModal__stage-item-text-wrapper">
                                <p className="SignUpModal__stage-item-label">{auth_lang[44][lang]}</p>
                                {!isMobile && <p className="SignUpModal__stage-item-desc">{auth_lang[17][lang]}</p>}
                            </div>
                        </div>
                    </div>

                    {signUpStage === 0 ?
                    <>
                    <div className="SignUpModal__header">
                        <div className="SignUpModal__header-left">
                            <p className="SignUpModal__label">{auth_lang[1][lang]}</p>
                            <p className="SignUpModal__switch-signup">{auth_lang[27][lang]} <span onClick={toggleModeChange} className="LogInModal__switch-signup-link">{auth_lang[0][lang]}</span></p>
                        </div>

                        {/*
                        <SliderPicker 
                            items={["Почта", "Телефон"]} 
                            value={signUpMode}
                            onChange={changeSignUpMode}
                        />
                        */}
                    </div>

                    <div className="SignUpModal__body">
                        {signUpMode === 0 ? 
                        <div className="SignUpModal__body-password">
                            <TextField
                                label={auth_lang[29][lang]}
                                value={mail}
                                onChangeText={handleMailInput}
                                placeholder={auth_lang[30][lang]}
                                error={mailError}
                                errorMessage={modal_lang[32][lang]}
                                inputWidth="calc(100% - 48px)"
                                style={{width:"calc(100% - 32px)"}}
                            />

                            <SecureTextField
                                label={auth_lang[5][lang]}
                                value={password}
                                onChangeText={handlePasswordInput}
                                placeholder={auth_lang[31][lang]}
                                style={{
                                    marginTop: 12,
                                    width:"calc(100% - 64px)"
                                }}
                                error={passwordError}
                                errorMessage={auth_lang[32][lang]}
                                inputWidth="calc(100% - 32px)"
                            />

                            {lang===0?
                            <p className="SignUpModal__body-legal-text">
                                {/* Нажимая Продолжить, вы подтверждаете и даете согласие с <span style={{color: "#0F62FE", cursor: 'pointer'}}>Политикой конфиденциальности</span> и <span style={{color: "#0F62FE", cursor: 'pointer'}}>Условиями использования платформы</span> */}
                                {auth_lang[33][lang]}
                            </p>
                            :
                            <p className="SignUpModal__body-legal-text">
                                {auth_lang[33][lang]}
                            </p>
                            }
                            

                            <div className="SignUpModal__body-buttons-container">
                                <GreyButton
                                    text={auth_lang[34][lang]}
                                    style={{
                                        width: "100%",
                                        marginTop: 32,
                                        marginRight: 16
                                    }}
                                    onClick={onRequestClose}
                                />

                                <BlueButton
                                    text={auth_lang[35][lang]}
                                    style={{
                                        width: "100%",
                                        marginTop: 32
                                    }}
                                    onClick={updateStage}
                                />
                            </div>
                        </div> 
                        : 
                        <div className="SignUpModal__body-number">
                            <NumberField
                                label="Телефон"
                                placeholder="999 999-99-99"
                                value={number}
                                onChangeText={handleNumberInput}
                                countryCodes={["+7 RU", "+380 UA", "+1 US", "+4 EU"]}
                                error={numberError}
                                errorMessage="Введите корректный номер телефона"
                            />

                            <p className="SignUpModal__body-legal-text">
                                Нажимая Продолжить, вы подтверждаете и даете согласие с <span style={{color: "#0F62FE"}}>Политикой конфиденциальности</span> и <span style={{color: "#0F62FE"}}>Условиями использования платформы</span>
                            </p>

                            <div className="SignUpModal__body-buttons-container">
                                <GreyButton
                                    text="Вернуться"
                                    style={{
                                        width: 232,
                                        marginTop: 32
                                    }}
                                    onClick={onRequestClose}
                                />

                                <BlueButton
                                    text={auth_lang[35][lang]}
                                    style={{
                                        width: 232,
                                        marginTop: 32
                                    }}
                                    onClick={updateStage}
                                />
                            </div>
                        </div>
                        }
                    </div>  
                    </>
                    :
                    (signUpStage === 1 ? 
                    <div className="SignUpModal__body">
                        <p className="SignUpModal__body-pwd-conf-header">Ввод персональных данных</p>
                        <TextField
                            value={firstName}
                            onChangeText={setFirstName}
                            inputWidth="calc(100% - 48px)"
                            style={{
                                width:"calc(100% - 32px)",
                                marginTop: 32
                            }}
                            
                            label={profile_lang[15][lang]}
                            placeholder={profile_lang[16][lang]}
                            maxLength={30}
                        />

                        <TextField
                            value={lastName}
                            onChangeText={setLastName}
                            inputWidth="calc(100% - 48px)"
                            style={{
                                width:"calc(100% - 32px)"
                            }}
                            
                            label={profile_lang[17][lang]}
                            placeholder={profile_lang[18][lang]}
                            maxLength={30}
                        />

                        <TextField
                            value={phone}
                            onChangeText={setPhone}
                            inputWidth="calc(100% - 48px)"
                            style={{
                                width:"calc(100% - 32px)"
                            }}
                            
                            label={profile_lang[22][lang]}
                            placeholder={auth_lang[46][lang]}
                            maxLength={30}
                        />

                        <div className="SignUpModal__body-buttons-container">
                            <GreyButton
                                text={modal_lang[12][lang]}
                                style={{
                                    width: "100%",
                                    marginTop: 32,
                                    marginRight: "16px"
                                }}
                                onClick={onRequestClose}
                            />

                            <BlueButton
                                text={auth_lang[35][lang]}
                                style={{
                                    width: "100%",
                                    marginTop: 32
                                }}
                                onClick={() => {setSignUpStage(2)}}
                            />
                        </div>
                    </div> 
                    :
                    <div className="SignUpModal__body">
                        {signUpMode === 0 ?
                        <>
                            <p className="SignUpModal__body-pwd-conf-header">{auth_lang[36][lang]}</p>
                            <p className="SignUpModal__body-pwd-conf-text">{auth_lang[42][lang]}“{mail}”{auth_lang[43][lang]}</p>
                            <TextField
                                value={verificationCode}
                                onChangeText={setVerificationCode}
                                error={codeError}
                                errorMessage={auth_lang[39][lang]}
                                inputWidth="calc(100% - 48px)"
                                style={{
                                    width:"calc(100% - 32px)",
                                    marginTop: 32
                                }}
                            
                                label={auth_lang[21][lang]}
                                maxLength={6}
                            />

                            <div className="SignUpModal__body-pwd-conf-retry">
                                <p className="SignUpModal__body-pwd-conf-retry-ask">{auth_lang[40][lang]}</p>
                                <p className="SignUpModal__body-pwd-conf-retry-action">{auth_lang[41][lang]}</p>
                            </div>

                            <BlueButton
                                text={auth_lang[38][lang]}
                                style={{
                                    width: "calc(100% - 64px)",
                                    marginTop: 46,
                                    marginBottom: 32
                                }}
                                onClick={checkVerificationCode}
                            />
                        </>    
                        :
                        <>
                            <p className="SignUpModal__body-pwd-conf-header">Подтвердите номер телефона</p>
                            <p className="SignUpModal__body-pwd-conf-text">На указанный номер телефона “{number}” отправлен 6-ти значный код. Введите его в указанное поле ниже</p>
                            <TextField
                                label="Код верификации"
                                style={{
                                    marginTop: 32
                                }}
                                maxLength={6}
                            />

                            <div className="SignUpModal__body-pwd-conf-retry">
                                <p className="SignUpModal__body-pwd-conf-retry-ask">Не пришел код?</p>
                                <p className="SignUpModal__body-pwd-conf-retry-action">Отправить снова</p>
                            </div>

                            <BlueButton
                                text="Отправить"
                                style={{
                                    width: 480,
                                    marginTop: 46,
                                    marginBottom: 32
                                }}
                            />
                        </>
                        }
                    </div>
                    )
                    }  
                </div>
            </div>
        );
    }
}

export function ProfileModal({
    visible,
    onRequestClose,
    onLogOut,
    lang=1
}) {
    const [displayed, setDisplayed] = useState(false);
    const location = useLocation();
    const [selectedItem, setSelectedItem] = useState(0);


    useEffect(() => {
        if(visible) {
            toggleOpen();
        }
    }, [visible]);

    async function toggleOpen() {
        await timeout(10);
        setDisplayed(true);
    }

    useEffect(() => {
        let path = location.pathname.split("/");
        switch(path[1]) {
            case "":
                setSelectedItem(2);
                break;
            case "account":
                if(path.length === 2) {
                    setSelectedItem(0);
                } else {
                    setSelectedItem(3);
                }
                break;
            case "my":
                setSelectedItem(1);
                break;
            default:
                break;
        }
    }, [location]);

    const toggleClose = async () => {
        setDisplayed(false);
        await timeout(300);
        onRequestClose();
    }

    const toggleClick = () => {
        toggleClose();
    }

    const ref = useOutsideClick(toggleClick);

    if(visible) {
        return(
            <div className={"ProfileModal__container"+(displayed ? "" : "-hidden")} ref={ref}>
                <Link to="/account" style={{ textDecoration: 'none' }}>
                    <p className="ProfileModal__item">{profile_lang[0][lang]}</p>
                </Link>

                <Link to="/my" style={{ textDecoration: 'none' }}>
                    <p className="ProfileModal__item">{profile_lang[2][lang]}</p>
                </Link>
                
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <p className="ProfileModal__item">{profile_lang[3][lang]}</p>
                </Link>

                <Link to="/account/feedback" style={{ textDecoration: 'none' }}>
                    <p className="ProfileModal__item">{profile_lang[4][lang]}</p>
                </Link>
                
                <p 
                    className="ProfileModal__item-exit"
                    onClick={() => {
                        onLogOut();
                        toggleClose();
                    }}
                >{profile_lang[5][lang]}</p>
                <div className="ProfileModal__indicator" style={{ top: 21+selectedItem*40 }}/>
            </div>
        );
    }
}

const useOutsideClick = (callback) => {
    const ref = React.useRef();
  
    React.useEffect(() => {
        const handleClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };
  
        document.addEventListener('click', handleClick, true);
  
        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [ref]);
  
    return ref;
};

export function InfoModal({
    visible,
    title,
    subtitle,
    labels,
    values,
    placeholders,
    onChangeValue,
    onRequestClose,
    onAcceptChanges,
    type,
    lang=1
}) {
    const [displayed, setDisplayed] = useState(false);
    const [stage, setStage] = useState(0);

    // for code + value
    const [code, setCode] = useState("+7");
    const [phoneCode, setPhoneCode] = useState(""); // 000000

    // errors
    const [errors, setErrors] = useState([false, false, false]);
    const [trigger, setTrigger] = useState(0);

    useEffect(() => {
        if(visible) {
            toggleOpen();
        }
    }, [visible]);

    async function toggleOpen() {
        await timeout(10);
        setDisplayed(true);
    }

    const toggleClose = async () => {
        setDisplayed(false);
        await timeout(200);
        setStage(0);
        setErrors([false, false, false]);
        onRequestClose();
    }

    const acceptChanges = () => {
        onAcceptChanges();
        toggleClose();
    }

    if(visible) {
        return(
            <div className="InfoModal__root">
                <div 
                    className={"InfoModal__background"+(displayed ? "" : "-hidden")}
                    onClick={toggleClose}
                />
                <div className={"InfoModal__container"+(displayed ? "" : "-hidden")}>
                    {type === "basic" ? 
                    <>
                        <p className="InfoModal__header">{title}</p>

                        <div className="InfoModal__input-container">
                            {labels.map((label, index) => {
                                return(
                                    <TextField
                                        key={index}
                                        label={label}
                                        value={values[index]}
                                        placeholder={placeholders[index]}
                                        onChangeText={text => {
                                            onChangeValue(text, index);
                                        }}
                                        style={{
                                            marginBottom: 16,
                                            width: "100%"
                                        }}
                                        inputWidth="100%"
                                        error={values[index] === ""}
                                        errorMessage={modal_lang[32][lang]}
                                    />
                                );
                            })}
                        </div>

                        <div className="InfoModal__button-container">
                            <GreyButton
                                text={modal_lang[5][lang]}
                                onClick={toggleClose}
                                style={{
                                    width: 233,
                                    height: 32
                                }}
                            />

                            <BlueButton
                                text={profile_lang[20][lang]}
                                onClick={acceptChanges}
                                style={{
                                    width: 233,
                                    height: 32
                                }}
                            />
                        </div>
                    </> 
                    : (type === "mail" ? 
                    <>
                        <div className="InfoModal__progress-container">
                            <div className="InfoModal__progress-item">
                                <div className="InfoModal__progress-circle">
                                    {stage===1?<img src={doneIcon} className="InfoModal__progress-icon" />:<div/>}
                                </div>

                                <div className="InfoModal__progress-text-container">
                                    <p className="InfoModal__progress-title">{auth_lang[8][lang]}</p>
                                    <p className="InfoModal__progress-subtitle">Изменение почты</p>
                                </div>
                            </div>

                            <div className={"InfoModal__progress-item"+(stage===0?"-inactive":"")}>
                                <div className={"InfoModal__progress-circle"+(stage===0?"-inactive":"")}/>

                                <div className="InfoModal__progress-text-container">
                                    <p className="InfoModal__progress-title">{auth_lang[16][lang]}</p>
                                    <p className="InfoModal__progress-subtitle">{modal_lang[39][lang]}</p>
                                </div>
                            </div>
                        </div>

                        <div className="InfoModal__body">
                            {stage === 0 ? 
                            <>
                                <p className="InfoModal__header">{title}</p>

                                <TextField
                                    label={labels[0]}
                                    value={values[0]}
                                    placeholder={placeholders[0]}
                                    onChangeText={text => {
                                        onChangeValue(text, 0);
                                    }}
                                    style={{
                                        marginLeft: 32,
                                        marginTop: 22,
                                        marginBottom: 32,
                                        width: "100%"
                                    }}
                                    inputWidth="calc(100% - 80px)"
                                />

                                <div className="InfoModal__button-container">
                                    <GreyButton
                                        text={modal_lang[5][lang]}
                                        onClick={toggleClose}
                                        style={{
                                            width: 233,
                                            height: 32
                                        }}
                                    />

                                    <BlueButton
                                        text={modal_lang[6][lang]}
                                        onClick={() => {
                                            //console.log("ACTION: SEND CODE");
                                            setStage(1);
                                        }}
                                        style={{
                                            width: 233,
                                            height: 32
                                        }}
                                    />
                                </div>
                            </>
                            :
                            <>
                                <p className="InfoModal__header">Подтверждение адреса электронной почты</p>

                                <p className="InfoModal__subheader">На адрес “{values[0]}” было отправлено письмо с 6-ти значным кодом. Введите его в поле ниже, либо перейдите по ссылке указаной в письме, чтобы пройти верификацию</p>

                                <TextField
                                    label="Код подтверждения"
                                    placeholder=""
                                    style={{
                                        marginTop: 23,
                                        marginLeft: 32,
                                        marginBottom: 12,
                                        width: "100%"
                                    }}
                                    inputWidth="calc(100% - 80px)"
                                    maxLength={6}
                                    errorMessage="Неверный код"
                                    error={errors[1]}
                                />

                                <div className="InfoModal__resend-container">
                                    <p className="InfoModal__resend-question">Не пришел код?</p>
                                    <p className="InfoModal__resend-action">Отправить снова</p>
                                </div>
                            </>
                            }
                        </div>

                        
                    </> 
                    : (type === "number" ?
                    <>
                        <div className="InfoModal__progress-container">
                            <div className="InfoModal__progress-item">
                                <div className="InfoModal__progress-circle">
                                    {stage===1?<img src={doneIcon} className="InfoModal__progress-icon" />:<div/>}
                                </div>

                                <div className="InfoModal__progress-text-container">
                                    <p className="InfoModal__progress-title">{auth_lang[8][lang]}</p>
                                    <p className="InfoModal__progress-subtitle">Изменение номера телефона</p>
                                </div>
                            </div>

                            <div className={"InfoModal__progress-item"+(stage===0?"-inactive":"")}>
                                <div className={"InfoModal__progress-circle"+(stage===0?"-inactive":"")}/>

                                <div className="InfoModal__progress-text-container">
                                    <p className="InfoModal__progress-title">{auth_lang[16][lang]}</p>
                                    <p className="InfoModal__progress-subtitle">{modal_lang[39][lang]}</p>
                                </div>
                            </div>
                        </div>

                        <div className="InfoModal__body">
                            {stage === 0 ? 
                            <>
                                <p className="InfoModal__header">{title}</p>

                                <NumberField
                                    label={labels[0]}
                                    value={values[0]}
                                    placeholder={placeholders[0]}
                                    countryCodes={["+7 RU", "+380 UA", "+1 US", "+4 EU"]}
                                    onCodeChange={_code => {setCode(_code);}}
                                    error={errors[0]}
                                    errorMessage="Введите корректный номер телефона"
                                    onChangeText={text => {
                                        onChangeValue(text, 0);
                                    }}
                                    style={{
                                        marginLeft: 32,
                                        marginTop: 22,
                                        marginBottom: 32
                                    }}
                                />

                                <div className="InfoModal__button-container">
                                    <GreyButton
                                        text={modal_lang[5][lang]}
                                        onClick={toggleClose}
                                        style={{
                                            width: 233,
                                            height: 32
                                        }}
                                    />

                                    <BlueButton
                                        text={modal_lang[6][lang]}
                                        onClick={() => {
                                            //console.log("ACTION: SEND CODE");
                                            onChangeValue(code+" "+values[0], 0);
                                            //setStage(1);

                                            onAcceptChanges();
                                            toggleClose();
                                        }}
                                        style={{
                                            width: 233,
                                            height: 32
                                        }}
                                    />
                                </div>
                            </>
                            :
                            <>
                                <p className="InfoModal__header">Подтвердите номер телефона</p>

                                <p className="InfoModal__subheader">На указанный номер телефона “{values[0]}” отправлен 6-ти значный код. Введите его в указанное поле ниже</p>

                                <TextField
                                    label="Код подтверждения"
                                    placeholder=""
                                    style={{
                                        marginTop: 23,
                                        marginLeft: 32,
                                        marginBottom: 12
                                    }}
                                    value={phoneCode}
                                    onChangeText={text => {
                                        if(text !== "") {
                                            let err = errors;
                                            err[1] = false;
                                            setErrors(err);
                                        }
                                        setPhoneCode(text);
                                    }}
                                    error={errors[1]}
                                    maxLength={6}
                                    errorMessage="Неверный код"
                                />

                                <div className="InfoModal__resend-container">
                                    <p className="InfoModal__resend-question">Не пришел код?</p>
                                    <p className="InfoModal__resend-action">Отправить снова</p>
                                </div>

                                <div className="InfoModal__button-container">
                                    <GreyButton
                                        text={modal_lang[12][lang]}
                                        onClick={() => setStage(0)}
                                        style={{
                                            width: 233,
                                            height: 32
                                        }}
                                    />

                                    <BlueButton
                                        text="Подтвердить"
                                        onClick={() => {
                                            if(phoneCode !== "000000") {
                                                let err = errors;
                                                err[1] = true;
                                                setErrors(err);
                                                setPhoneCode("");
                                                setTrigger(1-trigger);
                                            } else {
                                                onAcceptChanges();
                                                toggleClose();
                                            }
                                        }}
                                        style={{
                                            width: 233,
                                            height: 32
                                        }}
                                    />
                                </div>
                            </>
                            }
                        </div>
                    </>
                    : (type === "secure" ? 
                    <>
                        <p className="InfoModal__header">{title}</p>

                        <div className="InfoModal__input-container">
                            <SecureTextField
                                label={labels[0]}
                                value={values[0]}
                                onChangeText={text => {
                                    onChangeValue(text, 0);
                                }}
                                style={{
                                    marginBottom: 16,
                                    width: "calc(100% + 16px)"
                                }}
                                errorMessage="Неправильный пароль"
                                error={errors[0]}
                                inputWidth="calc(100% - 64px)"
                            />

                            <SecureTextField
                                label={labels[1]}
                                value={values[1]}
                                onChangeText={text => {
                                    onChangeValue(text, 1);
                                }}
                                style={{
                                    marginBottom: 16,
                                    width: "calc(100% + 16px)"
                                }}
                                errorMessage="Длина пароля должна составлять не менее 10 символов"
                                error={errors[1]}
                                inputWidth="calc(100% - 64px)"
                            />

                            <SecureTextField
                                label={labels[2]}
                                value={values[2]}
                                onChangeText={text => {
                                    onChangeValue(text, 2);
                                }}
                                style={{
                                    marginBottom: 16,
                                    width: "calc(100% + 16px)"
                                }}
                                errorMessage="Пароли не совпадают"
                                error={errors[2]}
                                inputWidth="calc(100% - 64px)"
                            />
                        </div>

                        <div className="InfoModal__button-container">
                            <GreyButton
                                text={modal_lang[5][lang]}
                                onClick={toggleClose}
                                style={{
                                    width: 233,
                                    height: 32
                                }}
                            />

                            <BlueButton
                                text={profile_lang[20][lang]}
                                onClick={() => {
                                    // 1: CHECK values[0] === PASSWORD ?

                                    // 2
                                    if(values[1].length < 10) {
                                        var _errors = errors;
                                        _errors[1] = true;
                                        setErrors(_errors);
                                        setTrigger(1-trigger);
                                    } else {
                                        var _errors = errors;
                                        _errors[1] = false;
                                        setErrors(_errors);
                                        setTrigger(1-trigger);
                                    }

                                    // 3
                                    if(values[1] !== values[2]) {
                                        var _errors = errors;
                                        _errors[2] = true;
                                        setErrors(_errors);
                                        setTrigger(1-trigger);
                                    } else {
                                        var _errors = errors;
                                        _errors[2] = false;
                                        setErrors(_errors);
                                        setTrigger(1-trigger);
                                    }

                                    if(!errors[0] && !errors[1] && !errors[2]) {
                                        acceptChanges();
                                    }
                                }}
                                style={{
                                    width: 233,
                                    height: 32
                                }}
                            />
                        </div>
                    </>
                    : <div/>)))}
                </div>
            </div>
        );
    }
}

export function CompanyModal({
    visible,
    values,
    onChangeValue,
    onRequestClose,
    onComplete,
    lang=1
}) {
    const [displayed, setDisplayed] = useState(false);
    const [stage, setStage] = useState(0);

    // errors
    const [errors, setErrors] = useState([false, false, false, false, false, false]);
    const [trigger, setTrigger] = useState(0);

    const [searchResults, setSearchResults] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [resultState, setResultState] = useState(false);

    const [role, setRole] = useState(0);
    const roles = [
        "seller",
        "buyer",
        "service"
    ];
    const onSliderPickerChange = (index) => {
        setRole(index);
    }

    const [productType, setProductType] = useState(0);
    const products = [
        "Сельхозпродукция",
        "Лесопродукция"
    ];
    const onProductChange = (index) => {
        setProductType(index);
    };

    const [site, setSite] = useState("");
    const [phone, setPhone] = useState("");

    useEffect(() => {
        if(visible) {
            toggleOpen();
        }
    }, [visible]);

    async function toggleOpen() {
        await timeout(10);
        setDisplayed(true);
    }

    useEffect(() => {
        if(searchValue.length > 8) {
            commitSearch();
        } else {
            onChangeValue("", 1);
            onChangeValue("", 2);
            setSearchResults([]);
        }
    }, [searchValue]);

    async function commitSearch() {
        //let response = await fetch(`https://api-fns.ru/api/egr?req=${searchValue}&key=31b019fdbb5ad1bf6c7de8bb5bd489ee65292d53`);
        //let response = await fetch(`https://api-fns.ru/api/search?q=${searchValue}&key=234dc3d6e476355dc7d62468a6709d1322592529`);
        //let data = await response.json();
        axios.post(
            'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party',
            { query: searchValue },
            { 
                headers: {
                    'content-type': 'application/json',
                    'authorization': 'Token 11f0f146144bfa7088a1dbc10e3186e809ce48f2'
                },
                withCredentials: false
            }
        ).then(response => {
            let data = response.data;
            //console.log(data.suggestions);
            if(data.suggestions.length > 0) {
                let results = [];
                //let items = data.items.splice(0, 10);
                //console.log(items);
                //console.log(data);
                data.suggestions.forEach((item, index) => {
                    try {
                        results.push({
                            name: item.value,
                            address: item.data.address.value,
                            director: item.data.management.name
                        });
                    } catch(err) {
                        results.push({
                            name: item.value,
                            address: item.data.address.value,
                            director: ""
                        });
                    }
                });

                setSearchResults(results);
            } else {
                onChangeValue("", 1);
                onChangeValue("", 2);
                setSearchResults([]);
            }
        })
    }

    const toggleClose = async () => {
        setDisplayed(false);
        await timeout(200);
        setStage(0);
        setErrors([false, false, false]);
        onRequestClose();
    }

    function verifyValues() {

        onChangeValue(roles[role], 3);
        if(productType !== -1) {
            onChangeValue(products[productType], 4);
        } else {
            return;
        }

        onChangeValue(site, 5);
        if (phone !== "") {
            onChangeValue(phone, 6);
        } else {
            return;
        }

        acceptChanges();
    }

    const acceptChanges = () => {
        

        onComplete();
        toggleClose();
    }

    async function closeSearchResults() {
        await timeout(200);
        setResultState(false);
    }

    function parseDirectorName(director) {
        if(director !== "") {
            let parts = director.split(" ");
            return parts[0] + " " + parts[1][0] + ". " + parts[2][0] + "."
        } else {
            return "";
        }
    }

    function tryContinue() {
        if(values[0] === "") {
            let er = errors;
            er[0] = true;
            setErrors(er);
        }

        if(values[1] === "") {
            let er = errors;
            er[1] = true;
            setErrors(er);
        }

        if(values[2] === "") {
            let er = errors;
            er[2] = true;
            setErrors(er);
        }

        setTrigger(1-trigger);
        if(!errors[0] && !errors[1] && !errors[2]) {
            setStage(1);
        }
    }

    if(visible) {
        return(
            <div className="InfoModal__root">
                <div 
                    className={"CompanyModal__background"+(displayed ? "" : "-hidden")}
                    onClick={toggleClose}
                />
                <div className={"CompanyModal__container"+(displayed ? "" : "-hidden")}>
                    <div className="InfoModal__progress-container">
                        <div className="InfoModal__progress-item">
                            <div className="InfoModal__progress-circle">
                                {stage===1?<img src={doneIcon} className="InfoModal__progress-icon" />:<div/>}
                            </div>

                            <div className="InfoModal__progress-text-container">
                                <p className="InfoModal__progress-title">{auth_lang[8][lang]}</p>
                                <p className="InfoModal__progress-subtitle">Добавление ИНН</p>
                            </div>
                        </div>

                        <div className={"InfoModal__progress-item"+(stage===0?"-inactive":"")}>
                            <div className={"InfoModal__progress-circle"+(stage===0?"-inactive":"")}/>

                            <div className="InfoModal__progress-text-container">
                                <p className="InfoModal__progress-title">{auth_lang[16][lang]}</p>
                                <p className="InfoModal__progress-subtitle">Дополнительная информация</p>
                            </div>
                        </div>
                    </div>

                    {stage === 0 ? 
                    <>
                        <p className="InfoModal__header">Укажите ИНН компании</p>
                        <p className="InfoModal__subheader">Наименование и адрес регистрации заполнятся автоматически</p>

                        <TextField
                            label="ИНН"
                            value={values[0]}
                            onChangeText={(text) => {
                                onChangeValue(text, 0);
                                setSearchValue(text);
                            }}
                            placeholder="Введите ИНН компании"
                            style={{
                                marginLeft: 32,
                                marginTop: 50
                            }}
                            onFocus={() => setResultState(true)}
                            onBlur={() => closeSearchResults()}
                            error={errors[0]}
                            errorMessage="Введите ИНН"
                            maxLength={12}
                        />

                        {resultState ? 
                        <div className="CompanyModal__search-container">
                            {searchResults.length === 0 ? 
                            <p style={{ marginTop: 8 }}>{values[0] === "" ? "Введите ИНН компании" : "Компаний с таким ИНН не найдено"}</p>
                            :
                            <div className="CompanyModal__search-results-wrapper">
                                {searchResults.map((result, index) => {
                                    return(
                                        <div 
                                            className="CompanyModal__search-item-container"
                                            onClick={() => {
                                                onChangeValue(result.name, 1);
                                                onChangeValue(result.address, 2);
                                                let er = errors;
                                                er[0] = false;
                                                er[1] = false;
                                                er[2] = false;
                                                setErrors(er);
                                            }}
                                            key={index}
                                        >
                                            <p className="CompanyModal__search-item-title">{result.name}</p>
                                            <p className="CompanyModal__search-item-director">Директор: {parseDirectorName(result.director)}</p>
                                        </div>
                                    );
                                })}
                            </div>
                            }
                        </div>
                        : <div/>}

                        <TextField
                            label="Наименовение компании"
                            value={values[1]}
                            placeholder=""
                            style={{
                                marginLeft: 32,
                                marginTop: 8
                            }}
                            disabled
                            error={errors[1]}
                            errorMessage="Наименование компании не указано"
                        />

                        <TextField
                            label="Адрес регистрации компании"
                            value={values[2]}
                            placeholder=""
                            style={{
                                marginLeft: 32,
                                marginTop: 8
                            }}
                            disabled
                            error={errors[2]}
                            errorMessage="Адрес регистрации компании не указан"
                        />

                        <div className="CompanyModal__action-container">
                            <GreyButton
                                text="Вернуться"
                                onClick={toggleClose}
                                style={{
                                    width: 232,
                                    height: 32
                                }}
                            />

                            <BlueButton
                                text={modal_lang[6][lang]}
                                onClick={() => {
                                    tryContinue();
                                }}
                                style={{
                                    width: 232,
                                    height: 32
                                }}
                            />
                        </div>
                    </>
                    :
                    <>
                        <p className="InfoModal__header">Основная информация</p>

                        <p className="CompanyModal__input-label" style={{ marginTop: "28px" }}>Роль</p>
                        <SliderPickerLarge
                            items={["Поставщик", "Покупатель"]} //, "Сервис"
                            labelStyles={[{width:'100%'}, {width:'100%'}]}
                            value={role}
                            onChange={onSliderPickerChange}
                            style={{ marginLeft: "32px" }}
                        />

                        {/*
                        <DropdownPicker
                            label="Тип продукции"
                            items={products}
                            value={productType}
                            onChange={onProductChange}
                            style={{ marginLeft: "32px", marginTop: "19px" }}
                        />
                        */}

                        <TextField
                            label="Сайт (опционально)"
                            style={{ marginLeft: '32px', marginTop: "19px" }}
                            value={site}
                            onChangeText={setSite}
                        />

                        <TextField
                            label="Номер телефона компании"
                            style={{ marginLeft: '32px' }}
                            value={phone}
                            onChangeText={setPhone}
                        />

                        <div className="CompanyModal__action-container">
                            <GreyButton
                                text="Вернуться"
                                onClick={() => setStage(0)}
                                style={{
                                    width: 232,
                                    height: 32
                                }}
                            />

                            <BlueButton
                                text={profile_lang[20][lang]}
                                onClick={() => {
                                    //console.log("OK");
                                    verifyValues();
                                }}
                                style={{
                                    width: 232,
                                    height: 32
                                }}
                            />
                        </div>
                    </>
                    }
                </div>
            </div>
        );
    }
}

/*const categoriesData = [
    {
        label: "Пиломатериалы",
        items: [
            "Ежиха",
            "Еж",
            "Епископ",
            "Емана",
            "Едрить"
        ]
    },
    {
        label: "Растительные масла",
        items: [
            "Ельцин"
        ]
    },
    {
        label: "Продукты перемола",
        items: [
            "Елабуга"
        ]
    }
];*/

export function ProductModal({
    visible,
    onRequestClose,
    onComplete,
    token,
    lang=1
}) {
    const [displayed, setDisplayed] = useState(false);
    const [stage, setStage] = useState(0);
    const [categoriesData, setCategoriesData] = useState([]); 
    const [indexesData, setIndexesData] = useState([]);

    const [indexesLang, setIndexesLang] = useState({});

    const [verifying, setVerifying] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        fetchCategoriesData();
        indexLanguage(function(response) {
            setIndexesLang(response);
        });
    }, [visible]);

    function fetchCategoriesData() {
        getCategoriesList((list) => {
            setCategoriesData(list);
            setCatResults(list);
            setTrigger(1-trigger);
        });
    }

    // data
    const [catSearchValue, setCatSearchValue] = useState("");
    const [catSearchState, setCatSearchState] = useState(false);
    const [catResults, setCatResults] = useState(categoriesData);
    const [selectedCategory, setSelectedCategory] = useState(null);

    
    // stage 2 data
    const [selectedStock, setSelectedStock] = useState(-1);
    const [stockSelectorVisible, setStockSelectorState] = useState(false);
    const [stockError, setStockError] = useState(false);
    const [stocksData, setStocksData] = useState([]);

    async function closeCatSearch() {
        await timeout(200);
        setCatSearchState(false);
    }

    const [itemSearchValue, setItemSearchValue] = useState("");
    const [itemSearchState, setItemSearchState] = useState(false);
    const [itemResults, setItemResults] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);

    async function closeItemSearch() {
        await timeout(200);
        setItemSearchState(false);
    }

    const [globalSearchValue, setGlobalSearchValue] = useState("");
    const [globalSearchState, setGlobalSearchState] = useState(false);
    const [globalResults, setGlobalResults] = useState([]);

    async function closeGlobalSearch() {
        await timeout(200);
        setGlobalSearchState(false);
    }

    function resetValues() {
        setStage(0);
        setIndexesData([]);
        setCatSearchValue("");
        setCatResults(categoriesData);
        setSelectedCategory(null);
        setSelectedStock(-1);
        setStocksData([]);
        setItemSearchValue("");
        setItemResults([]);
        setSelectedItem(null);
        setGlobalSearchValue("");
        setGlobalResults([]);
        setProductVolume("");
        setValues([""]);

        setCatSearchState(false);
        setItemSearchState(false);
        setGlobalSearchState(false);
    }

    function updateCatSearchValue(value) {
        setCatSearchValue(value);
        if(value === "") {
            setCatResults(categoriesData);
        } else {
            let cats = [];
            categoriesData.forEach((c) => {
                if(c.label.toLowerCase().includes(value.toLowerCase())) {
                    cats.push(c);
                }
            })

            setCatResults(cats);
        }
    }

    function updateItemSearchValue(value) {
        setItemSearchValue(value);
        if(value === "") {
            setItemResults([]);
        } else {
            let items = [];
            
            selectedCategory.items.forEach((c) => {
                if(c.name.toLowerCase().includes(value.toLowerCase())) {
                    items.push(c);
                }
            })

            setItemResults(items);
        }
    }

    function updateGlobalSearchValue(value) {
        setGlobalSearchValue(value);
        if(value === "") {
            setGlobalResults([]);
        } else {
            let gres = [];
            categoriesData.forEach((cat, index1) => {
                cat.items.forEach((item, index2) => {
                    if(item.lang !== undefined) {
                        if(item.lang[lang].toLowerCase().includes(value.toLowerCase())) {
                            gres.push({
                                catLang: cat.lang !== undefined ? cat.lang[lang] : cat.label,
                                catLabel: cat.label,
                                itemLabel: item.name,
                                itemLang: item.lang[lang]
                            });
                        }
                    } else {
                        if(item.name.toLowerCase().includes(value.toLowerCase())) {
                            gres.push({
                                catLabel: cat.label,
                                catLang: cat.lang !== undefined ? cat.lang[lang] : cat.label,
                                itemLabel: item.name,
                                itemLang: item.name
                            });
                        }
                    }
                })
            })

            setGlobalResults(gres);
        }
    }

    async function continueToStage1() {
        if(selectedCategory !== null && selectedItem !== "") {
            getIndexesForItem(selectedItem, (indexes) => {
                setIndexesData(indexes);
                let _values = [];
                let _errors = [];

                for(const ind of indexes) {
                    if(ind.format !== "picker") {
                        _values.push("");
                    } else {
                        _values.push(0);
                    }
                    _errors.push(false);
                }

                setValues(_values);
                setErrors(_errors);
                setStage(1);
                getStocks();
            })
        } else {
            return;
        }
    }

    // stage 1
    const [productVolume, setProductVolume] = useState("");
    const [values, setValues] = useState(["", "", "", ""]);

    const [volError, setVolError] = useState(false);
    const [errors, setErrors] = useState([false, false, false, false]);

    const updateValue = (index, value) => {
        let val_old = values;
        val_old[index] = value;
        setValues(val_old);
        setTrigger(1-trigger);
        
        let err_old = errors;
        err_old[index] = false;
        setErrors(err_old);
        
        setTrigger(1-trigger);
    }

    function parseFilter(filter) {
        switch(filter) {
            case 'from':
                return modal_lang[34][lang];
            case 'to':
                return modal_lang[33][lang];
            default:
                return "";
        }
    }

    // errors
    const [trigger, setTrigger] = useState(0);

    useEffect(() => {
        if(visible) {
            toggleOpen();
        }
    }, [visible]);

    async function toggleOpen() {
        await timeout(10);
        setDisplayed(true);
    }

    const toggleClose = async () => {
        setDisplayed(false);
        resetValues();
        await timeout(200);
        setStage(0);
        //setErrors([false, false, false]);
        onRequestClose();
    }

    const acceptChanges = () => {
        //onComplete();
        let indexes = [];
        indexesData.forEach((item, index) => {
            indexes.push(Object.assign(item, { value: values[index] }));
        });

        //console.log(indexes);

        companyService.getCompaniesList(token, function(companies) {
            if(companies.companies !== undefined) {
                let parent_company = companies.companies[0].pk;

                productionService.addProduction(
                    {
                        "category": (selectedCategory.label !== undefined ? selectedCategory.label : selectedCategory),
                        "name": selectedItem,
                        "volume": productVolume,
                        "stock": stocksData[selectedStock].pk,
                        "params": {
                            indexes
                        },
                        "parent_company": parent_company
                    },
                    token,
                    function(response) {
                        if(response.production !== null) {
                            toggleClose();
                            onComplete();
                        }
                    }
                );
            }
        });
    }

    function getStocks() {
        stockService.getStockList(token, function(response) {
            if(response.length > 0) {
                setStocksData(response);
                setTrigger(1-trigger);
            }
        });
    }

    function tryCommitValues() {
        let verr = false;
        if(productVolume === "") {
            setVolError(true);
            verr = true;
        }

        var index = 0;
        for(const value of values) {
            if(value === "") {
                let err_old = errors;
                err_old[index] = true;
                setErrors(err_old);
                setTrigger(1-trigger);
            }

            index += 1;
        }

        if(verr === true) {return;}
        for(const error of errors) {
            if(error) {return;}
        }

        setStage(2);
    }

    function parseStockAddress(stock) {
        var addr = "";
        addr += (stock.country + ", ");
        if(stock.region !== "") {
            addr += (stock.region + ", ");
        }
        if(stock.district !== "") {
            addr += (stock.district + ", ");
        }
        addr += ("г " + stock.town + ", ");
        addr += (stock.street + ", ");
        addr += ("д " + stock.house);
        if(stock.building !== "") {
            addr += (", стр " + stock.building);
        }

        if(stock.corpus !== "") {
            addr += (", к " + stock.corpus);
        }

        if(stock.room !== "") {
            addr += (", кв " + stock.room);

        }

        return addr;
    }

    const catRef = useOutsideClick(() => {setCatSearchState(false)});
    const itemRef = useOutsideClick(() => {setItemSearchState(false)});
    const globalRef = useOutsideClick(() => {setGlobalSearchState(false)});

    const [itemLang, setItemLang] = useState("");

    if(visible) {
        return(
            <div className="InfoModal__root">
                <div 
                    className={"ProductModal__background"+(displayed ? "" : "-hidden")}
                    onClick={toggleClose}
                />

                <div className={"ProductModal__container"+(displayed ? "" : "-hidden")}>
                    <div className="ProductModal__progress-container">
                        <div
                            className="ProductModal__progress-indicator-active"
                            style={{
                                marginLeft: 17,
                            }}
                        >
                            {stage>0?<img src={doneIcon} className="InfoModal__progress-icon" />:<div/>}
                        </div>
                        {stage === 0 && <p className="ProductModal__progress-title" style={{ width: 216, minWidth: 216 }}>{modal_lang[0][lang]}</p>}
                        
                        <div 
                            className="ProductModal__progress-stick"
                            style={stage>0?{backgroundColor:"#0F62FE"}:{}}
                        />

                        <div
                            className={"ProductModal__progress-indicator"+(stage>0?"-active":"")}
                        >
                            {stage>1?<img src={doneIcon} className="InfoModal__progress-icon" />:<div/>}
                        </div>
                        {stage === 1 && <p className="ProductModal__progress-title" style={{ width: 158, minWidth: 158 }}>{modal_lang[7][lang]}</p>}

                        <div 
                            className="ProductModal__progress-stick"
                            style={stage>1?{backgroundColor:"#0F62FE"}:{}}
                        />

                        <div
                            className={"ProductModal__progress-indicator"+(stage>1?"-active":"")}
                        >
                            {stage>2?<img src={doneIcon} className="InfoModal__progress-icon" />:<div/>}
                        </div>
                        {stage === 2 && <p className="ProductModal__progress-title" style={{ width: 122, minWidth: 122 }}>{modal_lang[35][lang]}</p>}

                        <div 
                            className="ProductModal__progress-stick"
                            style={stage>2?{backgroundColor:"#0F62FE"}:{}}
                        />

                        <div
                            className={"ProductModal__progress-indicator"+(stage>2?"-active":"")}
                            style={stage !== 3 ? {marginRight: 17} : {}}
                        />
                        {stage === 3 && <p className="ProductModal__progress-title" style={{ width: 112, minWidth: 112, marginRight: 17 }}>{modal_lang[39][lang]}</p>}
                    </div>

                    <div className="ProductModal__body">
                        {stage === 0 ?
                            <>
                                <p className="ProductModal__body-title">{modal_lang[1][lang]}</p>
                                <div className="ProductModal__pickers-container-1">
                                    <div className="ProductModal__picker-container" onClick={() => setCatSearchState(!catSearchState)} ref={catRef}>
                                        <input
                                            value={catSearchValue}
                                            onChange={(event) => updateCatSearchValue(event.target.value)}
                                            className="ProductModal__picker-input"
                                            placeholder={modal_lang[2][lang]}
                                        />

                                        <IoChevronDown
                                            color="#0F62FE"
                                            size={18}
                                            style={{ marginRight: 10, cursor: "pointer" }}
                                        />

                                        {catSearchState &&
                                        <div className="ProductModal__picker-result-container">
                                            <div className="ProductModal__picker-result-wrapper">
                                                {catResults.map((res, index) => {
                                                    return(
                                                        <div 
                                                            className="ProductModal__picker-result-item" 
                                                            key={index}
                                                            onClick={() => {
                                                                setCatSearchValue(res.lang !== undefined ? res.lang[lang] : res.label);
                                                                setSelectedCategory(res);
                                                                setSelectedItem("");
                                                                setItemSearchValue("");
                                                                setItemResults(res.items);
                                                                setGlobalSearchValue((res.lang !== undefined ? res.lang[lang] : res.label)+"/ ");
                                                                setCatSearchState(false);
                                                                console.log(res);
                                                            }}
                                                        >
                                                            <p className="ProductModal__picker-result-item-label">{res.lang !== undefined ? res.lang[lang] : res.label}</p>
                                                            {index !== catResults.length-1 && <div className="ProductModal__picker-result-item-divider"/>}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        }
                                    </div>

                                    <div className="ProductModal__picker-container" onClick={() => {if(selectedCategory !== null) {setItemSearchState(!itemSearchState)}}} ref={itemRef}>
                                        <input
                                            value={itemSearchValue}
                                            onChange={(event) => updateItemSearchValue(event.target.value)}
                                            className="ProductModal__picker-input"
                                            placeholder={modal_lang[3][lang]}
                                            disabled={selectedCategory === null}
                                        />

                                        <IoChevronDown
                                            color="#0F62FE"
                                            size={18}
                                            style={{ marginRight: 10, cursor: "pointer" }}
                                        />

                                        {itemSearchState &&
                                        <div className="ProductModal__picker-result-container">
                                            <div className="ProductModal__picker-result-wrapper">
                                                {itemResults.map((res, index) => {
                                                    return(
                                                        <div 
                                                            className="ProductModal__picker-result-item" 
                                                            key={index}
                                                            onClick={() => {
                                                                setItemSearchValue(res.lang !== undefined ? res.lang[lang] : res.name);
                                                                setItemLang(res.lang !== undefined ? res.lang[lang] : res.name);
                                                                setSelectedItem(res.name);
                                                                setItemSearchState(false);
                                                                setGlobalSearchValue((selectedCategory.lang !== undefined ? selectedCategory.lang[lang] : selectedCategory.label) + "/ " + (res.lang !== undefined ? res.lang[lang] : res.name));
                                                            }}
                                                        >
                                                            <p className="ProductModal__picker-result-item-label">{res.lang !== undefined ? res.lang[lang] : res.name}</p>
                                                            {index !== itemResults.length-1 && <div className="ProductModal__picker-result-item-divider"/>}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>

                                <div className="ProductModal__global-search-container" style={{marginBottom:"12px"}} onClick={() => setGlobalSearchState(!globalSearchState)} ref={globalRef}>
                                    <input
                                        value={globalSearchValue}
                                        placeholder={modal_lang[4][lang]}
                                        className="ProductModal__global-search-input"
                                        onChange={(event) => updateGlobalSearchValue(event.target.value)}
                                    />

                                    {globalSearchState && 
                                    <div className="ProductModal__global-result-container">
                                        <div className="ProductModal__global-result-wrapper">
                                            {globalResults.map((res, index) => {
                                                return(
                                                    <div 
                                                        className="ProductModal__global-result-item"
                                                        onClick={() => {
                                                            setCatSearchValue(res.catLang);
                                                            setItemSearchValue(res.itemLang);
                                                            setItemLang(res.itemLang);
                                                            setSelectedItem(res.itemLabel);
                                                            categoriesData.forEach((cat, index) => {
                                                                if(cat.label === res.catLabel) {
                                                                    setSelectedCategory(cat);
                                                                    setItemResults(cat.items);
                                                                    setCatResults([]);
                                                                }
                                                            });
                                                            setGlobalSearchValue(res.catLang + "/ " + res.itemLang);
                                                        }}
                                                        key={index}
                                                    >
                                                        <p className="ProductModal__global-result-item-label">{res.catLang}/ {res.itemLang}</p>
                                                        {index !== globalResults.length-1 && <div className="ProductModal__global-result-item-divider"/>}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    }
                                </div>

                                <p 
                                    className="LogInModal__switch-signup" 
                                    style={{
                                        alignSelf: "flex-start",
                                        marginLeft: "32px",
                                        fontSize:"14px",
                                        color:"#161616",
                                        marginBottom:"12px",
                                        lineHeight:"18px"
                                    }} 
                                    onClick={() => {
                                        toggleClose();
                                        navigate("/account/feedback");
                                    }}
                                >
                                    Не нашли нужную продукцию? <span className="LogInModal__switch-signup-link">Напишите в поддержку</span>, и мы добавим её на платформу
                                </p>

                                <div className="ProductModal__actions-container">
                                    <GreyButton
                                        text={modal_lang[5][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        onClick={toggleClose}
                                    />

                                    <BlueButton
                                        text={modal_lang[6][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        disabled={!(selectedCategory !== null && selectedItem !== "")}
                                        onClick={() => {
                                            if(verifying) {
                                                setStage(3);
                                            } else {
                                                continueToStage1();
                                            }
                                        }}
                                    />
                                </div>
                            </>    
                            :(stage === 1 ?
                            <>
                                <p className="ProductModal__body-title">{modal_lang[8][lang]}</p>
                                <TextField
                                    label={modal_lang[9][lang]}
                                    placeholder={modal_lang[10][lang]}
                                    style={{
                                        marginLeft: 32,
                                        marginBottom: 12,
                                    }}
                                    inputWidth={"720px"}
                                    value={productVolume}
                                    onChangeText={text => {
                                        setProductVolume(text);
                                        if(text !== "") {
                                            setVolError(false);
                                        }
                                    }}
                                    error={volError}
                                    errorMessage={modal_lang[32][lang]}
                                    indicatorShown={false}
                                />

                                <div className="ProductModal__inputs-wrapper">
                                    {indexesData.map((data, index) => {
                                        if(data.format !== "picker") {
                                            return(
                                                <TextField
                                                    inputWidth={"344px"}
                                                    style={index%2 !== 0 ? { marginLeft: 16 } : {}}
                                                    key={index}
    
                                                    label={indexesLang.lang[data.name][lang] + parseFilter(data.filter) + ", " + data.format}
                                                    placeholder={modal_lang[11][lang] + indexesLang.lang[data.name][lang].toLowerCase()}
    
                                                    value={values[index]}
                                                    onChangeText={text => updateValue(index, text)}
                                                    error={errors[index]}
                                                    errorMessage={modal_lang[32][lang]}
                                                    indicatorShown={false}
                                                />
                                            );
                                        } else {
                                            return(
                                                <div className="ProductModal__picker-input" key={index} style={index%2 === 0 ? { marginLeft: 0 } : {}}>
                                                    <p className="ProductModal__picker-label">{indexesLang.lang[data.name][lang]}</p>
                                                    <SliderPickerLarge
                                                        items={
                                                            indexesLang.lang[data.base_values[0]] !== undefined ? 
                                                            [indexesLang.lang[data.base_values[0]][lang], indexesLang.lang[data.base_values[1]][lang]]
                                                            :
                                                            data.base_values.slice(0, 2)
                                                        } //{data.base_values.slice(0, 2)} // ONLY 2 CASES SUPPORTED!
                                                        style={{width:"360px"}}
                                                        labelStyles={[
                                                            {fontWeight:"400"},
                                                            {fontWeight:"400"}
                                                        ]}
                                                        value={values[index]}
                                                        onChange={value => updateValue(index, value)}
                                                    />
                                                </div>
                                            );
                                        }
                                    })}
                                </div>

                                <div className="ProductModal__actions-container" style={{ marginTop: 16 }}>
                                    <GreyButton
                                        text={modal_lang[12][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        onClick={() => {setStage(0)}}
                                    />

                                    <BlueButton
                                        text={modal_lang[6][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        disabled={!(selectedCategory !== null && selectedItem !== "")}
                                        onClick={() => {
                                            if(verifying) {
                                                setStage(3);
                                            } else {
                                                tryCommitValues();
                                            }
                                        }}
                                    />
                                </div>
                            </>
                                
                            :(stage === 2 ?
                            <>
                                <p className="ProductModal__body-title">{modal_lang[36][lang]}</p>
                                
                                <div 
                                    className="ProductModal__stock-selector-container"
                                    onClick={() => setStockSelectorState(!stockSelectorVisible)}
                                    style={stockError ? {
                                        borderBottomColor: "#DA1E28",
                                        borderBottomWidth: 1,
                                        backgroundColor: "#FFF1F1"
                                    } : {
                                        borderBottomColor: "#fff",
                                        borderBottomWidth: 1,
                                    }}
                                >
                                    {selectedStock === -1 ? 
                                    <p className="ProductModal__stock-selector-text">{modal_lang[37][lang]}</p>
                                    :
                                    <p className="ProductModal__stock-selector-text">{stocksData[selectedStock].name}, {parseStockAddress(stocksData[selectedStock]).slice(0, 30)}..</p>
                                    }

                                    <IoChevronDown
                                        color="#0F62FE"
                                        size={16}
                                        className={"ProductModal__stock-selector-chevron" + (stockSelectorVisible ? "-open" : "")}
                                    />

                                    {stockSelectorVisible &&
                                    <div className="ProductModal__stock-list-container">
                                        {stocksData.map((stock, index) => {
                                            return(
                                                <div 
                                                    className="ProductModal__stock-item-container"
                                                    onClick={() => setSelectedStock(index)}
                                                    key={index}
                                                >
                                                    <p className="ProductModal__stock-item-label">{stock.name}</p>
                                                    <p className="ProductModal__stock-item-address">{parseStockAddress(stock)}</p>
                                                    <div className="ProductModal__stock-item-divider"/>
                                                </div>
                                            );
                                        })}
                                        <div className="ProductModal__stock-button-container">
                                            <p className="ProductModal__stock-button-label">{modal_lang[38][lang]}</p>
                                        </div>
                                    </div>
                                    }
                                </div>

                                <div className="ProductModal__actions-container" style={{ marginTop: 16 }}>
                                    <GreyButton
                                        text={modal_lang[12][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        onClick={() => {setStage(1)}}
                                    />

                                    <BlueButton
                                        text={modal_lang[6][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        disabled={selectedStock === -1}
                                        onClick={() => {
                                            setStage(3);
                                            setVerifying(true);
                                        }}
                                    />
                                </div>
                            </>
                            :
                            <>
                                <p className="ProductModal__body-title">{modal_lang[40][lang]}</p>

                                <div className="ProductModal__result-container">
                                    <p className="ProductModal__result-subheader">{modal_lang[41][lang]}</p>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[2][lang]}</p>
                                        <p className="ProductModal__result-item-value">{(selectedCategory.lang !== undefined ? selectedCategory.lang[lang] : selectedCategory.label)}</p>
                                    </div>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[3][lang]}</p>
                                        <p className="ProductModal__result-item-value">{itemLang}</p>
                                    </div>

                                    <img 
                                        src={editIcon} 
                                        className="ProductModal__result-item-edit"
                                        onClick={() => setStage(0)}
                                    />
                                    <div className="ProductModal__result-divider"/>
                                </div>

                                <div className="ProductModal__result-container">
                                    <p className="ProductModal__result-subheader">{modal_lang[7][lang]}</p>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[9][lang]}</p>
                                        <p className="ProductModal__result-item-value">{productVolume}</p>
                                    </div>

                                    {indexesData.map((data, index) => {
                                        if(data.format !== "picker") {
                                            return(
                                                <div className="ProductModal__result-item-container" key={index}>
                                                    <p className="ProductModal__result-item-label">{indexesLang.lang[data.name][lang]}</p>
                                                    <p className="ProductModal__result-item-value">{values[index]} {data.format}</p>
                                                </div>
                                            );
                                        } else {
                                            return(
                                                <div className="ProductModal__result-item-container" key={index}>
                                                    <p className="ProductModal__result-item-label">{indexesLang.lang[data.name][lang]}</p>
                                                    <p className="ProductModal__result-item-value">{indexesLang.lang[data.base_values[values[index]]][lang]}</p>
                                                </div>
                                            );
                                        }
                                    })}

                                    <img 
                                        src={editIcon} 
                                        className="ProductModal__result-item-edit"
                                        onClick={() => setStage(1)}
                                    />
                                    <div className="ProductModal__result-divider"/>
                                </div>

                                <div className="ProductModal__result-container">
                                    <p className="ProductModal__result-subheader">Адрес склада</p>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{stocksData[selectedStock].name}</p>
                                        <p className="ProductModal__result-item-value">{parseStockAddress(stocksData[selectedStock])}</p>
                                    </div>

                                    <img 
                                        src={editIcon} 
                                        className="ProductModal__result-item-edit"
                                        onClick={() => setStage(2)}
                                    />
                                </div>

                                <div className="ProductModal__actions-container" style={{ marginTop: 24 }}>
                                    <GreyButton
                                        text={modal_lang[12][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        onClick={() => {
                                            setStage(3);
                                            setVerifying(false);
                                        }}
                                    />

                                    <BlueButton
                                        text={modal_lang[42][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        onClick={() => {
                                            //console.log("SAVE DATA");
                                            acceptChanges();
                                        }}
                                    />
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export function DeleteModal({
    label,
    content,
    visible,
    onClose,
    onAccept,
    onCancel,
    lang=1,
    cancelLabel=modal_lang[5][lang],
    commitLabel="Удалить"
}) {
    const [displayed, setDisplayed] = useState(false);
    useEffect(() => {
        if(visible) {
            toggleOpen();
        }
    }, [visible]);

    async function toggleOpen() {
        await timeout(10);
        setDisplayed(true);
    }

    const toggleClose = async () => {
        setDisplayed(false);
        await timeout(200);
        onClose();
    }

    const toggleAccept = async () => {
        onAccept();
        setDisplayed(false);
        await timeout(200);
        onClose();
    }

    if(visible) {
        return(
            <div className="InfoModal__root">
                <div 
                    className={"ProductModal__background"+(displayed ? "" : "-hidden")}
                    onClick={toggleClose}
                />

                <div className={"DeleteModal__container"+(displayed ? "" : "-hidden")}>
                    <p className="InfoModal__header">{label}</p>
                    <p className="InfoModal__subheader">{content}</p>

                    <div className="DeleteModal__actions-container">
                        <GreyButton
                            text={cancelLabel}
                            style={{
                                width: 232,
                                height: 32
                            }}
                            onClick={() => {
                                onCancel();
                                toggleClose();
                            }}
                        />

                        <YellowButton
                            text={commitLabel}
                            style={{
                                width: 232,
                                height: 32
                            }}
                            onClick={toggleAccept}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export function StockModal({
    visible,
    onClose,
    onAccept,
    token,
    lang=1
}) {
    const [displayed,setDisplayed] = useState(false);
    useEffect(() => {
        if(visible) {
            toggleOpen();
        }
    }, [visible]);

    useEffect(() => {
        if(lang === 0) {
            setInputMode(0);
        } else {
            setInputMode(1);
        }
    }, [lang]);

    async function toggleOpen() {
        await timeout(10);
        setDisplayed(true);
    }

    const toggleClose = async () => {
        setDisplayed(false);
        await timeout(200);
        setDefaultValues();
        onClose();
    }

    const handleAccept = () => {
        if(!checkValues()) {return;}
        stockService.addStock({
            name: name,
            index: postalIndex,
            country: country,
            region: region,
            district: district,
            town: town,
            street: street,
            house: house,
            building: building,
            corpus: corpus,
            room: room
        }, token, function(response) {
            //console.log("API RESPONSE:");
            //console.log(response);
            onAccept();
            toggleClose();
        });
    }

    const [inputMode, setInputMode] = useState(0); // 0 - short, 1 - full

    // data : global
    const [name, setName] = useState("");
    const [errors, setErrors] = useState([
        false,
        false,
        false,
        false,
        false,
        false
    ]);

    // data : mode 0
    const [fullAddress, setFullAddress] = useState("");

    const [searchState, setSearchState] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const searchRef = useOutsideClick(() => setSearchState(false));
    useEffect(() => {
        if(fullAddress !== "" && fullAddress.length > 5) {
            axios.post(
                'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
                { query: fullAddress },
                { 
                    headers: {
                        'content-type': 'application/json',
                        'authorization': 'Token 11f0f146144bfa7088a1dbc10e3186e809ce48f2'
                    },
                    withCredentials: false
                }
            ).then(response => {
                let data = response.data;
                //console.log(data.suggestions);

                setSearchResults(data.suggestions);
            });
        }
    }, [fullAddress]);

    // data : mode 1
    const [postalIndex, setPostalIndex] = useState("");
    const [country, setCountry] = useState("");
    const [region, setRegion] = useState("");
    const [district, setDistrict] = useState("");
    const [town, setTown] = useState("");
    const [street, setStreet] = useState("");
    const [house, setHouse] = useState("");
    const [building, setBuilding] = useState("");
    const [corpus, setCorpus] = useState("");
    const [room, setRoom] = useState("");

    function setDefaultValues() {
        if(lang === 0) {setInputMode(0);}
        setName("");
        setFullAddress("");
        setPostalIndex("");
        setCountry("");
        setRegion("");
        setDistrict("");
        setTown("");
        setStreet("");
        setHouse("");
        setBuilding("");
        setCorpus("");
        setRoom("");
        setErrors([
            false,
            false,
            false,
            false,
            false,
            false
        ]);
    }

    function checkValues() {
        if(name === "") {showError(0);}
        if(country === "") {showError(1); showError(2);}
        if(town === "") {showError(1); showError(3);}
        if(street === "") {showError(1); showError(4);}
        if(house === "") {showError(1); showError(5);}

        for(const error of errors) {
            if(error) {return false;}
        }

        return true;
    }

    function showError(index) {
        let er = errors;
        er[index] = true;
        setErrors(er);
        setTrigger(1-trigger);
    }

    function hideError(index) {
        let er = errors;
        er[index] = false;
        setErrors(er);
        setTrigger(1-trigger);
    }

    const [trigger, setTrigger] = useState(1);

    if(visible) {
        return(
            <div className="InfoModal__root">
                <div 
                    className={"ProductModal__background"+(displayed ? "" : "-hidden")}
                    onClick={toggleClose}
                />

                <div className={"StockModal__container"+(displayed ? "" : "-hidden")}>
                    <p className="InfoModal__header">{profile_lang[63][lang]}</p>

                    {inputMode === 0 ? 
                    <div className="StockModal__body">
                        <TextField
                            label={profile_lang[45][lang]}
                            placeholder={profile_lang[55][lang]}
                            value={name}
                            onChangeText={text => {
                                if(text !== "" && errors[0]) {
                                    hideError(0);
                                    setTrigger(1-trigger);
                                }
                                setName(text);
                            }}
                            errorMessage={modal_lang[32][lang]}
                            error={errors[0]}
                            inputWidth={462}
                            style={{
                                width: "512px"
                            }}
                        />

                        <div 
                            className="StockModal__full-address-wrapper"
                            onFocus={() => setSearchState(true)}
                            ref={searchRef}
                        >
                            <TextField
                                label="Адрес"
                                placeholder="Введите адрес склада"
                                value={fullAddress}
                                onChangeText={text => {
                                    if(text !== "" && errors[1]) {
                                        hideError(1);
                                        setTrigger(1-trigger);
                                    }
                                    setFullAddress(text);
                                }}
                                maxLength={100}
                                inputWidth={462}
                                style={{
                                    width: "512px"
                                }}
                                errorMessage="Некорректный адрес. Попробуйте ввести вручную"
                                error={errors[1]}
                            />

                            {searchState &&
                            <div className="StockModal__full-address-search-container">
                                {searchResults.slice(0, 5).map((result, index) => {
                                    if(result.data.house !== null) {
                                        return(
                                            <div 
                                                className="StockModal__search-result-container" 
                                                key={index}
                                                onClick={() => {
                                                    //console.log(result);
                                                    setFullAddress(result.value);
                                                    setPostalIndex(result.data.postal_code);
                                                    setCountry(result.data.country);
                                                    if(result.data.region_type === "г") {
                                                        setTown(result.data.region);
                                                    } else if(result.data.city !== null) {
                                                        setTown(result.data.city);
                                                        setRegion(result.data.region + " " + result.data.region_type);
                                                    } else {
                                                        setTown(result.data.settlement);
                                                        setRegion(result.data.region + " " + result.data.region_type);
                                                    }
                                                    if(result.data.area !== null) {
                                                        setDistrict(result.data.area + " " + result.data.area_type);
                                                    }
                                                    setStreet(result.data.street + " " + result.data.street_type);
                                                    setHouse(result.data.house);
                                                    if(result.data.flat !== null) {
                                                        setRoom(result.data.flat);
                                                    }
                                                    if(result.data.block_type === "к") {
                                                        setCorpus(result.data.block);
                                                    } else if(result.data.block_type === "стр") {
                                                        setBuilding(result.data.block);
                                                    }
                                                    setSearchState(false);
                                                }}
                                            >
                                                <p className="StockModal__search-result-label">{result.value}</p>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                            }
                        </div>

                        <p 
                            className="StockModal__mode-switch"
                            onClick={() => setInputMode(1)}
                        >Ввести вручную</p>

                        <div className="StockModal__actions-container">
                            <GreyButton
                                text={modal_lang[5][lang]}
                                style={{
                                    width: 232,
                                    height: 32
                                }}
                                onClick={toggleClose}
                            />

                            <BlueButton
                                text={profile_lang[20][lang]}
                                style={{
                                    width: 232,
                                    height: 32
                                }}
                                onClick={handleAccept}
                            />
                        </div>
                    </div>
                    :
                    <div className="StockModal__body">
                        <TextField
                            label={profile_lang[45][lang]}
                            placeholder={profile_lang[55][lang]}
                            value={name}
                            onChangeText={setName}
                            errorMessage={modal_lang[32][lang]}
                            error={errors[1]}
                            inputWidth={462}
                            style={{
                                width: "512px"
                            }}
                        />
                        
                        <TextField
                            label={profile_lang[46][lang]}
                            placeholder={profile_lang[56][lang]}
                            value={postalIndex}
                            onChangeText={setPostalIndex}
                            style={{
                                marginTop: "4px"
                            }}
                        />
                        
                        <TextField
                            label={profile_lang[47][lang]}
                            placeholder={profile_lang[57][lang]}
                            value={country}
                            onChangeText={setCountry}
                            errorMessage={modal_lang[32][lang]}
                            error={errors[2]}
                            inputWidth={462}
                            style={{
                                width: "512px",
                                marginTop: "4px"
                            }}
                        />
                        
                        <TextField
                            label={market_lang[15][lang]}
                            placeholder={profile_lang[58][lang]}
                            value={region}
                            onChangeText={setRegion}
                            style={{
                                marginTop: "4px"
                            }}
                        />
                        
                        <TextField
                            label={profile_lang[48][lang]}
                            placeholder={profile_lang[59][lang]}
                            value={district}
                            onChangeText={setDistrict}
                            style={{
                                marginTop: "4px"
                            }}
                        />
                        
                        <TextField
                            label={profile_lang[49][lang]}
                            placeholder={profile_lang[60][lang]}
                            value={town}
                            onChangeText={setTown}
                            errorMessage={modal_lang[32][lang]}
                            error={errors[3]}
                            inputWidth={462}
                            style={{
                                width: "512px",
                                marginTop: "4px"
                            }}
                        />
                        
                        <TextField
                            label={profile_lang[50][lang]}
                            placeholder={profile_lang[61][lang]}
                            value={street}
                            onChangeText={setStreet}
                            errorMessage={modal_lang[32][lang]}
                            error={errors[4]}
                            inputWidth={462}
                            style={{
                                width: "512px",
                                marginTop: "4px"
                            }}
                        />
                        
                        <div className="StockModal__details-container">
                            <TextField
                                label={profile_lang[51][lang]}
                                placeholder={profile_lang[62][lang]}
                                inputWidth={90}
                                style={{
                                    width: "106px",
                                }}
                                value={house}
                                onChangeText={setHouse}
                                indicatorShown={false}
                                errorMessage="Заполните поле"
                                error={errors[5]}
                            />

                            <TextField
                                label={profile_lang[52][lang]}
                                placeholder={profile_lang[62][lang]}
                                inputWidth={90}
                                style={{
                                    width: "106px",
                                }}
                                value={building}
                                onChangeText={setBuilding}
                            />

                            <TextField
                                label={profile_lang[53][lang]}
                                placeholder={profile_lang[62][lang]}
                                inputWidth={90}
                                style={{
                                    width: "106px",
                                }}
                                value={corpus}
                                onChangeText={setCorpus}
                            />

                            <TextField
                                label={profile_lang[54][lang]}
                                placeholder={profile_lang[62][lang]}
                                inputWidth={90}
                                style={{
                                    width: "106px",
                                }}
                                value={room}
                                onChangeText={setRoom}
                            />
                        </div>

                        <div className="StockModal__actions-container">
                            <GreyButton
                                text={modal_lang[5][lang]}
                                style={{
                                    width: 232,
                                    height: 32
                                }}
                                onClick={toggleClose}
                            />

                            <BlueButton
                                text={profile_lang[20][lang]}
                                style={{
                                    width: 232,
                                    height: 32
                                }}
                                onClick={handleAccept}
                            />
                        </div>
                    </div>
                    }
                </div>
            </div>
        );
    }
}


export function ProductRequestModal({
    visible,
    onRequestClose,
    onComplete,
    token,
    lang=1
}) {
    const [displayed, setDisplayed] = useState(false);
    const [stage, setStage] = useState(0);
    const [categoriesData, setCategoriesData] = useState([]); 
    const [indexesData, setIndexesData] = useState([]);

    const [indexLang, setIndexLang] = useState({});

    const [verifying, setVerifying] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        fetchCategoriesData();

        let periods = getPeriodItems();
        setPeriodItems(periods);
        indexLanguage(function(response) {
            setIndexLang(response);
        });
    }, []);

    function fetchCategoriesData() {
        getCategoriesList((list) => {
            setCategoriesData(list);
            setCatResults(list);
            setTrigger(1-trigger);
        });
    }

    // data
    const [catSearchValue, setCatSearchValue] = useState("");
    const [catSearchState, setCatSearchState] = useState(false);
    const [catResults, setCatResults] = useState(categoriesData);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [priceMode, setPriceMode] = useState(1); // 0 - своя , 1 - договор
    const [price, setPrice] = useState("");
    const [priceError, setPriceError] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(0);
    const currencies = [
        "₽",
        "$",
        "€",
        "¥"
    ];

    useEffect(() => {
        if(price !== "" && priceError) {
            setPriceError(false);
        }
    }, [price]);

    function resetValues() {
        setStage(0);
        setIndexesData([]);
        setCatSearchValue("");
        setCatResults(categoriesData);
        setSelectedCategory(null);
        setBasis(-1);
        setSelectedCountry("");
        setCountrySearchValue("");
        setStation("");
        setTransportType1(-1);
        setTransportType2(-1);
        setProductPackage(-1);
        setPeriodFrom(-1);
        setPeriodTo(-1);
        setItemSearchValue("");
        setItemResults([]);
        setSelectedItem(null);
        setGlobalSearchValue("");
        setGlobalResults([]);
        setProductVolume("");
        setValues([""]);
        setPriceMode(1);
        setPrice("");
        setSelectedCurrency(0);
        setSelectedCountryLang("");
    }
    
    // stage 2 data
    const [basis, setBasis] = useState(-1);
    const [basisItems, setBasisItems] = useState([
        "FCA",
        "DAP",
        "FOB", 
        "FAS", 
        "CIF",
        "CFR", 
        "CPT", 
        "CIP"
    ]);

    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedCountryLang, setSelectedCountryLang] = useState("");
    const [countrySearchValue, setCountrySearchValue] = useState("");
    const [countrySearchState, setCountrySearchState] = useState(false);
    const [countryItems, setCountryItems] = useState([]);

    useEffect(() => {
        if(selectedCountry !== countrySearchValue) {
            setSelectedCountry("");

            if(countrySearchValue !== "" && countrySearchValue.length > 1) {
                /*let url = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/country`;
                axios.post(
                    url,
                    { query: countrySearchValue },
                    { 
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Token 11f0f146144bfa7088a1dbc10e3186e809ce48f2"
                        },
                        withCredentials: false
                    }
                ).then(response => {
                    //console.log(response.data.suggestions);
                    if(response.data.suggestions !== undefined) {
                        let countries = [];
                        for(const cntr of response.data.suggestions) {
                            countries.push(cntr.value);
                        }
    
                        setCountryItems(countries);
                    }
                });*/

                axios.get(
                    `https://restcountries.com/v3.1/name/${countrySearchValue}`,
                    {headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                    }}
                ).then(response => {
                    console.log(response);
                    if(response.data.length > 0) {
                        let countries = [];
                        for(const cntr of response.data) {
                            if(lang === 0) {
                                countries.push(cntr.name.nativeName.rus.common);
                            } else {
                                countries.push(cntr.name.common);
                            }
                        }

                        setCountryItems(countries);
                    }
                });
            } else {
                setCountryItems([]);
            }
        }
    }, [countrySearchValue]);

    const [station, setStation] = useState("");

    const [transportType1, setTransportType1] = useState(-1);
    const [transportType1Items, setTransportType1Items] = useState([
        ["Контейнерная", "Неконтейнерная"],
        ["Container", "Non-Container"],
        ["集装箱", "无集装箱"]
    ]);

    const [transportType2, setTransportType2] = useState(-1);
    const [transportType2Items, setTransportType2Items] = useState([
        ["Железнодорожная", "Автомобильная", "Морская"],
        ["Railway", "Car", "Sea"],
        ["铁路运输", "汽车运输", "海洋运输"]
    ]);

    const oilPackageItems = [["Налив", "Евробочки", "Бутылки"], ["Налив", "Евробочки", "Бутылки"], ["Налив", "Евробочки", "Бутылки"]];
    const defaultPackageItems = [["Насыпь", "Мешки", "Биг-бэги"], ["Embankment", "Bags", "Big bags"], ["散装", "麻袋", "吨袋"]];

    const [productPackage, setProductPackage] = useState(-1);
    const [productPackageItems, setProductPackageItems] = useState([]);


    const [periodFrom, setPeriodFrom] = useState(-1);
    const [periodTo, setPeriodTo] = useState(-1);
    const [periodItems, setPeriodItems] = useState([]);

    const [stage2Errors, setStage2Errors] = useState([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
    ]);

    function getPeriodItems() {
        let periods = [];
        let date = new Date();
        let mon = date.getMonth();
        let year = date.getFullYear();

        let monthLangDict = [
            [
                "Январь",
                "January",
                "1月"
            ],
            [
                "Февраль",
                "February",
                "2月"
            ],
            [
                "Март",
                "March",
                "3月"
            ],
            [
                "Апрель",
                "April",
                "4月"
            ],
            [
                "Май",
                "May",
                "5月"
            ],
            [
                "Июнь",
                "June",
                "6月"
            ],
            [
                "Июль",
                "July",
                "7月"
            ],
            [
                "Август",
                "August",
                "8月"
            ],
            [
                "Сентябрь",
                "September",
                "9月"
            ],
            [
                "Октябрь",
                "October",
                "10月"
            ],
            [
                "Ноябрь",
                "November",
                "11月"
            ],
            [
                "Декабрь",
                "December",
                "12月"
            ]
        ];

        for(let j = mon; j < 12; j++) {
            periods.push(monthLangDict[j][lang] + " " + year);
        }

        for(let i = year+1; i < 2030; i++) {
            for(let j = 0; j < 12; j++) {
                periods.push(monthLangDict[j][lang] + " " + i);
            }
        }

        for(let j = 0; j < mon; j++) {
            periods.push(monthLangDict[j][lang] + " 2030");
        }

        return periods;
    }

    function getPeriodAliases() {
        let monthDict = [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь"
        ];

        let periodAl = [];

        let date = new Date();
        let mon = date.getMonth();
        let year = date.getFullYear();

        for(let j = mon; j < 12; j++) {
            periodAl.push(monthDict[j] + " " + year);
        }

        for(let i = year+1; i < 2030; i++) {
            for(let j = 0; j < 12; j++) {
                periodAl.push(monthDict[j] + " " + i);
            }
        }

        for(let j = 0; j < mon; j++) {
            periodAl.push(monthDict[j] + " 2030");
        }

        return periodAl;
    }

    async function closeCatSearch() {
        await timeout(200);
        setCatSearchState(false);
    }

    const [itemSearchValue, setItemSearchValue] = useState("");
    const [itemSearchState, setItemSearchState] = useState(false);
    const [itemResults, setItemResults] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);

    const [globalSearchValue, setGlobalSearchValue] = useState("");
    const [globalSearchState, setGlobalSearchState] = useState(false);
    const [globalResults, setGlobalResults] = useState([]);

    function updateCatSearchValue(value) {
        setCatSearchValue(value);
        if(value === "") {
            setCatResults(categoriesData);
        } else {
            let cats = [];
            categoriesData.forEach((c) => {
                if(c.lang !== undefined) {
                    if(c.lang[lang].toLowerCase().includes(value.toLowerCase())) {
                        cats.push(c);
                    }
                } else {
                    if(c.label.toLowerCase().includes(value.toLowerCase())) {
                        cats.push(c);
                    }
                }
            })

            setCatResults(cats);
        }
    }

    function updateItemSearchValue(value) {
        setItemSearchValue(value);
        if(value === "") {
            setItemResults([]);
        } else {
            let items = [];
            selectedCategory.items.forEach((c) => {
                if(c.label.toLowerCase().includes(value.toLowerCase())) {
                    items.push(c);
                }
            })

            setItemResults(items);
        }
    }

    function updateGlobalSearchValue(value) {
        setGlobalSearchValue(value);
        if(value === "") {
            setGlobalResults([]);
        } else {
            let gres = [];
            categoriesData.forEach((cat, index1) => {
                cat.items.forEach((item, index2) => {
                    if(item.lang !== undefined) {
                        if(item.lang[lang].toLowerCase().includes(value.toLowerCase())) {
                            gres.push({
                                catLabel: cat.label,
                                catLang: cat.lang !== undefined ? cat.lang[lang] : cat.label,
                                itemLabel: item.name,
                                itemLang: item.lang[lang]
                            });
                        }
                    } else {
                        if(item.name.toLowerCase().includes(value.toLowerCase())) {
                            gres.push({
                                catLabel: cat.label,
                                catLang: cat.lang !== undefined ? cat.lang[lang] : cat.label,
                                itemLabel: item.name,
                                itemLang: item.name
                            });
                        }
                    }
                })
            })

            setGlobalResults(gres);
        }
    }

    async function continueToStage1() {
        if(selectedCategory !== null && selectedItem !== "") {
            getIndexesForItem(selectedItem, function(indexes) {
                setIndexesData(indexes);
                let _values = [];
                let _errors = [];

                for(const ind of indexes) {
                    if(ind.format !== "picker") {
                        _values.push("");
                    } else {
                        _values.push(0);
                    }
                    _errors.push(false);
                }

                setValues(_values);
                setErrors(_errors);
                setStage(1);
            });
        } else {
            return;
        }
    }

    // stage 1
    const [productVolume, setProductVolume] = useState("");
    const [values, setValues] = useState(["", "", "", ""]);

    const [volError, setVolError] = useState(false);
    const [errors, setErrors] = useState([false, false, false, false]);

    const updateValue = (index, value) => {
        let val_old = values;
        val_old[index] = value;
        setValues(val_old);
        setTrigger(1-trigger);
        
        let err_old = errors;
        err_old[index] = false;
        setErrors(err_old);
        
        setTrigger(1-trigger);
    }

    function parseFilter(filter) {
        switch(filter) {
            case 'from':
                return modal_lang[34][lang];
            case 'to':
                return modal_lang[33][lang];
            default:
                return "";
        }
    }

    // errors
    const [trigger, setTrigger] = useState(0);

    useEffect(() => {
        if(visible) {
            toggleOpen();
        }
    }, [visible]);

    async function toggleOpen() {
        await timeout(10);
        setDisplayed(true);
    }

    const toggleClose = async () => {
        setDisplayed(false);
        resetValues();
        await timeout(200);
        setStage(0);
        //setErrors([false, false, false]);
        onRequestClose();
    }

    const acceptChanges = () => {
        //onComplete();
        let indexes = [];
        indexesData.forEach((item, index) => {
            indexes.push(Object.assign(item, { value: values[index] }));
        });

        //console.log(indexes);
        companyService.getCompaniesList(token, function(companies) {
            if(companies.companies !== undefined) {
                let parent_company = companies.companies[0].pk;

                let periodAliases = getPeriodAliases();

                productionRequestService.addProductionRequest(
                    {
                        "category": (selectedCategory.label !== undefined ? selectedCategory.label : selectedCategory),
                        "name": selectedItem,
                        "volume": productVolume,
                        "basis": basisItems[basis],
                        "country": selectedCountry,
                        "station": station,
                        "ttype1": transportType1Items[lang][transportType1],
                        "ttype2": transportType2Items[lang][transportType2],
                        "package": productPackageItems[lang][productPackage],
                        "period1": periodAliases[periodFrom],
                        "period2": periodAliases[periodTo],
                        "params": {
                            indexes
                        },
                        "price_mode": priceMode,
                        "price": price,
                        "currency": priceMode === 0 ? currencies[selectedCurrency] : "",
                        "parent_company": parent_company
                    },
                    token,
                    function(response) {
                        //console.log(response);
                        if(response.production_request !== null) {
                            setVerifying(false);
                            toggleClose();
                            onComplete();
                        }
                    }
                );
            }
        });
    }

    function tryCommitValues() {
        let verr = false;
        if(productVolume === "") {
            setVolError(true);
            verr = true;
        }

        var index = 0;
        for(const value of values) {
            if(value === "") {
                let err_old = errors;
                err_old[index] = true;
                setErrors(err_old);
                setTrigger(1-trigger);
            }

            index += 1;
        }

        if(verr === true) {return;}
        for(const error of errors) {
            if(error) {return;}
        }

        if(priceMode===0 && price === "") {
            setPriceError(true);
            return;
        }

        setStage(2);
    }
    
    const catRef = useOutsideClick(() => {setCatSearchState(false)});
    const itemRef = useOutsideClick(() => {setItemSearchState(false)});
    const globalRef = useOutsideClick(() => {setGlobalSearchState(false)});

    const countryRef = useOutsideClick(() => {setCountrySearchState(false)});

    const [itemLang, setItemLang] = useState("");

    const monthlang = {
        "Январь": [
            "Январь",
            "January",
            "1月"
        ],
        "Февраль": [
            "Февраль",
            "February",
            "2月"
        ],
        "Март": [
            "Март",
            "March",
            "3月"
        ],
        "Апрель": [
            "Апрель",
            "April",
            "4月"
        ],
        "Май": [
            "Май",
            "May",
            "5月"
        ],
        "Июнь": [
            "Июнь",
            "June",
            "6月"
        ],
        "Июль": [
            "Июль",
            "July",
            "7月"
        ],
        "Август": [
            "Август",
            "August",
            "8月"
        ],
        "Сентябрь": [
            "Сентябрь",
            "September",
            "9月"
        ],
        "Октябрь": [
            "Октябрь",
            "October",
            "10月"
        ],
        "Ноябрь": [
            "Ноябрь",
            "November",
            "11月"
        ],
        "Декабрь": [
            "Декабрь",
            "December",
            "12月"
        ]
    };

    if(visible) {
        return(
            <div className="InfoModal__root">
                <div 
                    className={"ProductModal__background"+(displayed ? "" : "-hidden")}
                    onClick={toggleClose}
                />

                <div className={"ProductRequestModal__container"+(displayed ? "" : "-hidden")}>
                    <div className="ProductModal__progress-container">
                        <div
                            className="ProductModal__progress-indicator-active"
                            style={{
                                marginLeft: 17,
                            }}
                        >
                            {stage>0?<img src={doneIcon} className="InfoModal__progress-icon" />:<div/>}
                        </div>
                        {stage === 0 && <p className="ProductModal__progress-title" style={{ width: 216, minWidth: 216 }}>{modal_lang[0][lang]}</p>}
                        
                        <div 
                            className="ProductModal__progress-stick"
                            style={stage>0?{backgroundColor:"#0F62FE"}:{}}
                        />

                        <div
                            className={"ProductModal__progress-indicator"+(stage>0?"-active":"")}
                        >
                            {stage>1?<img src={doneIcon} className="InfoModal__progress-icon" />:<div/>}
                        </div>
                        {stage === 1 && <p className="ProductModal__progress-title" style={{ width: 158, minWidth: 158 }}>{modal_lang[7][lang]}</p>}

                        <div 
                            className="ProductModal__progress-stick"
                            style={stage>1?{backgroundColor:"#0F62FE"}:{}}
                        />

                        <div
                            className={"ProductModal__progress-indicator"+(stage>1?"-active":"")}
                        >
                            {stage>2?<img src={doneIcon} className="InfoModal__progress-icon" />:<div/>}
                        </div>
                        {stage === 2 && <p className="ProductModal__progress-title" style={{ width: 122, minWidth: 122 }}>{modal_lang[19][lang]}</p>}

                        <div 
                            className="ProductModal__progress-stick"
                            style={stage>2?{backgroundColor:"#0F62FE"}:{}}
                        />

                        <div
                            className={"ProductModal__progress-indicator"+(stage>2?"-active":"")}
                            style={stage !== 3 ? {marginRight: 17} : {}}
                        />
                        {stage === 3 && <p className="ProductModal__progress-title" style={{ width: 112, minWidth: 112, marginRight: 17 }}>{modal_lang[39][lang]}</p>}
                    </div>

                    <div className="ProductModal__body">
                        {stage === 0 ?
                            <>
                                <p className="ProductModal__body-title">{modal_lang[1][lang]}</p>
                                <div className="ProductModal__pickers-container-1">
                                    <div className="ProductModal__picker-container" onClick={() => setCatSearchState(!catSearchState)} ref={catRef}>
                                        <input
                                            value={catSearchValue}
                                            onChange={(event) => updateCatSearchValue(event.target.value)}
                                            className="ProductModal__picker-input"
                                            placeholder={modal_lang[2][lang]}
                                        />

                                        <IoChevronDown
                                            color="#0F62FE"
                                            size={18}
                                            style={{ marginRight: 10, cursor: "pointer" }}
                                        />

                                        {catSearchState &&
                                        <div className="ProductModal__picker-result-container">
                                            <div className="ProductModal__picker-result-wrapper">
                                                {catResults.map((res, index) => {
                                                    return(
                                                        <div 
                                                            className="ProductModal__picker-result-item" 
                                                            key={index}
                                                            onClick={() => {
                                                                setCatSearchValue(res.lang !== undefined ? res.lang[lang] : res.label);
                                                                setSelectedCategory(res);
                                                                setSelectedItem("");
                                                                setItemSearchValue("");
                                                                setItemResults(res.items);
                                                                setGlobalSearchValue((res.lang !== undefined ? res.lang[lang] : res.label)+"/ ");
                                                                setCatSearchState(false);

                                                                if(res.label === "Растительные масла") {
                                                                    setProductPackageItems(oilPackageItems);
                                                                } else {
                                                                    setProductPackageItems(defaultPackageItems);
                                                                }
                                                            }}
                                                        >
                                                            <p className="ProductModal__picker-result-item-label">{res.lang !== undefined ? res.lang[lang] : res.label}</p>
                                                            {index !== catResults.length-1 && <div className="ProductModal__picker-result-item-divider"/>}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        }
                                    </div>

                                    <div className="ProductModal__picker-container" onClick={() => {if(selectedCategory !== null) {setItemSearchState(!itemSearchState)}}} ref={itemRef}>
                                        <input
                                            value={itemSearchValue}
                                            onChange={(event) => updateItemSearchValue(event.target.value)}
                                            className="ProductModal__picker-input"
                                            placeholder={modal_lang[3][lang]}
                                            disabled={selectedCategory === null}
                                        />

                                        <IoChevronDown
                                            color="#0F62FE"
                                            size={18}
                                            style={{ marginRight: 10, cursor: "pointer" }}
                                        />

                                        {itemSearchState &&
                                        <div className="ProductModal__picker-result-container">
                                            <div className="ProductModal__picker-result-wrapper">
                                                {itemResults.map((res, index) => {
                                                    return(
                                                        <div 
                                                            className="ProductModal__picker-result-item" 
                                                            key={index}
                                                            onClick={() => {
                                                                setItemSearchValue(res.lang !== undefined ? res.lang[lang] : res.name);
                                                                setItemLang(res.lang !== undefined ? res.lang[lang] : res.name);
                                                                setSelectedItem(res.name);
                                                                setItemSearchState(false);
                                                                setGlobalSearchValue((selectedCategory.lang !== undefined ? selectedCategory.lang[lang] : selectedCategory.label) + "/ " + (res.lang !== undefined ? res.lang[lang] : res.name));
                                                            }}
                                                        >
                                                            <p className="ProductModal__picker-result-item-label">{res.lang !== undefined ? res.lang[lang] : res.name}</p>
                                                            {index !== itemResults.length-1 && <div className="ProductModal__picker-result-item-divider"/>}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>

                                <div className="ProductModal__global-search-container" style={{marginBottom:"12px"}} onClick={() => setGlobalSearchState(!globalSearchState)} ref={globalRef}>
                                    <input
                                        value={globalSearchValue}
                                        placeholder={modal_lang[4][lang]}
                                        className="ProductModal__global-search-input"
                                        onChange={(event) => updateGlobalSearchValue(event.target.value)}
                                        
                                    />

                                    {globalSearchState && 
                                    <div className="ProductModal__global-result-container">
                                        <div className="ProductModal__global-result-wrapper">
                                            {globalResults.map((res, index) => {
                                                return(
                                                    <div 
                                                        className="ProductModal__global-result-item"
                                                        onClick={() => {
                                                            setCatSearchValue(res.catLang);
                                                            setItemSearchValue(res.itemLang);
                                                            setItemLang(res.itemLang);
                                                            setSelectedItem(res.itemLabel);
                                                            categoriesData.forEach((cat, index) => {
                                                                if(cat.label === res.catLabel) {
                                                                    setSelectedCategory(cat);
                                                                    setItemResults(cat.items);
                                                                    setCatResults([]);

                                                                    if(cat.label === "Растительные масла") {
                                                                        setProductPackageItems(oilPackageItems);
                                                                    } else {
                                                                        setProductPackageItems(defaultPackageItems);
                                                                    }
                                                                }
                                                            });
                                                            setGlobalSearchValue(res.catLang + "/ " + res.itemLang);
                                                        }}
                                                        key={index}
                                                    >
                                                        <p className="ProductModal__global-result-item-label">{res.catLang}/ {res.itemLang}</p>
                                                        {index !== globalResults.length-1 && <div className="ProductModal__global-result-item-divider"/>}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    }
                                </div>

                                <p 
                                    className="LogInModal__switch-signup" 
                                    style={{
                                        alignSelf: "flex-start",
                                        marginLeft: "32px",
                                        fontSize:"14px",
                                        color:"#161616",
                                        marginBottom:"12px",
                                        lineHeight:"18px"
                                    }} 
                                    onClick={() => {
                                        toggleClose();
                                        navigate("/account/feedback");
                                    }}
                                >
                                    Не нашли нужную продукцию? <span className="LogInModal__switch-signup-link">Напишите в поддержку</span>, и мы добавим её на платформу
                                </p>

                                <div className="ProductModal__actions-container">
                                    <GreyButton
                                        text={modal_lang[5][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        onClick={toggleClose}
                                    />

                                    <BlueButton
                                        text={modal_lang[6][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        disabled={!(selectedCategory !== null && selectedItem !== "")}
                                        onClick={() => {
                                            if(verifying) {
                                                setStage(3);
                                            } else {
                                                continueToStage1();
                                            }
                                        }}
                                    />
                                </div>
                            </>    
                            :(stage === 1 ?
                            <>
                                <p className="ProductModal__body-title">{modal_lang[8][lang]}</p>
                                <TextField
                                    label={modal_lang[9][lang]}
                                    placeholder={modal_lang[10][lang]}
                                    style={{
                                        marginLeft: 32,
                                        marginBottom: 12,
                                    }}
                                    inputWidth={"720px"}
                                    value={productVolume}
                                    onChangeText={text => {
                                        setProductVolume(text);
                                        if(text !== "") {
                                            setVolError(false);
                                        }
                                    }}
                                    error={volError}
                                    errorMessage={modal_lang[32][lang]}
                                    indicatorShown={false}
                                />

                                <div className="ProductModal__inputs-wrapper">
                                    {indexesData.map((data, index) => {
                                        if(data.format !== "picker") {
                                            return(
                                                <TextField
                                                    inputWidth={"344px"}
                                                    style={index%2 !== 0 ? { marginLeft: 16 } : {}}
                                                    key={index}
    
                                                    label={indexLang.lang[data.name][lang] + parseFilter(data.filter) + ", " + data.format}
                                                    placeholder={modal_lang[11][lang] + indexLang.lang[data.name][lang].toLowerCase()}
    
                                                    value={values[index]}
                                                    onChangeText={text => updateValue(index, text)}
                                                    error={errors[index]}
                                                    errorMessage={modal_lang[32][lang]}
                                                    indicatorShown={false}
                                                />  
                                            );
                                        } else {
                                            return(
                                                <div className="ProductModal__picker-input" key={index}  style={index%2 === 0 ? { marginLeft: 0 } : {}}>
                                                    <p className="ProductModal__picker-label">{indexLang.lang[data.name][lang]}</p>
                                                    <SliderPickerLarge
                                                        items={
                                                            indexLang.lang[data.base_values[0]] !== undefined ? 
                                                            [indexLang.lang[data.base_values[0]][lang], indexLang.lang[data.base_values[1]][lang]]
                                                            :
                                                            data.base_values.slice(0, 2)
                                                        } // ONLY 2 CASES SUPPORTED!
                                                        style={{width:"360px"}}
                                                        labelStyles={[
                                                            {fontWeight:"400"},
                                                            {fontWeight:"400"}
                                                        ]}
                                                        value={values[index]}
                                                        onChange={value => updateValue(index, value)}
                                                    />
                                                </div>
                                            );
                                        }
                                    })}
                                </div>

                                <p className="ProductRequestModal__pricemode-label">{modal_lang[15][lang]}</p>
                                <div className="ProductRequestModal__pricemode-picker-container">
                                    <div className="ProductRequestModal__pricemode-picker-item" onClick={() => setPriceMode(0)}>
                                        <div className="ProductRequestModal__pricemode-item-indicator">
                                            {priceMode === 0 && <div className="ProductRequestModal__pricemode-item-indicator-inner"/>}
                                        </div>

                                        <p className="ProductRequestModal__pricemode-item-label" style={priceMode!==0?{color:"#6F6F6F"}:{}}>{modal_lang[13][lang]}</p>
                                    </div>

                                    <div className="ProductRequestModal__pricemode-picker-item" onClick={() => setPriceMode(1)}>
                                        <div className="ProductRequestModal__pricemode-item-indicator">
                                            {priceMode === 1 && <div className="ProductRequestModal__pricemode-item-indicator-inner"/>}
                                        </div>

                                        <p className="ProductRequestModal__pricemode-item-label" style={priceMode!==1?{color:"#6F6F6F"}:{}}>{modal_lang[14][lang]}</p>
                                    </div>
                                </div>

                                {priceMode === 0 &&
                                <div className="ProductRequestModal__price-container">
                                    <div className="ProductRequestModal__price-input-container">
                                        {/*
                                        <p className="ProductRequestModal__pricemode-label" style={{marginLeft:"0px"}}>Укажите цену за 1 т</p>
                                        <input
                                            value={price}
                                            onChange={event => setPrice(event.target.value)}
                                            className="ProductRequestModal__price-input"
                                            placeholder="Укажите цену"
                                        />
                                        */}

                                        <TextField
                                            label={modal_lang[16][lang]}
                                            value={price}
                                            onChangeText={setPrice}
                                            indicatorShown={false}
                                            inputWidth="calc(100% - 16px)"
                                            placeholder={modal_lang[17][lang]}
                                            errorMessage={modal_lang[72][lang]}
                                            error={priceError}
                                        />
                                    </div>

                                    <div className="ProductRequestModal__price-input-container">
                                        <p className="ProductRequestModal__pricemode-label" style={{marginLeft:"0px"}}>{modal_lang[18][lang]}</p>
                                        <div className="ProductRequestModal__price-currency-picker-container">
                                            {currencies.map((curr, index) => {
                                                return(
                                                    <div className="ProductRequestModal__price-currency-picker-item" onClick={() => setSelectedCurrency(index)}>
                                                        <div className="ProductRequestModal__pricemode-item-indicator">
                                                            <div className="ProductRequestModal__pricemode-item-indicator-inner" style={selectedCurrency === index?{opacity:1}:{opacity:0}}/>
                                                        </div>

                                                        <p className="ProductRequestModal__pricemode-item-label"  style={selectedCurrency!==index?{color:"#6F6F6F"}:{}}>{curr}</p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                }

                                <div className="ProductModal__actions-container" style={{ marginTop: 16 }}>
                                    <GreyButton
                                        text={modal_lang[12][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        onClick={() => {setStage(0)}}
                                    />

                                    <BlueButton
                                        text={modal_lang[6][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        disabled={!(selectedCategory !== null && selectedItem !== "")}
                                        onClick={() => {
                                            if(verifying) {
                                                setStage(3);
                                            } else {
                                                tryCommitValues();
                                            }
                                        }}
                                    />
                                </div>
                            </>
                                
                            :(stage === 2 ?
                            <>
                                <p className="ProductModal__body-title">{modal_lang[20][lang]}</p>
                                
                                <p className="ProductRequestModal__picker-subtitle">{modal_lang[21][lang]}</p>
                                <div className="ProductRequestModal__picker-wrapper">
                                    <SquareDropdownPicker
                                        placeholder={modal_lang[22][lang]}
                                        value={basis}
                                        items={basisItems}
                                        onChange={(value) => {
                                            setBasis(value);
                                        }}

                                        style={{
                                            borderRight: "1px solid #E0E0E0"
                                        }}
                                        textStyle={{
                                            marginLeft: 0
                                        }}
                                    />

                                    <div 
                                        className="ProductRequestModal__station-input-container" 
                                        style={{borderRight: "1px solid #E0E0E0"}}
                                        onClick={() => setCountrySearchState(!countrySearchState)}
                                        ref={countryRef}
                                    >
                                        <input
                                            value={countrySearchValue}
                                            onChange={event => setCountrySearchValue(event.target.value)}
                                            className="ProductRequestModal__station-input"
                                            placeholder={modal_lang[23][lang]}
                                        />

                                        {countrySearchState &&
                                        <div className="ProductRequestModal__country-results-container">
                                            <div className="ProductRequestModal__country-results-wrapper">
                                            {
                                                countryItems.map((country, index) => {
                                                    return(
                                                        <div 
                                                            className="ProductRequestModal__country-result-item" 
                                                            key={index}
                                                            onClick={() => {
                                                                setSelectedCountry(country);
                                                                setCountrySearchValue(country);
                                                            }}
                                                        >
                                                            <p className="ProductRequestModal__country-result-item-label">{country}</p>
                                                        </div>
                                                    );
                                                })
                                            }
                                            </div>
                                        </div>
                                        }
                                    </div>

                                    <div className="ProductRequestModal__station-input-container">
                                        <input
                                            value={station}
                                            onChange={event => setStation(event.target.value)}
                                            className="ProductRequestModal__station-input"
                                            placeholder={modal_lang[24][lang]}
                                        />
                                    </div>
                                </div>

                                <p className="ProductRequestModal__picker-subtitle" style={{marginTop:"32px"}}>{modal_lang[25][lang]}</p>
                                <div className="ProductRequestModal__picker-wrapper">
                                    <SquareDropdownPicker
                                        placeholder={modal_lang[26][lang]}
                                        value={transportType1}
                                        items={transportType1Items[lang]}
                                        onChange={(value) => {
                                            setTransportType1(value);
                                        }}

                                        style={{
                                            borderRight: "1px solid #E0E0E0"
                                        }}
                                        textStyle={{
                                            marginLeft: 0
                                        }}
                                    />

                                    <SquareDropdownPicker
                                        placeholder={modal_lang[27][lang]}
                                        value={transportType2}
                                        items={transportType2Items[lang]}
                                        onChange={(value) => {
                                            setTransportType2(value);
                                        }}

                                        style={{
                                            borderRight: "1px solid #E0E0E0"
                                        }}
                                    />

                                    <SquareDropdownPicker
                                        placeholder={modal_lang[28][lang]}
                                        value={productPackage}
                                        items={productPackageItems[lang]}
                                        onChange={(value) => {
                                            setProductPackage(value);
                                        }}
                                    />
                                </div>

                                <p className="ProductRequestModal__picker-subtitle" style={{marginTop:"32px"}}>{modal_lang[29][lang]}</p>
                                <div className="ProductRequestModal__picker-wrapper">
                                    <SquareDropdownPicker
                                        placeholder={modal_lang[30][lang]}
                                        value={periodFrom}
                                        items={periodItems}
                                        onChange={(value) => {
                                            if(value > periodTo && periodTo !== -1) {
                                                let err2 = stage2Errors;
                                                err2[6] = true;
                                                setStage2Errors(err2);
                                                setPeriodFrom(-1);
                                            } else {
                                                setPeriodFrom(value);
                                                if(stage2Errors[6]) {
                                                    let err2 = stage2Errors;
                                                    err2[6] = false;
                                                    setStage2Errors(err2);
                                                    setTrigger(1-trigger);
                                                }
                                            }
                                        }}
                                        error={stage2Errors[6]}

                                        style={{
                                            borderRight: "1px solid #E0E0E0"
                                        }}
                                        textStyle={{
                                            marginLeft: 0
                                        }}
                                    />

                                    <SquareDropdownPicker
                                        placeholder={modal_lang[31][lang]}
                                        value={periodTo}
                                        items={periodItems}
                                        onChange={(value) => {
                                            if(value < periodFrom) {
                                                let err2 = stage2Errors;
                                                err2[7] = true;
                                                setStage2Errors(err2);
                                                setPeriodTo(-1);
                                            } else {
                                                setPeriodTo(value);
                                                if(stage2Errors[7]) {
                                                    let err2 = stage2Errors;
                                                    err2[7] = false;
                                                    setStage2Errors(err2);
                                                    setTrigger(1-trigger);
                                                }
                                            }
                                        }}
                                        error={stage2Errors[7]}
                                    />
                                </div>

                                <div className="ProductModal__actions-container" style={{ marginTop: 16 }}>
                                    <GreyButton
                                        text={modal_lang[12][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        onClick={() => {setStage(1)}}
                                    />

                                    <BlueButton
                                        text={modal_lang[6][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        disabled={!(basis !== -1 && selectedCountry !== "" && station !== "" && transportType1 !== -1 && transportType2 !== -1 && productPackage !== -1 && periodFrom !== -1 && periodTo !== -1)}
                                        onClick={() => {
                                            setStage(3);
                                            setVerifying(true);
                                        }}
                                    />
                                </div>
                            </>
                            :
                            <>
                                <p className="ProductModal__body-title">{modal_lang[40][lang]}</p>

                                <div className="ProductModal__result-container">
                                    <p className="ProductModal__result-subheader">{modal_lang[41][lang]}</p>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[2][lang]}</p>
                                        <p className="ProductModal__result-item-value">{(selectedCategory.lang !== undefined ? selectedCategory.lang[lang] : selectedCategory.label)}</p>
                                    </div>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[3][lang]}</p>
                                        <p className="ProductModal__result-item-value">{itemLang}</p>
                                    </div>

                                    <img 
                                        src={editIcon} 
                                        className="ProductModal__result-item-edit"
                                        onClick={() => setStage(0)}
                                    />
                                    <div className="ProductModal__result-divider"/>
                                </div>

                                <div className="ProductModal__result-container">
                                    <p className="ProductModal__result-subheader">{modal_lang[7][lang]}</p>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[9][lang]}</p>
                                        <p className="ProductModal__result-item-value">{productVolume}</p>
                                    </div>

                                    {indexesData.map((data, index) => {
                                        if(data.format !== "picker") {
                                            return(
                                                <div className="ProductModal__result-item-container" key={index}>
                                                    <p className="ProductModal__result-item-label">{indexLang.lang[data.name][lang]}</p>
                                                    <p className="ProductModal__result-item-value">{values[index]} {data.format}</p>
                                                </div>
                                            );
                                        } else {
                                            return(
                                                <div className="ProductModal__result-item-container" key={index}>
                                                    <p className="ProductModal__result-item-label">{indexLang.lang[data.name][lang]}</p>
                                                    <p className="ProductModal__result-item-value">{data.base_values[values[index]]}</p>
                                                </div>
                                            );
                                        }
                                    })}

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">Цена</p>
                                        <p className="ProductModal__result-item-value">{priceMode===1?"По согласованию":`${price}${currencies[selectedCurrency]} за т`}</p>
                                    </div>  

                                    <img 
                                        src={editIcon} 
                                        className="ProductModal__result-item-edit"
                                        onClick={() => setStage(1)}
                                    />
                                    <div className="ProductModal__result-divider"/>
                                </div>

                                <div className="ProductModal__result-container">
                                    <p className="ProductModal__result-subheader">{modal_lang[19][lang]}</p>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[22][lang]}</p>
                                        <p className="ProductModal__result-item-value">{basisItems[basis]}</p>
                                    </div>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[23][lang]}</p>
                                        <p className="ProductModal__result-item-value">{selectedCountry}</p>
                                    </div>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[24][lang]}</p>
                                        <p className="ProductModal__result-item-value">{station}</p>
                                    </div>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[26][lang]}</p>
                                        <p className="ProductModal__result-item-value">{transportType1Items[lang][transportType1]}</p>
                                    </div>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[27][lang]}</p>
                                        <p className="ProductModal__result-item-value">{transportType2Items[lang][transportType2]}</p>
                                    </div>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[28][lang]}</p>
                                        <p className="ProductModal__result-item-value">{productPackageItems[lang][productPackage]}</p>
                                    </div>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[70][lang]}</p>
                                        <p className="ProductModal__result-item-value">{monthlang[periodItems[periodFrom].split(" ")[0]][lang]} {periodItems[periodFrom].split(" ")[1]} - {monthlang[periodItems[periodTo].split(" ")[0]][lang]} {periodItems[periodTo].split(" ")[1]}</p>
                                    </div>

                                    <img 
                                        src={editIcon} 
                                        className="ProductModal__result-item-edit"
                                        onClick={() => setStage(2)}
                                    />
                                </div>

                                <div className="ProductModal__actions-container" style={{ marginTop: 24 }}>
                                    <GreyButton
                                        text={modal_lang[12][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        onClick={() => {
                                            setStage(2);
                                            setVerifying(false);
                                        }}
                                    />

                                    <BlueButton
                                        text={modal_lang[42][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        onClick={() => {
                                            //console.log("SAVE DATA");
                                            acceptChanges();
                                        }}
                                    />
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export function RoleModal({
    defaultValues,
    onAccept,
    onRequestClose,
    visible,
    lang=1
}) {
    const engRoles = [
        "buyer",
        "seller",
        //"service"
    ];

    const roleDict = [
        [
            "Покупатель",
            "Buyer",
            "买方"
        ],
        [
            "Поставщик",
            "Supplier",
            "供应者"
        ],
        [
            "Сервис",
            "Сервис",
            "Сервис"
        ]
    ];

    function parseResponse() {
        let str = "";
        let index = 0;
        for(const value of values) {
            str += engRoles[value];
            if(index !== values.length-1) {
                str += " ";
            }

            index += 1;
        }

        return str;
    }

    useEffect(() => {
        setValues(defaultValues);
    }, [defaultValues]);

    const [displayed, setDisplayed] = useState(false);
    const [values, setValues] = useState([]);

    const roles = ["Покупатель", "Поставщик"]; //, "Сервис"

    useEffect(() => {
        if(visible) {
            toggleOpen();
        }
    }, [visible]);

    async function toggleOpen() {
        await timeout(10);
        setDisplayed(true);
    }

    const toggleClose = async () => {
        setDisplayed(false);
        await timeout(200);
        onRequestClose();
    }

    const tryAccept = () => {
        onAccept(parseResponse());
        toggleClose();
    }

    if(visible) {
        return(
            <div className="InfoModal__root">
                <div 
                    className={"InfoModal__background"+(displayed ? "" : "-hidden")}
                    onClick={toggleClose}
                />
                <div className={"InfoModal__container"+(displayed ? "" : "-hidden")}>
                    <p className="InfoModal__header">Роль на платформе</p>
                    {/*
                    <SliderPickerLarge
                        items={roles}
                        value={value}
                        onChange={setValue}
                        style={{ marginLeft: "32px", marginBottom: "32px", marginTop: "16px" }}
                    />
                    */}

                    <MultiPickerLarge
                        items={[roleDict[0][lang], roleDict[1][lang]]}
                        values={values}
                        onChange={setValues}
                        style={{ marginLeft: "32px", marginBottom: "32px", marginTop: "16px" }}
                    />

                    <div className="InfoModal__button-container">
                        <GreyButton
                            text={modal_lang[5][lang]}
                            onClick={toggleClose}
                            style={{
                                width: 233,
                                height: 32
                            }}
                        />

                        <BlueButton
                            text={profile_lang[20][lang]}
                            onClick={tryAccept}
                            style={{
                                width: 233,
                                height: 32
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export function SendOfferModalFromSeller({
    visible,
    requestData,
    onRequestClose,
    onAccept,
    token,
    lang=1
}) {
    const [displayed, setDisplayed] = useState(false);
    const [stage, setStage] = useState(0);

    const [volume, setVolume] = useState("");
    const [volumeError, setVolumeError] = useState(false);

    const [values, setValues] = useState([]);
    const [errors, setErrors] = useState([]);

    const [indexLang, setIndexLang] = useState({});

    useEffect(() => {
        if(visible) {
            toggleOpen();
        }

        indexLanguage(function(r) {
            setIndexLang(r);
        });
    }, [visible]);

    async function toggleOpen() {
        await timeout(10);
        setDisplayed(true);
    }

    useEffect(() => {
        if(requestData !== null) {
            let _v = [];
            let _e = [];
            for(const _d of requestData.params.indexes) {
                if(_d.format !== "picker") {
                    _v.push("");
                } else {
                    _v.push(0);
                }
                _e.push(false);
            }

            setValues(_v);
            setErrors(_e);
        }
    }, [requestData]);

    function resetValues() {
        setValues([]);
        setVolume("");
    }

    const toggleClose = async () => {
        setDisplayed(false);
        resetValues();
        await timeout(200);
        onRequestClose();
    }

    function parseIndexValue(index) {
        let str = "";
        if(index.format !== "picker") {
            switch(index.filter) {
                case "from":
                    str += modal_lang[34][lang] += " ";
                    break;
                case "to":
                    str += modal_lang[33][lang] += " ";
                    break;
                default:
                    break;
            }

            str += index.value + " ";
            str += index.format !== "%" && index.format !== "" ? indexLang.lang[index.format][lang] : (index.format);
        } else {
            str += indexLang.lang[index.base_values[index.value]][lang];
        }

        return str;
    }

    function parseFilter(filter) {
        switch(filter) {
            case 'from':
                return modal_lang[34][lang];
            case 'to':
                return modal_lang[33][lang];
            default:
                return "";
        }
    }

    function updateValue(index, value) {
        let _v = values;
        _v[index] = value;
        setValues(_v);

        if(value !== "" && errors[index]) {
            updateError(index, false);
        } else {
            setTrigger(1-trigger);
        }
    }

    function updateError(index, state) {
        let _e = errors;
        _e[index] = state;
        setErrors(_e);

        setTrigger(1-trigger);
    }

    function tryAccept() {
        let flag = true;

        if(volume === "") {
            setVolumeError(true);
            flag = false;
        }

        let index = 0;
        for(const _v of values) {
            if(_v === "") {
                updateError(index, true);
                flag = false;
            }

            index += 1;
        }

        if(flag) {
            tryCreateTransaction();
        } else {
            return;
        }
    }

    function tryCreateTransaction() {
        let sellerIndexes = [];

        let index = 0;
        for(const ind of requestData.params.indexes) {
            sellerIndexes.push({
                name: ind.name,
                format: ind.format,
                filter: ind.filter,
                base_values: ind.base_values,
                priority: ind.priority,
                editable: ind.editable,
                value: values[index]
            });

            index += 1;
        }

        let sellerData = Object.assign({"seller_volume": volume}, {"seller_params":{"indexes":sellerIndexes}});
        let transactionData = Object.assign(requestData, sellerData);

        companyService.getCompaniesList(token, function(response) {
            let selfID = response.companies[0].pk;
            let partnerID = requestData.parent_company;

            transactionService.addTransaction(
                partnerID,
                selfID,
                selfID,
                transactionData,
                token,
                function(response) {
                    console.log(response);
                    onRequestClose();
                }
            );
        })
    }

    const [trigger, setTrigger] = useState(0);

    const monthlang = {
        "Январь": [
            "Январь",
            "January",
            "1月"
        ],
        "Февраль": [
            "Февраль",
            "February",
            "2月"
        ],
        "Март": [
            "Март",
            "March",
            "3月"
        ],
        "Апрель": [
            "Апрель",
            "April",
            "4月"
        ],
        "Май": [
            "Май",
            "May",
            "5月"
        ],
        "Июнь": [
            "Июнь",
            "June",
            "6月"
        ],
        "Июль": [
            "Июль",
            "July",
            "7月"
        ],
        "Август": [
            "Август",
            "August",
            "8月"
        ],
        "Сентябрь": [
            "Сентябрь",
            "September",
            "9月"
        ],
        "Октябрь": [
            "Октябрь",
            "October",
            "10月"
        ],
        "Ноябрь": [
            "Ноябрь",
            "November",
            "11月"
        ],
        "Декабрь": [
            "Декабрь",
            "December",
            "12月"
        ]
    };

    if(visible) {
        return(
            <div className="InfoModal__root">
                <div 
                    className={"ProductModal__background"+(displayed ? "" : "-hidden")}
                    onClick={toggleClose}
                />

                <div className={"ProductRequestModal__container"+(displayed ? "" : "-hidden")}>
                    <div className="ProductModal__progress-container">
                        <div
                            className="ProductModal__progress-indicator-active"
                            style={{
                                marginLeft: 17,
                            }}
                        >
                            {stage>0?<img src={doneIcon} className="InfoModal__progress-icon" />:<div/>}
                        </div>
                        {stage === 0 && <p className="ProductModal__progress-title" style={{ width: "max-content", minWidth: "max-content" }}>{modal_lang[69][lang]}</p>}
                        
                        <div 
                            className="ProductModal__progress-stick"
                            style={stage>0?{backgroundColor:"#0F62FE"}:{}}
                        />

                        <div
                            className={"ProductModal__progress-indicator"+(stage>2?"-active":"")}
                            style={stage !== 3 ? {marginRight: 17} : {}}
                        />
                        {stage === 1 && <p className="ProductModal__progress-title" style={{ width: 112, minWidth: 112, marginRight: 17 }}>{modal_lang[39][lang]}</p>}
                    </div>

                    <div className="ProductModal__body">
                        <p className="SendOfferModal__title">{modal_lang[69][lang]}</p>
                    </div>

                    <div className="ProductModal__result-container">
                        <p className="ProductModal__result-subheader">{modal_lang[41][lang]}</p>

                        {/*<div className="ProductModal__result-item-container">
                            <p className="ProductModal__result-item-label">Вид</p>
                            <p className="ProductModal__result-item-value">{'Сельхоз продукция'}</p>
                        </div>*/}

                        <div className="ProductModal__result-item-container">
                            <p className="ProductModal__result-item-label">{modal_lang[2][lang]}</p>
                            <p className="ProductModal__result-item-value">{requestData.category}</p>
                        </div>

                        <div className="ProductModal__result-item-container">
                            <p className="ProductModal__result-item-label">{modal_lang[3][lang]}</p>
                            <p className="ProductModal__result-item-value">{requestData.name}</p>
                        </div>

                        <div className="ProductModal__result-divider"/>
                    </div>

                    <div className="ProductModal__result-container">
                        <p className="ProductModal__result-subheader">{modal_lang[19][lang]}</p>

                        <div className="ProductModal__result-item-container">
                            <p className="ProductModal__result-item-label">{modal_lang[22][lang]}</p>
                            <p className="ProductModal__result-item-value">{requestData.basis}</p>
                        </div>

                        <div className="ProductModal__result-item-container">
                            <p className="ProductModal__result-item-label">{modal_lang[23][lang]}</p>
                            <p className="ProductModal__result-item-value">{requestData.country}</p>
                        </div>

                        <div className="ProductModal__result-item-container">
                            <p className="ProductModal__result-item-label">{modal_lang[24][lang]}</p>
                            <p className="ProductModal__result-item-value">{requestData.station}</p>
                        </div>

                        <div className="ProductModal__result-item-container">
                            <p className="ProductModal__result-item-label">{modal_lang[26][lang]}</p>
                            <p className="ProductModal__result-item-value">{indexLang.lang[requestData.ttype1][lang]}</p>
                        </div>

                        <div className="ProductModal__result-item-container">
                            <p className="ProductModal__result-item-label">{modal_lang[27][lang]}</p>
                            <p className="ProductModal__result-item-value">{indexLang.lang[requestData.ttype2][lang]}</p>
                        </div>

                        <div className="ProductModal__result-item-container">
                            <p className="ProductModal__result-item-label">{modal_lang[28][lang]}</p>
                            <p className="ProductModal__result-item-value">{indexLang.lang[requestData.package][lang]}</p>
                        </div>

                        <div className="ProductModal__result-item-container">
                            <p className="ProductModal__result-item-label">{modal_lang[70][lang]}</p>
                            <p className="ProductModal__result-item-value">{monthlang[requestData.period1.split(" ")[0]][lang]} {requestData.period1.split(" ")[1]} - {monthlang[requestData.period2.split(" ")[0]][lang]} {requestData.period2.split(" ")[1]}</p>
                        </div>

                        <div className="ProductModal__result-divider"/>
                    </div>

                    <div className="ProductModal__result-container">
                        <p className="ProductModal__result-subheader">{deals_lang[12][lang]}</p>

                        <div className="ProductModal__result-item-container">
                            <p className="ProductModal__result-item-label">{modal_lang[9][lang]}</p>
                            <p className="ProductModal__result-item-value">{requestData.volume}</p>
                        </div>

                        {requestData.params.indexes.map((ind, index) => {
                            return(
                                <div className="ProductModal__result-item-container">
                                    <p className="ProductModal__result-item-label">{indexLang.lang !== undefined ? indexLang.lang[ind.name][lang] : ind.name}</p>
                                    <p className="ProductModal__result-item-value">{parseIndexValue(ind)}</p>
                                </div>
                            );
                        })}

                        <div className="ProductModal__result-divider"/>
                    </div>

                    <p className="ProductModal__result-subheader" style={{marginLeft:"32px"}}>{deals_lang[13][lang]}</p>
                    <TextField
                        label={modal_lang[9][lang]}
                        style={{marginLeft:"32px",marginTop:"32px"}}
                        inputWidth="calc(100% - 80px)"
                        placeholder={modal_lang[10][lang]}

                        error={volumeError}
                        errorMessage={modal_lang[32][lang]}

                        value={volume}
                        onChangeText={text => {
                            if(text !== "" && volumeError) {
                                setVolumeError(false);
                            }

                            setVolume(text);
                        }}
                    />

                    <div className="ProductModal__inputs-wrapper">
                        {requestData.params.indexes.map((data, index) => {
                            if(data.format !== "picker") {
                                return(
                                    <TextField
                                        inputWidth={"344px"}
                                        style={index%2 !== 0 ? { marginLeft: 16 } : {}}
                                        key={index}
    
                                        label={data.name + parseFilter(data.filter) + ", " + data.format}
                                        placeholder={profile_lang[62][lang] + data.name.toLowerCase()}
    
                                        value={values[index]}
                                        onChangeText={text => updateValue(index, text)}
                                        error={errors[index]}
                                        errorMessage={modal_lang[32][lang]}
                                        indicatorShown={false}
                                    />
                                );
                            } else {
                                return(
                                    <div className="ProductModal__picker-input" key={index} style={index%2 === 0 ? { marginLeft: 0 } : {}}>
                                        <p className="ProductModal__picker-label">{data.name}</p>
                                        <SliderPickerLarge
                                            items={data.base_values.slice(0, 2)} // ONLY 2 CASES SUPPORTED!
                                            style={{width:"360px"}}
                                            labelStyles={[
                                                {fontWeight:"400"},
                                                {fontWeight:"400"}
                                            ]}
                                            value={values[index]}
                                            onChange={value => updateValue(index, value)}
                                        />
                                    </div>
                                );
                            }
                        })}
                    </div>

                    <div className="SendOfferModal__button-container">
                        <GreyButton
                            text={modal_lang[12][lang]}
                            onClick={toggleClose}
                            style={{
                                width: "100%",
                                height: "40px"
                            }}
                        />

                        <BlueButton
                            text={modal_lang[68][lang]}
                            onClick={() => tryAccept()}
                            style={{
                                width: "100%",
                                height: "40px"
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export function TransactionModal({
    visible,
    onRequestClose,
    transactionData,
    selfID,
    token,
    context,
    lang=1
}) {
    const [displayed, setDisplayed] = useState(false);
    const [isSeller, setIsSeller] = useState(false);

    const [indexLang, setIndexLang] = useState({});

    useEffect(() => {
        if(visible) {
            toggleOpen();
        }

        indexLanguage(function(r) {
            setIndexLang(r);
        });
    }, [visible]);

    async function toggleOpen() {
        await timeout(10);
        setDisplayed(true);
    }

    const toggleClose = async () => {
        setDisplayed(false);
        await timeout(200);
        onRequestClose();
    }

    function parseDate(date) {
        return `${date.slice(8, 10)}.${date.slice(5, 7)}.${date.slice(2, 4)}`
    }

    const [companyName, setCompanyName] = useState("");

    useEffect(() => {
        if(transactionData !== null && token !== "") {
            if(selfID == transactionData.seller) {
                setIsSeller(true);
            } else {
                setIsSeller(false);
            }
            
            companyService.getCompany(transactionData.seller, token, function(response) {
                let data = response.company;
                setCompanyName(data.name);
            });
        }
    }, [transactionData]);

    const [showDetails, setDetailsState] = useState(true);

    function parseIndexValue(index) {
        let str = "";
        if(index.format !== "picker") {
            switch(index.filter) {
                case "from":
                    str += modal_lang[34][lang] += " ";
                    break;
                case "to":
                    str += modal_lang[33][lang] += " ";
                    break;
                default:
                    break;
            }

            str += index.value + " ";
            str += index.format !== "%" && index.format !== "" ? indexLang.lang[index.format][lang] : (index.format);
        } else {
            str += indexLang.lang[index.base_values[index.value]][lang];
        }

        return str;
    }

    function cancelTransaction() {
        if(transactionData.state === "new") {
            transactionService.patchTransactionState(
                transactionData._id,
                "cancelled",
                token,
                function(response) {
                    if(response.state !== undefined) {
                        transactionData.state = "cancelled";
                        toggleClose();
                    }
                }
            );
        }
    }

    function continueTransaction() {
        if(transactionData.state === "new") {
            transactionService.patchTransactionState(
                transactionData._id,
                "active",
                token,
                function(response) {
                    if(response.state !== undefined) {
                        transactionData.state = "active";

                        sendPersonalInformation();
                    }
                }
            );
        }
    }

    function sendPersonalInformation() {
        userService.getUser(token, function(selfUser) {
            let to_name = selfUser.user.first_name;
            let to_email = selfUser.user.email;

            let countercompany = transactionData.seller == selfID ? transactionData.buyer : transactionData.seller;

            companyService.getCompany(countercompany, token, function(company) {
                let cparent = company.company.parent;
                userService.getUserInfo(cparent, token, function(counterUser) {
                    let email = counterUser.email;
                    let phone = counterUser.phone;

                    emailService.sendPersonalInformation(
                        to_name,
                        to_email,
                        email,
                        phone,
                        transactionData._id,
                        "ru",
                        function(response) {
                            console.log(response);
                        }
                    );

                    emailService.sendPersonalInformation(
                        counterUser.first_name,
                        email,
                        to_email,
                        selfUser.user.phone,
                        transactionData._id,
                        "ru",
                        function(response) {
                            console.log(response);
                        }
                    );
                    
                    toggleClose();
                });
            });
        });
    }

    const monthlang = {
        "Январь": [
            "Январь",
            "January",
            "1月"
        ],
        "Февраль": [
            "Февраль",
            "February",
            "2月"
        ],
        "Март": [
            "Март",
            "March",
            "3月"
        ],
        "Апрель": [
            "Апрель",
            "April",
            "4月"
        ],
        "Май": [
            "Май",
            "May",
            "5月"
        ],
        "Июнь": [
            "Июнь",
            "June",
            "6月"
        ],
        "Июль": [
            "Июль",
            "July",
            "7月"
        ],
        "Август": [
            "Август",
            "August",
            "8月"
        ],
        "Сентябрь": [
            "Сентябрь",
            "September",
            "9月"
        ],
        "Октябрь": [
            "Октябрь",
            "October",
            "10月"
        ],
        "Ноябрь": [
            "Ноябрь",
            "November",
            "11月"
        ],
        "Декабрь": [
            "Декабрь",
            "December",
            "12月"
        ]
    };

    if(visible) {
        return(
            <div className="InfoModal__root">
                <div 
                    className={"ProductModal__background"+(displayed ? "" : "-hidden")}
                    onClick={toggleClose}
                />

                <div className={"ProductRequestModal__container"+(displayed ? "" : "-hidden")}>
                    {context === "product-accept" ?
                    <>
                        <div className="ProductModal__progress-container">
                            <div
                                className="ProductModal__progress-indicator"
                                style={{
                                    marginLeft: 17,
                                }}
                            />
                            
                            <div 
                                className="ProductModal__progress-stick"
                            />

                            <div
                                className="ProductModal__progress-indicator"
                            />
                            <p className="ProductModal__progress-title" style={{ width: "max-content", minWidth: "max-content", marginRight: 17 }}>{modal_lang[69][lang]}</p>
                        </div>

                        <div className="ProductModal__body">
                            <p className="SendOfferModal__title">{deals_lang[3][lang]}</p>
                        </div>

                        <div className="TransactionModal__starter-info">
                            <div className="ProductModal__result-item-container" style={{marginLeft:"32px"}}>
                                <p className="ProductModal__result-item-label">{deals_lang[4][lang]}</p>
                                <p className="ProductModal__result-item-value">{parseDate(transactionData.created_at)}</p>
                            </div>
                            
                            <div className="ProductModal__result-item-container" style={{marginLeft:"32px"}}>
                                <p className="ProductModal__result-item-label">{isSeller ? "Покупатель" : "Поставщик"}</p>
                                <p className="ProductModal__result-item-value">{companyName}</p>
                            </div>
                            
                            <div className="ProductModal__result-item-container" style={{marginLeft:"32px"}}>
                                <p className="ProductModal__result-item-label">Страна</p>
                                <p className="ProductModal__result-item-value">{'В разработке'}</p>
                            </div>
                            
                            <div className="ProductModal__result-item-container" style={{marginLeft:"32px",marginBottom:"28px"}}>
                                <p className="ProductModal__result-item-label">Регион</p>
                                <p className="ProductModal__result-item-value">{'В разработке'}</p>
                            </div>
                        </div>

                        <p className="SendOfferModal__title">Заявка {transactionData._id} на {isSeller ? "продажу" : "покупку"} {transactionData.data.name}</p>

                        {showDetails &&
                        <>
                            <div className="ProductModal__result-container" style={{marginBottom:"20px"}}>
                                <p className="ProductModal__result-subheader">{modal_lang[41][lang]}</p>

                                {/*<div className="ProductModal__result-item-container">
                                    <p className="ProductModal__result-item-label">Вид</p>
                                    <p className="ProductModal__result-item-value">{'Сельхоз продукция'}</p>
                                </div>*/}

                                <div className="ProductModal__result-item-container">
                                    <p className="ProductModal__result-item-label">{modal_lang[2][lang]}</p>
                                    <p className="ProductModal__result-item-value">{transactionData.data.category}</p>
                                </div>

                                <div className="ProductModal__result-item-container">
                                    <p className="ProductModal__result-item-label">{modal_lang[3][lang]}</p>
                                    <p className="ProductModal__result-item-value">{transactionData.data.name}</p>
                                </div>
                            </div>

                            <div className="ProductModal__result-container" style={{marginBottom:"20px"}}>
                                <p className="ProductModal__result-subheader">Предложение {isSeller ? "покупателя" : "поставщика"}</p>

                                <div className="ProductModal__result-item-container">
                                    <p className="ProductModal__result-item-label">{modal_lang[9][lang]}</p>
                                    <p className="ProductModal__result-item-value">{isSeller ? transactionData.data.buyer_volume : transactionData.data.seller_volume}</p>
                                </div>

                                {!isSeller && transactionData.data.seller_params.indexes.map((ind, index) => {
                                    return(
                                        <div className="ProductModal__result-item-container">
                                            <p className="ProductModal__result-item-label">{indexLang.lang[ind.name][lang]}</p>
                                            <p className="ProductModal__result-item-value">{parseIndexValue(ind)}</p>
                                        </div>
                                    );
                                })}

                                {isSeller && transactionData.data.buyer_params.indexes.map((ind, index) => {
                                    return(
                                        <div className="ProductModal__result-item-container">
                                            <p className="ProductModal__result-item-label">{indexLang.lang[ind.name][lang]}</p>
                                            <p className="ProductModal__result-item-value">{parseIndexValue(ind)}</p>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="ProductModal__result-container" style={{marginBottom:"20px"}}>
                                <p className="ProductModal__result-subheader">{modal_lang[19][lang]}</p>

                                <div className="ProductModal__result-item-container">
                                    <p className="ProductModal__result-item-label">{modal_lang[22][lang]}</p>
                                    <p className="ProductModal__result-item-value">{transactionData.data.basis}</p>
                                </div>

                                <div className="ProductModal__result-item-container">
                                    <p className="ProductModal__result-item-label">{modal_lang[23][lang]}</p>
                                    <p className="ProductModal__result-item-value">{transactionData.data.country}</p>
                                </div>

                                <div className="ProductModal__result-item-container">
                                    <p className="ProductModal__result-item-label">{modal_lang[24][lang]}</p>
                                    <p className="ProductModal__result-item-value">{transactionData.data.station}</p>
                                </div>

                                <div className="ProductModal__result-item-container">
                                    <p className="ProductModal__result-item-label">{modal_lang[26][lang]}</p>
                                    <p className="ProductModal__result-item-value">{indexLang.lang[transactionData.data.ttype1][lang]}</p>
                                </div>

                                <div className="ProductModal__result-item-container">
                                    <p className="ProductModal__result-item-label">{modal_lang[27][lang]}</p>
                                    <p className="ProductModal__result-item-value">{indexLang.lang[transactionData.data.ttype2][lang]}</p>
                                </div>

                                <div className="ProductModal__result-item-container">
                                    <p className="ProductModal__result-item-label">{modal_lang[28][lang]}</p>
                                    <p className="ProductModal__result-item-value">{indexLang.lang[transactionData.data.package][lang]}</p>
                                </div>

                                <div className="ProductModal__result-item-container">
                                    <p className="ProductModal__result-item-label">{modal_lang[70][lang]}</p>
                                    <p className="ProductModal__result-item-value">{monthlang[transactionData.data.period1.split(" ")[0]][lang]} {transactionData.data.period1.split(" ")[1]} - {monthlang[transactionData.data.period2.split(" ")[0]][lang]} {transactionData.data.period2.split(" ")[1]}</p>
                                </div>
                            </div>
                        </>
                        }

                        <div className="SendOfferModal__button-container">
                            <GreyButton
                                text="Отклонить"
                                onClick={() => {cancelTransaction()}}
                                style={{
                                    width: "100%",
                                    height: "40px"
                                }}
                            />

                            <BlueButton
                                text="Принять"
                                onClick={() => {continueTransaction()}}
                                style={{
                                    width: "100%",
                                    height: "40px"
                                }}
                            />
                        </div>
                    </>    
                    :(context === "none" ? 
                    <>
                        <div className="ProductModal__progress-container">
                            <div
                                className="ProductModal__progress-indicator"
                                style={{
                                    marginLeft: 17,
                                }}
                            />
                            
                            <div 
                                className="ProductModal__progress-stick"
                            />

                            <div
                                className="ProductModal__progress-indicator"
                            />
                            <p className="ProductModal__progress-title" style={{ width: "max-content", minWidth: "max-content", marginRight: 17 }}>Ожидание отклика</p>
                        </div>

                        <div className="ProductModal__body" style={{marginBottom:"32px"}}>
                            <p className="SendOfferModal__title">{deals_lang[7][lang]}</p>
                        </div>
                    </>
                    :(context === "active"? 
                    <>
                        <div className="ProductModal__progress-container">
                            <div
                                className="ProductModal__progress-indicator"
                                style={{
                                    marginLeft: 17,
                                }}
                            />
                            
                            <div 
                                className="ProductModal__progress-stick"
                            />

                            <div
                                className="ProductModal__progress-indicator"
                            />
                            <p className="ProductModal__progress-title" style={{ width: "max-content", minWidth: "max-content", marginRight: 17 }}>{deals_lang[1][lang]}</p>
                        </div>

                        <div className="ProductModal__body" style={{marginBottom:"32px"}}>
                            <p className="SendOfferModal__title" style={{marginBottom:"16px"}}>{deals_lang[1][lang]}</p>
                                <p className="SignUpModal__body-pwd-conf-text" style={{marginLeft:"32px"}}>{deals_lang[2][lang]}</p>
                        </div>
                    </>
                    :(context === "cancelled" ? 
                    <>
                        <div className="ProductModal__progress-container">
                            <div
                                className="ProductModal__progress-indicator"
                                style={{
                                    marginLeft: 17,
                                }}
                            />
                            
                            <div 
                                className="ProductModal__progress-stick"
                            />

                            <div
                                className="ProductModal__progress-indicator"
                            />
                            <p className="ProductModal__progress-title" style={{ width: "max-content", minWidth: "max-content", marginRight: 17 }}>Заявка отклонена</p>
                        </div>

                        <div className="ProductModal__body" style={{marginBottom:"32px"}}>
                            <p className="SendOfferModal__title" style={{marginBottom:"16px"}}>{deals_lang[8][lang]}</p>
                                <p className="SignUpModal__body-pwd-conf-text" style={{marginLeft:"32px"}}>{selfID === transactionData.buyer ? deals_lang[10][lang] : deals_lang[11][lang]}</p>
                        </div>
                    </>
                     : <></>)))}
                </div>
            </div>
        );
    }
}

export function CompanyNotProvidedModal({
    visible,
    alarmType,
    onRequestClose,
    onAccept,
    lang=1
}) {
    const [displayed, setDisplayed] = useState(false);
    useEffect(() => {
        if(visible === true) {
            toggleOpen();
        }
    }, [visible]);

    const toggleClose = async () => {
        setDisplayed(false);
        await timeout(200);
        onRequestClose();
    }

    async function toggleOpen() {
        await timeout(10);
        setDisplayed(true);
    }

    if(visible) {
        return(
            <div className="CompanyNotProvided__root">
                <div 
                    className={"CompanyNotProvided__background"+(displayed ? "" : "-hidden")}
                    onClick={toggleClose}
                />

                <div className={"CompanyNotProvided__container"+(displayed ? "" : "-hidden")}>
                    {alarmType === "not-provided" ?
                    <>
                        <p className="InfoModal__header">{modal_lang[47][lang]}</p>
                        <p className="CompanyNotProvided__content">{modal_lang[48][lang]}</p>

                        <div className="SendOfferModal__button-container">
                            <GreyButton
                                text={modal_lang[5][lang]}
                                onClick={toggleClose}
                                style={{
                                    width: "100%",
                                    height: "32px"
                                }}
                            />

                            <Link to="/account/company" style={{textDecoration:'none'}}>
                                <BlueButton
                                    text={modal_lang[49][lang]}
                                    style={{
                                        width: "100%",
                                        height: "32px"
                                    }}
                                />
                            </Link>
                        </div>
                    </>    
                    :(alarmType === "not-allowed" ? 
                    <>
                        <p className="InfoModal__header">{modal_lang[51][lang]}</p>
                        <p className="CompanyNotProvided__content">{modal_lang[52][lang]}</p>

                        <div className="SendOfferModal__button-container">
                            <GreyButton
                                text={modal_lang[5][lang]}
                                onClick={toggleClose}
                                style={{
                                    width: "100%",
                                    height: "32px"
                                }}
                            />

                            <Link to="/account/company" style={{textDecoration:'none'}}>
                                <BlueButton
                                    text={modal_lang[50][lang]}
                                    style={{
                                        width: "100%",
                                        height: "32px"
                                    }}
                                />
                            </Link>
                        </div>
                    </>
                    :
                    <div/>
                    )}
                </div>
            </div>
        );
    }
}

export function RestorePasswordModal({
    visible,
    onRequestClose,
    lang=1
}) {
    const [displayed, setDisplayed] = useState(false);
    const [stage, setStage] = useState(0);

    useEffect(() => {
        if(visible) {
            toggleOpen();
        }
    }, [visible]);

    const toggleClose = async () => {
        setDisplayed(false);
        await timeout(200);

        setStage(0);
        setEmail("");
        setCode("");
        setCorrectCode("");
        setEmailError(false);
        setEmailMsg("");
        setCodeError(false);
        setPassword1("");
        setPassword2("");
        setPwd1Error(false);
        setPwd2Error(false);

        onRequestClose();
    }

    async function toggleOpen() {
        await timeout(10);
        setDisplayed(true);
    }

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [emailMsg, setEmailMsg] = useState("");

    const [correctCode, setCorrectCode] = useState("");
    const [code, setCode] = useState("");
    const [codeError, setCodeError] = useState(false);

    const [password1, setPassword1] = useState("");
    const [pwd1Error, setPwd1Error] = useState(false);

    const [password2, setPassword2] = useState("");
    const [pwd2Error, setPwd2Error] = useState(false);

    function tryContinue() {
        if(email === "") {
            setEmailMsg(modal_lang[32][lang]);
            return;
        } else {
            userService.checkEmailExistance(email, function(exist) {
                if(exist) {
                    let vcode = Math.round(Math.random() * (999999 - 100000) + 100000);
                    let codestr = vcode.toString();

                    setCorrectCode(codestr);

                    emailService.sendPwdResetCode(email, codestr, "ru", function(response) {
                        if(response) {
                            setStage(1);
                        } else {
                            setEmailMsg(modal_lang[53][lang]);
                        }
                    });
                } else {
                    setEmailMsg(modal_lang[54][lang]);
                }
            });
        }
    }

    function verifyCode() {
        if(code != correctCode) {
            setCodeError(true);
            setCode("");
        } else {
            setStage(2);
        }
    }

    function acceptPwd() {
        if(password1.length < 10) {
            setPwd1Error(true);
            return;
        }

        if(password1 != password2) {
            setPwd2Error(true);
            return;
        }

        userService.updatePassword(email, password2, function(response) {
            if(response) {
                toggleClose();
            } else {
                setPwd1Error(true);
                return;
            }
        });
    }

    useEffect(() => {
        if(code !== "" && codeError) {
            setCodeError(false);
        }
    }, [code]);

    useEffect(() => {
        if(emailMsg !== "") {
            setEmailError(true);
        } else {
            if(emailError) {
                setEmailError(false);
            }
        }
    }, [emailMsg]);

    useEffect(() => {
        if(email !== "") {
            setEmailMsg("");
        }
    }, [email]);

    useEffect(() => {
        if(password1 !== "" && pwd1Error) {
            setPwd1Error(false);
            setPwd2Error(false);
        }
    }, [password1]);

    useEffect(() => {
        if(password2 !== "" && pwd2Error) {
            setPwd1Error(false);
            setPwd2Error(false);
        }
    }, [password2]);

    if(visible) {
        return(
            <div className="SignUpModal__root">
                <div 
                    className={displayed ? "SignUpModal__background" : "SignUpModal__background-transparent"}
                    onClick={toggleClose}
                />

                <div className={displayed ? "SignUpModal__container" : "SignUpModal__container-hidden"}>
                    {stage !== 2 &&
                    <div className="SignUpModal__stage-header">
                        <div className="SignUpModal__stage-item">
                            <div className="SignUpModal__stage-item-check">
                                {stage === 1 ? 
                                <img src={doneIcon} className="SignUpModal__stage-item-check-icon" /> 
                                : <div/>}
                            </div>

                            <div className="SignUpModal__stage-item-text-wrapper">
                                <p className="SignUpModal__stage-item-label">{auth_lang[8][lang]}</p>
                                <p className="SignUpModal__stage-item-desc">Ввод электронной почты</p>
                            </div>
                        </div>

                        <div className={"SignUpModal__stage-item"+(stage === 0 ? "-inactive" : "")}>
                            <div className={"SignUpModal__stage-item-check"+(stage === 0 ? "-inactive" : "")}>

                            </div>

                            <div className="SignUpModal__stage-item-text-wrapper">
                                <p className="SignUpModal__stage-item-label">{auth_lang[16][lang]}</p>
                                <p className="SignUpModal__stage-item-desc">{modal_lang[39][lang]}</p>
                            </div>
                        </div>
                    </div>
                    }

                    {stage === 0 ?
                    <>
                        <div className="SignUpModal__body">
                            <p className="SignUpModal__body-pwd-conf-header">{auth_lang[10][lang]}</p>
                            <p className="SignUpModal__body-pwd-conf-text">{auth_lang[11][lang]}</p>

                            <TextField
                                label={auth_lang[12][lang]}
                                value={email}
                                onChangeText={setEmail}
                                placeholder={auth_lang[13][lang]}
                                inputWidth="calc(100% - 80px)"
                                style={{marginTop:"32px"}}

                                error={emailError}
                                errorMessage={emailMsg}
                            />

                            <div className="SignUpModal__body-buttons-container">
                                <GreyButton
                                    text={modal_lang[5][lang]}
                                    style={{
                                        width: "100%",
                                        marginTop: "16px",
                                        marginRight: "16px"
                                    }}
                                    onClick={onRequestClose}
                                />

                                <BlueButton
                                    text={auth_lang[15][lang]}
                                    style={{
                                        width: "100%",
                                        marginTop: "16px"
                                    }}
                                    onClick={() => tryContinue()}
                                />
                            </div>
                        </div>
                    </>
                    :
                    (stage === 1 ? 
                    <>
                        <div className="SignUpModal__body">
                            <p className="SignUpModal__body-pwd-conf-header">{auth_lang[17][lang]}</p>
                            <p className="SignUpModal__body-pwd-conf-text">{auth_lang[42][lang]}“{email}”{auth_lang[43][lang]}</p>

                            <TextField
                                label={auth_lang[21][lang]}
                                value={code}
                                onChangeText={setCode}
                                placeholder={auth_lang[22][lang]}
                                maxLength={6}
                                inputWidth="calc(100% - 80px)"
                                style={{marginTop:"32px"}}

                                error={codeError}
                                errorMessage={auth_lang[23][lang]}
                            />

                            <div className="SignUpModal__body-buttons-container">
                                <GreyButton
                                    text={auth_lang[24][lang]}
                                    style={{
                                        width: "100%",
                                        marginTop: "16px",
                                        marginRight: "16px"
                                    }}
                                    onClick={() => setStage(0)}
                                />

                                <BlueButton
                                    text={auth_lang[25][lang]}
                                    style={{
                                        width: "100%",
                                        marginTop: "16px"
                                    }}
                                    onClick={() => verifyCode()}
                                />
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="SignUpModal__body">
                            <p className="SignUpModal__body-pwd-conf-header">{auth_lang[10][lang]}</p>

                            <SecureTextField
                                label={modal_lang[55][lang]}
                                value={password1}
                                onChangeText={setPassword1}
                                placeholder={auth_lang[31][lang]}
                                inputWidth="calc(100% - 80px)"
                                style={{marginTop:"32px"}}

                                error={pwd1Error}
                                errorMessage={auth_lang[32][lang]}
                            />

                            <SecureTextField
                                label={modal_lang[56][lang]}
                                value={password2}
                                onChangeText={setPassword2}
                                placeholder="Введите пароль еще раз"
                                inputWidth="calc(100% - 80px)"
                                style={{marginTop:"32px"}}

                                error={pwd2Error}
                                errorMessage={modal_lang[57][lang]}
                            />

                            <div className="SignUpModal__body-buttons-container">
                                <GreyButton
                                    text={auth_lang[14][lang]}
                                    style={{
                                        width: "100%",
                                        marginTop: "16px",
                                        marginRight: "16px"
                                    }}
                                    onClick={toggleClose}
                                />

                                <BlueButton
                                    text={profile_lang[20][lang]}
                                    style={{
                                        width: "100%",
                                        marginTop: "16px"
                                    }}
                                    onClick={() => acceptPwd()}
                                />
                            </div>
                        </div>
                    </>
                    )
                    }
                </div>
            </div>
        );
    }
}

export function SendOfferModalFromBuyer({
    visible,
    requestData,
    onRequestClose,
    onAccept,
    token,
    lang=1
}) {
    const [displayed, setDisplayed] = useState(false);
    const [stage, setStage] = useState(0);

    const [volume, setVolume] = useState("");
    const [volumeError, setVolumeError] = useState(false);

    const [values, setValues] = useState([]);
    const [errors, setErrors] = useState([]);

    const [indexLang, setIndexLang] = useState({});

    useEffect(() => {
        if(visible) {
            toggleOpen();
        }
        
        indexLanguage(function(r) {
            setIndexLang(r);
        });
    }, [visible]);

    useEffect(() => {
        let periods = getPeriodItems();
        setPeriodItems(periods);
    }, []);

    async function toggleOpen() {
        await timeout(10);
        setDisplayed(true);
    }

    useEffect(() => {
        if(requestData !== null) {
            let _v = [];
            let _e = [];
            for(const _d of requestData.params.indexes) {
                if(_d.format !== "picker") {
                    _v.push("");
                } else {
                    _v.push(0);
                }
                _e.push(false);
            }

            setValues(_v);
            setErrors(_e);
        }
    }, [requestData]);

    function resetValues() {
        setValues([]);
        setVolume("");
    }

    const toggleClose = async () => {
        setDisplayed(false);
        resetValues();
        await timeout(200);
        onRequestClose();
    }

    function parseIndexValue(index) {
        let str = "";
        if(index.format !== "picker") {
            switch(index.filter) {
                case "from":
                    str += modal_lang[34][lang] += " ";
                    break;
                case "to":
                    str += modal_lang[33][lang] += " ";
                    break;
                default:
                    break;
            }

            str += index.value + " ";
            str += index.format !== "%" && index.format !== "" ? indexLang.lang[index.format][lang] : (index.format);
        } else {
            str += indexLang.lang[index.base_values[index.value]][lang];
        }

        return str;
    }

    function parseFilter(filter) {
        switch(filter) {
            case 'from':
                return modal_lang[34][lang];
            case 'to':
                return modal_lang[33][lang];
            default:
                return "";
        }
    }

    function updateValue(index, value) {
        let _v = values;
        _v[index] = value;
        setValues(_v);

        if(value !== "" && errors[index]) {
            updateError(index, false);
        } else {
            setTrigger(1-trigger);
        }
    }

    function updateError(index, state) {
        let _e = errors;
        _e[index] = state;
        setErrors(_e);

        setTrigger(1-trigger);
    }

    function tryAccept() {
        let flag = true;

        if(volume === "") {
            setVolumeError(true);
            flag = false;
        }

        let index = 0;
        for(const _v of values) {
            if(_v === "") {
                updateError(index, true);
                flag = false;
            }

            index += 1;
        }

        if(flag) {
            tryCreateTransaction();
        } else {
            return;
        }
    }

    function tryCreateTransaction() {
        let buyerIndexes = [];

        let index = 0;
        for(const ind of requestData.params.indexes) {
            buyerIndexes.push({
                name: ind.name,
                format: ind.format,
                filter: ind.filter,
                base_values: ind.base_values,
                priority: ind.priority,
                editable: ind.editable,
                value: values[index]
            });

            index += 1;
        }

        let buyerData = Object.assign({"buyer_volume": volume}, {"buyer_params":{"indexes":buyerIndexes}});
        let _transactionData = Object.assign(requestData, buyerData);

        let periodAliases = getPeriodAliases();

        let transactionData = Object.assign(
            _transactionData,
            {
                "basis": basisItems[basis],
                "country": selectedCountry,
                "station": station,
                "ttype1": transportType1Items[lang][transportType1],
                "ttype2": transportType2Items[lang][transportType2],
                "package": productPackageItems[lang][productPackage],
                "period1": periodAliases[periodFrom],
                "period2": periodAliases[periodTo]
                //"period1": periodItems[periodFrom],
                //"period2": periodItems[periodTo],
            }
        );

        companyService.getCompaniesList(token, function(response) {
            let selfID = response.companies[0].pk;
            let partnerID = requestData.parent_company;

            transactionService.addTransaction(
                selfID,
                partnerID,
                selfID,
                transactionData,
                token,
                function(response) {
                    console.log(response);
                    onRequestClose();
                }
            );
        })
    }

    const [trigger, setTrigger] = useState(0);

    function resetValues() {
        setStage(0);
        setValues([]);
        setErrors([]);
        setBasis(-1);
        setSelectedCountry("");
        setCountrySearchValue("");
        setStation("");
        setTransportType1(-1);
        setTransportType2(-1);
        setProductPackage(-1);
        setPeriodFrom(-1);
        setPeriodTo(-1);
    }
    
    // stage 2 data
    const [basis, setBasis] = useState(-1);
    const [basisItems, setBasisItems] = useState([
        "FCA",
        "DAP",
        "FOB", 
        "FAS", 
        "CIF",
        "CFR", 
        "CPT", 
        "CIP"
    ]);

    const [selectedCountry, setSelectedCountry] = useState("");
    const [countrySearchValue, setCountrySearchValue] = useState("");
    const [countrySearchState, setCountrySearchState] = useState(false);
    const [countryItems, setCountryItems] = useState([]);

    useEffect(() => {
        if(selectedCountry !== countrySearchValue) {
            setSelectedCountry("");

            if(countrySearchValue !== "" && countrySearchValue.length > 1) {
                /*let url = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/country`;
                axios.post(
                    url,
                    { query: countrySearchValue },
                    { 
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Token 11f0f146144bfa7088a1dbc10e3186e809ce48f2"
                        },
                        withCredentials: false
                    }
                ).then(response => {
                    //console.log(response.data.suggestions);
                    if(response.data.suggestions !== undefined) {
                        let countries = [];
                        for(const cntr of response.data.suggestions) {
                            countries.push(cntr.value);
                        }
    
                        setCountryItems(countries);
                    }
                });*/

                axios.get(
                    `https://restcountries.com/v3.1/name/${countrySearchValue}`,
                    {headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                    }}
                ).then(response => {
                    console.log(response);
                    if(response.data.length > 0) {
                        let countries = [];
                        for(const cntr of response.data) {
                            if(lang === 0) {
                                countries.push(cntr.name.nativeName.rus.common);
                            } else {
                                countries.push(cntr.name.common);
                            }
                        }

                        setCountryItems(countries);
                    }
                });
            } else {
                setCountryItems([]);
            }
        }
    }, [countrySearchValue]);

    const [station, setStation] = useState("");

    const [transportType1, setTransportType1] = useState(-1);
    const [transportType1Items, setTransportType1Items] = useState([
        ["Контейнерная", "Неконтейнерная"],
        ["Container", "Non-Container"],
        ["集装箱", "无集装箱"]
    ]);

    const [transportType2, setTransportType2] = useState(-1);
    const [transportType2Items, setTransportType2Items] = useState([
        ["Железнодорожная", "Автомобильная", "Морская"],
        ["Railway", "Car", "Sea"],
        ["铁路运输", "汽车运输", "海洋运输"]
    ]);

    const oilPackageItems = [["Налив", "Евробочки", "Бутылки"], ["Налив", "Евробочки", "Бутылки"], ["Налив", "Евробочки", "Бутылки"]];
    const defaultPackageItems = [["Насыпь", "Мешки", "Биг-бэги"], ["Embankment", "Bags", "Big bags"], ["散装", "麻袋", "吨袋"]];

    const [productPackage, setProductPackage] = useState(-1);
    const [productPackageItems, setProductPackageItems] = useState([]);
    useEffect(() => {
        if(requestData !== null && requestData !== undefined) {
            if(requestData.category === "Растительные масла") {
                setProductPackageItems(oilPackageItems);
            } else {
                setProductPackageItems(defaultPackageItems);
            }

            translateCatAndItem(requestData.name, function(r) {
                console.log(r);
                setTCat(r.catLang[lang]);
                setTName(r.nameLang[lang]);
            });
        }

        
    }, [requestData]);

    const [tCategory, setTCat] = useState("");
    const [tName, setTName] = useState("");

    const [periodFrom, setPeriodFrom] = useState(-1);
    const [periodTo, setPeriodTo] = useState(-1);
    const [periodItems, setPeriodItems] = useState([]);

    const [stage2Errors, setStage2Errors] = useState([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
    ]);

    function getPeriodItems() {
        let periods = [];
        let date = new Date();
        let mon = date.getMonth();
        let year = date.getFullYear();

        let monthLangDict = [
            [
                "Январь",
                "January",
                "1月"
            ],
            [
                "Февраль",
                "February",
                "2月"
            ],
            [
                "Март",
                "March",
                "3月"
            ],
            [
                "Апрель",
                "April",
                "4月"
            ],
            [
                "Май",
                "May",
                "5月"
            ],
            [
                "Июнь",
                "June",
                "6月"
            ],
            [
                "Июль",
                "July",
                "7月"
            ],
            [
                "Август",
                "August",
                "8月"
            ],
            [
                "Сентябрь",
                "September",
                "9月"
            ],
            [
                "Октябрь",
                "October",
                "10月"
            ],
            [
                "Ноябрь",
                "November",
                "11月"
            ],
            [
                "Декабрь",
                "December",
                "12月"
            ]
        ];

        for(let j = mon; j < 12; j++) {
            periods.push(monthLangDict[j][lang] + " " + year);
        }

        for(let i = year+1; i < 2030; i++) {
            for(let j = 0; j < 12; j++) {
                periods.push(monthLangDict[j][lang] + " " + i);
            }
        }

        for(let j = 0; j < mon; j++) {
            periods.push(monthLangDict[j][lang] + " 2030");
        }

        return periods;
    }

    function getPeriodAliases() {
        let monthDict = [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь"
        ];

        let periodAl = [];

        let date = new Date();
        let mon = date.getMonth();
        let year = date.getFullYear();

        for(let j = mon; j < 12; j++) {
            periodAl.push(monthDict[j] + " " + year);
        }

        for(let i = year+1; i < 2030; i++) {
            for(let j = 0; j < 12; j++) {
                periodAl.push(monthDict[j] + " " + i);
            }
        }

        for(let j = 0; j < mon; j++) {
            periodAl.push(monthDict[j] + " 2030");
        }

        return periodAl;
    }

    const countryRef = useOutsideClick(() => setCountrySearchState(false));

    if(visible) {
        return(
            <div className="InfoModal__root">
                <div 
                    className={"ProductModal__background"+(displayed ? "" : "-hidden")}
                    onClick={toggleClose}
                />

                <div className={"ProductRequestModal__container"+(displayed ? "" : "-hidden")}>
                    <div className="ProductModal__progress-container">
                        <div
                            className="ProductModal__progress-indicator-active"
                            style={{
                                marginLeft: 17,
                            }}
                        >
                            {stage>0?<img src={doneIcon} className="InfoModal__progress-icon" />:<div/>}
                        </div>
                        {stage === 0 && <p className="ProductModal__progress-title" style={{ width: "max-content", minWidth: "max-content" }}>{modal_lang[69][lang]}</p>}
                        
                        <div 
                            className="ProductModal__progress-stick"
                            style={stage>0?{backgroundColor:"#0F62FE"}:{}}
                        />

                        <div
                            className={"ProductModal__progress-indicator"+(stage>2?"-active":"")}
                            style={stage !== 3 ? {marginRight: 17} : {}}
                        />
                        {stage === 1 && <p className="ProductModal__progress-title" style={{ width: 112, minWidth: 112, marginRight: 17 }}>{modal_lang[39][lang]}</p>}
                    </div>

                    <div className="ProductModal__body">
                        <p className="SendOfferModal__title">{modal_lang[69][lang]}</p>
                    </div>

                    <div className="ProductModal__result-container">
                        <p className="ProductModal__result-subheader">{modal_lang[41][lang]}</p>

                        {/*<div className="ProductModal__result-item-container">
                            <p className="ProductModal__result-item-label">Вид</p>
                            <p className="ProductModal__result-item-value">{'Сельхоз продукция'}</p>
                        </div>*/}

                        <div className="ProductModal__result-item-container">
                            <p className="ProductModal__result-item-label">{modal_lang[2][lang]}</p>
                            <p className="ProductModal__result-item-value">{tCategory}</p>
                        </div>

                        <div className="ProductModal__result-item-container">
                            <p className="ProductModal__result-item-label">{modal_lang[3][lang]}</p>
                            <p className="ProductModal__result-item-value">{tName}</p>
                        </div>

                        <div className="ProductModal__result-divider"/>
                    </div>

                    

                    <div className="ProductModal__result-container">
                        <p className="ProductModal__result-subheader">{modal_lang[59][lang]}</p>

                        <div className="ProductModal__result-item-container">
                            <p className="ProductModal__result-item-label">{modal_lang[9][lang]}</p>
                            <p className="ProductModal__result-item-value">{requestData.volume}</p>
                        </div>

                        {requestData.params.indexes.map((ind, index) => {
                            return(
                                <div className="ProductModal__result-item-container">
                                    <p className="ProductModal__result-item-label">{indexLang.lang !== undefined ? indexLang.lang[ind.name][lang] : ind.name}</p>
                                    <p className="ProductModal__result-item-value">{parseIndexValue(ind)}</p>
                                </div>
                            );
                        })}

                        <div className="ProductModal__result-divider"/>
                    </div>

                    <p className="ProductModal__result-subheader" style={{marginLeft:"32px"}}>{modal_lang[60][lang]}</p>
                    <TextField
                        label={modal_lang[9][lang]}
                        style={{marginLeft:"32px",marginTop:"32px"}}
                        inputWidth="calc(100% - 80px)"
                        placeholder={modal_lang[10][lang]}

                        error={volumeError}
                        errorMessage={modal_lang[32][lang]}

                        value={volume}
                        onChangeText={text => {
                            if(text !== "" && volumeError) {
                                setVolumeError(false);
                            }

                            setVolume(text);
                        }}
                    />

                    <div className="ProductModal__inputs-wrapper">
                        {requestData.params.indexes.map((data, index) => {
                            if(data.format !== "picker") {
                                return(
                                    <TextField
                                        inputWidth={"344px"}
                                        style={index%2 !== 0 ? { marginLeft: 16 } : {}}
                                        key={index}
    
                                        label={indexLang.lang[data.name][lang] + parseFilter(data.filter) + ", " + data.format}
                                        placeholder={modal_lang[11][lang] + indexLang.lang[data.name][lang].toLowerCase()}
    
                                        value={values[index]}
                                        onChangeText={text => updateValue(index, text)}
                                        error={errors[index]}
                                        errorMessage={modal_lang[32][lang]}
                                        indicatorShown={false}
                                    />
                                );
                            } else {
                                return(
                                    <div className="ProductModal__picker-input" key={index} style={index%2 === 0 ? { marginLeft: 0 } : {}}>
                                        <p className="ProductModal__picker-label">{indexLang.lang[data.name][lang]}</p>
                                        <SliderPickerLarge
                                            items={[indexLang.lang[data.base_values[0]][lang], indexLang.lang[data.base_values[1]][lang]]} // ONLY 2 CASES SUPPORTED!
                                            style={{width:"360px"}}
                                            labelStyles={[
                                                {fontWeight:"400"},
                                                {fontWeight:"400"}
                                            ]}
                                            value={values[index]}
                                            onChange={value => updateValue(index, value)}
                                        />
                                    </div>
                                );
                            }
                        })}
                    </div>

                    <p className="ProductModal__body-title">{modal_lang[20][lang]}</p>
                                
                                <p className="ProductRequestModal__picker-subtitle">{modal_lang[21][lang]}</p>
                                <div className="ProductRequestModal__picker-wrapper">
                                    <SquareDropdownPicker
                                        placeholder={modal_lang[22][lang]}
                                        value={basis}
                                        items={basisItems}
                                        onChange={(value) => {
                                            setBasis(value);
                                        }}

                                        style={{
                                            borderRight: "1px solid #E0E0E0"
                                        }}
                                        textStyle={{
                                            marginLeft: 0
                                        }}
                                    />

                                    <div 
                                        className="ProductRequestModal__station-input-container" 
                                        style={{borderRight: "1px solid #E0E0E0"}}
                                        onClick={() => setCountrySearchState(!countrySearchState)}
                                        ref={countryRef}
                                    >
                                        <input
                                            value={countrySearchValue}
                                            onChange={event => setCountrySearchValue(event.target.value)}
                                            className="ProductRequestModal__station-input"
                                            placeholder={modal_lang[23][lang]}
                                        />

                                        {countrySearchState &&
                                        <div className="ProductRequestModal__country-results-container">
                                            <div className="ProductRequestModal__country-results-wrapper">
                                            {
                                                countryItems.map((country, index) => {
                                                    return(
                                                        <div 
                                                            className="ProductRequestModal__country-result-item" 
                                                            key={index}
                                                            onClick={() => {
                                                                setSelectedCountry(country);
                                                                setCountrySearchValue(country);
                                                            }}
                                                        >
                                                            <p className="ProductRequestModal__country-result-item-label">{country}</p>
                                                        </div>
                                                    );
                                                })
                                            }
                                            </div>
                                        </div>
                                        }
                                    </div>

                                    <div className="ProductRequestModal__station-input-container">
                                        <input
                                            value={station}
                                            onChange={event => setStation(event.target.value)}
                                            className="ProductRequestModal__station-input"
                                            placeholder={modal_lang[24][lang]}
                                        />
                                    </div>
                                </div>

                                <p className="ProductRequestModal__picker-subtitle" style={{marginTop:"32px"}}>{modal_lang[25][lang]}</p>
                                <div className="ProductRequestModal__picker-wrapper">
                                    <SquareDropdownPicker
                                        placeholder={modal_lang[26][lang]}
                                        value={transportType1}
                                        items={transportType1Items[lang]}
                                        onChange={(value) => {
                                            setTransportType1(value);
                                        }}

                                        style={{
                                            borderRight: "1px solid #E0E0E0"
                                        }}
                                        textStyle={{
                                            marginLeft: 0
                                        }}
                                    />

                                    <SquareDropdownPicker
                                        placeholder={modal_lang[27][lang]}
                                        value={transportType2}
                                        items={transportType2Items[lang]}
                                        onChange={(value) => {
                                            setTransportType2(value);
                                        }}

                                        style={{
                                            borderRight: "1px solid #E0E0E0"
                                        }}
                                    />

                                    <SquareDropdownPicker
                                        placeholder={modal_lang[28][lang]}
                                        value={productPackage}
                                        items={productPackageItems[lang]}
                                        onChange={(value) => {
                                            setProductPackage(value);
                                        }}
                                    />
                                </div>

                                <p className="ProductRequestModal__picker-subtitle" style={{marginTop:"32px"}}>{modal_lang[29][lang]}</p>
                                <div className="ProductRequestModal__picker-wrapper">
                                    <SquareDropdownPicker
                                        placeholder={modal_lang[30][lang]}
                                        value={periodFrom}
                                        items={periodItems}
                                        onChange={(value) => {
                                            if(value > periodTo && periodTo !== -1) {
                                                let err2 = stage2Errors;
                                                err2[6] = true;
                                                setStage2Errors(err2);
                                                setPeriodFrom(-1);
                                            } else {
                                                setPeriodFrom(value);
                                                if(stage2Errors[6]) {
                                                    let err2 = stage2Errors;
                                                    err2[6] = false;
                                                    setStage2Errors(err2);
                                                    setTrigger(1-trigger);
                                                }
                                            }
                                        }}
                                        error={stage2Errors[6]}

                                        style={{
                                            borderRight: "1px solid #E0E0E0"
                                        }}
                                        textStyle={{
                                            marginLeft: 0
                                        }}
                                    />

                                    <SquareDropdownPicker
                                        placeholder={modal_lang[31][lang]}
                                        value={periodTo}
                                        items={periodItems}
                                        onChange={(value) => {
                                            if(value < periodFrom) {
                                                let err2 = stage2Errors;
                                                err2[7] = true;
                                                setStage2Errors(err2);
                                                setPeriodTo(-1);
                                            } else {
                                                setPeriodTo(value);
                                                if(stage2Errors[7]) {
                                                    let err2 = stage2Errors;
                                                    err2[7] = false;
                                                    setStage2Errors(err2);
                                                    setTrigger(1-trigger);
                                                }
                                            }
                                        }}
                                        error={stage2Errors[7]}
                                    />
                                </div>

                    <div className="SendOfferModal__button-container">
                        <GreyButton
                            text={modal_lang[12][lang]}
                            onClick={toggleClose}
                            style={{
                                width: "100%",
                                height: "40px"
                            }}
                        />

                        <BlueButton
                            text={modal_lang[61][lang]}
                            onClick={() => tryAccept()}
                            style={{
                                width: "100%",
                                height: "40px"
                            }}
                            disabled={!(basis !== -1 && selectedCountry !== "" && station !== "" && transportType1 !== -1 && transportType2 !== -1 && productPackage !== -1 && periodFrom !== -1 && periodTo !== -1 && !values.includes(""))}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export function EditProductModal({
    visible,
    onRequestClose,
    onComplete,
    initialData,
    token,
    lang=1
}) {
    const [displayed, setDisplayed] = useState(false);
    const [stage, setStage] = useState(3);
    const [indexesData, setIndexesData] = useState([]);

    const [verifying, setVerifying] = useState(true);
    const [trigger, setTrigger] = useState(0);

    // stage 2 data
    const [selectedStock, setSelectedStock] = useState(-1);
    const [stockSelectorVisible, setStockSelectorState] = useState(false);
    const [stockError, setStockError] = useState(false);
    const [stocksData, setStocksData] = useState([]);

    function resetValues() {
        setIndexesData([]);
        setSelectedStock(-1);
        setStocksData([]);
    }

    useEffect(() => {
        if(initialData !== null && initialData !== undefined) {
            setIndexesData(initialData.params.indexes);
            setProductVolume(initialData.volume);
            processIndexes();
            getStocks();
        }
    }, [initialData]);

    async function processIndexes() {
        let _values = [];
        let _errors = [];

        for(const ind of initialData.params.indexes) {
            _values.push(ind.value);
            _errors.push(false);
        }

        setValues(_values);
        setErrors(_errors);
    }

    // stage 1
    const [productVolume, setProductVolume] = useState("");
    const [values, setValues] = useState(["", "", "", ""]);

    const [volError, setVolError] = useState(false);
    const [errors, setErrors] = useState([false, false, false, false]);

    const updateValue = (index, value) => {
        let val_old = values;
        val_old[index] = value;
        setValues(val_old);
        setTrigger(1-trigger);
        
        let err_old = errors;
        err_old[index] = false;
        setErrors(err_old);
        
        setTrigger(1-trigger);
    }

    function parseFilter(filter) {
        switch(filter) {
            case 'from':
                return modal_lang[34][lang];
            case 'to':
                return modal_lang[33][lang];
            default:
                return "";
        }
    }

    useEffect(() => {
        if(visible) {
            toggleOpen();
        }
    }, [visible]);

    async function toggleOpen() {
        await timeout(10);
        setDisplayed(true);
    }

    const toggleClose = async () => {
        setDisplayed(false);
        resetValues();
        await timeout(200);
        //setErrors([false, false, false]);
        onRequestClose();
    }

    const acceptChanges = () => {
        //onComplete();
        let indexes = [];
        indexesData.forEach((item, index) => {
            indexes.push(Object.assign(item, { value: values[index] }));
        });

        console.log(indexes);

        productionService.patchProduction(
            {
                "pk": initialData.pk,
                "volume": productVolume,
                "stock": stocksData[selectedStock].pk,
                "params": {
                    indexes
                }
            },
            token,
            function(response) {
                if(response.production !== null) {
                    toggleClose();
                    onComplete();
                }
            }
        );
    }

    function getStocks() {
        stockService.getStockList(token, function(response) {
            if(response.length > 0) {
                setStocksData(response);
                let _i = 0;
                for(const stock of response) {
                    if(stock.pk == initialData.stock) {
                        setSelectedStock(_i);
                    }

                    _i += 1;
                }
                setTrigger(1-trigger);
            }
        });
    }

    function tryCommitValues() {
        let verr = false;
        if(productVolume === "") {
            setVolError(true);
            verr = true;
        }

        var index = 0;
        for(const value of values) {
            if(value === "") {
                let err_old = errors;
                err_old[index] = true;
                setErrors(err_old);
                setTrigger(1-trigger);
            }

            index += 1;
        }

        if(verr === true) {return;}
        for(const error of errors) {
            if(error) {return;}
        }

        setStage(2);
    }

    function parseStockAddress(stock) {
        var addr = "";
        addr += (stock.country + ", ");
        if(stock.region !== "") {
            addr += (stock.region + ", ");
        }
        if(stock.district !== "") {
            addr += (stock.district + ", ");
        }
        addr += ("г " + stock.town + ", ");
        addr += (stock.street + ", ");
        addr += ("д " + stock.house);
        if(stock.building !== "") {
            addr += (", стр " + stock.building);
        }

        if(stock.corpus !== "") {
            addr += (", к " + stock.corpus);
        }

        if(stock.room !== "") {
            addr += (", кв " + stock.room);

        }

        return addr;
    }

    const [indexLang, setIndexLang] = useState({});
    useEffect(() => {
        indexLanguage(r => {
            setIndexLang(r);
        });
    }, []);

    if(visible) {
        return(
            <div className="InfoModal__root">
                <div 
                    className={"ProductModal__background"+(displayed ? "" : "-hidden")}
                    onClick={toggleClose}
                />

                <div className={"ProductModal__container"+(displayed ? "" : "-hidden")}>
                    <div className="ProductModal__progress-container">
                        <div
                            className="ProductModal__progress-indicator-active"
                            style={{
                                marginLeft: 17,
                            }}
                        >
                            {stage>0?<img src={doneIcon} className="InfoModal__progress-icon" />:<div/>}
                        </div>
                        {stage === 0 && <p className="ProductModal__progress-title" style={{ width: 216, minWidth: 216 }}>{modal_lang[0][lang]}</p>}
                        
                        <div 
                            className="ProductModal__progress-stick"
                            style={stage>0?{backgroundColor:"#0F62FE"}:{}}
                        />

                        <div
                            className={"ProductModal__progress-indicator"+(stage>0?"-active":"")}
                        >
                            {stage>1?<img src={doneIcon} className="InfoModal__progress-icon" />:<div/>}
                        </div>
                        {stage === 1 && <p className="ProductModal__progress-title" style={{ width: 158, minWidth: 158 }}>{modal_lang[7][lang]}</p>}

                        <div 
                            className="ProductModal__progress-stick"
                            style={stage>1?{backgroundColor:"#0F62FE"}:{}}
                        />

                        <div
                            className={"ProductModal__progress-indicator"+(stage>1?"-active":"")}
                        >
                            {stage>2?<img src={doneIcon} className="InfoModal__progress-icon" />:<div/>}
                        </div>
                        {stage === 2 && <p className="ProductModal__progress-title" style={{ width: 122, minWidth: 122 }}>{modal_lang[35][lang]}</p>}

                        <div 
                            className="ProductModal__progress-stick"
                            style={stage>2?{backgroundColor:"#0F62FE"}:{}}
                        />

                        <div
                            className={"ProductModal__progress-indicator"+(stage>2?"-active":"")}
                            style={stage !== 3 ? {marginRight: 17} : {}}
                        />
                        {stage === 3 && <p className="ProductModal__progress-title" style={{ width: 112, minWidth: 112, marginRight: 17 }}>{modal_lang[39][lang]}</p>}
                    </div>

                    <div className="ProductModal__body">
                        {stage === 0 ?
                            <>
                                <p className="ProductModal__body-title">Выберите наименование продукции</p>
                                
                            </>    
                            :(stage === 1 ?
                            <>
                                <p className="ProductModal__body-title">{modal_lang[8][lang]}</p>
                                <TextField
                                    label={modal_lang[9][lang]}
                                    placeholder={modal_lang[10][lang]}
                                    style={{
                                        marginLeft: 32,
                                        marginBottom: 12,
                                    }}
                                    inputWidth={"720px"}
                                    value={productVolume}
                                    onChangeText={text => {
                                        setProductVolume(text);
                                        if(text !== "") {
                                            setVolError(false);
                                        }
                                    }}
                                    error={volError}
                                    errorMessage={modal_lang[32][lang]}
                                    indicatorShown={false}
                                />

                                <div className="ProductModal__inputs-wrapper">
                                    {indexesData.map((data, index) => {
                                        if(data.format !== "picker") {
                                            return(
                                                <TextField
                                                    inputWidth={"344px"}
                                                    style={index%2 !== 0 ? { marginLeft: 16 } : {}}
                                                    key={index}
    
                                                    label={(indexLang.lang !== undefined ? indexLang.lang[data.name][lang] : data.name) + parseFilter(data.filter) + ", " + data.format}
                                                    placeholder={modal_lang[11][lang] + data.name.toLowerCase()}
    
                                                    value={values[index]}
                                                    onChangeText={text => updateValue(index, text)}
                                                    error={errors[index]}
                                                    errorMessage={modal_lang[32][lang]}
                                                    indicatorShown={false}
                                                />
                                            );
                                        } else {
                                            return(
                                                <div className="ProductModal__picker-input" key={index} style={index%2 === 0 ? { marginLeft: 0 } : {}}>
                                                    <p className="ProductModal__picker-label">{data.name}</p>
                                                    <SliderPickerLarge
                                                        items={data.base_values.slice(0, 2)} // ONLY 2 CASES SUPPORTED!
                                                        style={{width:"360px"}}
                                                        labelStyles={[
                                                            {fontWeight:"400"},
                                                            {fontWeight:"400"}
                                                        ]}
                                                        value={values[index]}
                                                        onChange={value => updateValue(index, value)}
                                                    />
                                                </div>
                                            );
                                        }
                                    })}
                                </div>

                                <div className="ProductModal__actions-container" style={{ marginTop: 16 }}>
                                    <GreyButton
                                        text={modal_lang[12][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        onClick={() => {setStage(0)}}
                                    />

                                    <BlueButton
                                        text={modal_lang[6][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        onClick={() => {
                                            if(verifying) {
                                                setStage(3);
                                            } else {
                                                tryCommitValues();
                                            }
                                        }}
                                    />
                                </div>
                            </>
                                
                            :(stage === 2 ?
                            <>
                                <p className="ProductModal__body-title">{modal_lang[36][lang]}</p>
                                
                                <div 
                                    className="ProductModal__stock-selector-container"
                                    onClick={() => setStockSelectorState(!stockSelectorVisible)}
                                    style={stockError ? {
                                        borderBottomColor: "#DA1E28",
                                        borderBottomWidth: 1,
                                        backgroundColor: "#FFF1F1"
                                    } : {
                                        borderBottomColor: "#fff",
                                        borderBottomWidth: 1,
                                    }}
                                >
                                    {selectedStock === -1 ? 
                                    <p className="ProductModal__stock-selector-text">{modal_lang[37][lang]}</p>
                                    :
                                    <p className="ProductModal__stock-selector-text">{stocksData[selectedStock].name}, {parseStockAddress(stocksData[selectedStock]).slice(0, 30)}..</p>
                                    }

                                    <IoChevronDown
                                        color="#0F62FE"
                                        size={16}
                                        className={"ProductModal__stock-selector-chevron" + (stockSelectorVisible ? "-open" : "")}
                                    />

                                    {stockSelectorVisible &&
                                    <div className="ProductModal__stock-list-container">
                                        {stocksData.map((stock, index) => {
                                            return(
                                                <div 
                                                    className="ProductModal__stock-item-container"
                                                    onClick={() => setSelectedStock(index)}
                                                    key={index}
                                                >
                                                    <p className="ProductModal__stock-item-label">{stock.name}</p>
                                                    <p className="ProductModal__stock-item-address">{parseStockAddress(stock)}</p>
                                                    <div className="ProductModal__stock-item-divider"/>
                                                </div>
                                            );
                                        })}
                                        <div className="ProductModal__stock-button-container">
                                            <p className="ProductModal__stock-button-label">{modal_lang[38][lang]}</p>
                                        </div>
                                    </div>
                                    }
                                </div>

                                <div className="ProductModal__actions-container" style={{ marginTop: 16 }}>
                                    <GreyButton
                                        text={modal_lang[12][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        onClick={() => {setStage(1)}}
                                    />

                                    <BlueButton
                                        text={modal_lang[6][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        disabled={selectedStock === -1}
                                        onClick={() => {
                                            setStage(3);
                                        }}
                                    />
                                </div>
                            </>
                            :
                            (
                                initialData !== null && initialData !== undefined &&
                                <>
                                    <p className="ProductModal__body-title">Редактирование продукции</p>

                                    <div className="ProductModal__result-container">
                                        <p className="ProductModal__result-subheader">{modal_lang[41][lang]}</p>

                                        <div className="ProductModal__result-item-container">
                                            <p className="ProductModal__result-item-label">{modal_lang[2][lang]}</p>
                                            <p className="ProductModal__result-item-value">{initialData !== undefined ? initialData.category : ""}</p>
                                        </div>

                                        <div className="ProductModal__result-item-container">
                                            <p className="ProductModal__result-item-label">{modal_lang[3][lang]}</p>
                                            <p className="ProductModal__result-item-value">{initialData !== undefined ? initialData.name: ""}</p>
                                        </div>
                                        <div className="ProductModal__result-divider"/>
                                    </div>

                                    <div className="ProductModal__result-container">
                                        <p className="ProductModal__result-subheader">{modal_lang[7][lang]}</p>

                                        <div className="ProductModal__result-item-container">
                                            <p className="ProductModal__result-item-label">{modal_lang[9][lang]}</p>
                                            <p className="ProductModal__result-item-value">{productVolume}</p>
                                        </div>

                                        {indexesData.map((data, index) => {
                                            if(data.format !== "picker") {
                                                return(
                                                    <div className="ProductModal__result-item-container" key={index}>
                                                        <p className="ProductModal__result-item-label">{indexLang.lang !== undefined ? indexLang.lang[data.name][lang] : data.name}</p>
                                                        <p className="ProductModal__result-item-value">{values[index]} {data.format}</p>
                                                    </div>
                                                );
                                            } else {
                                                return(
                                                    <div className="ProductModal__result-item-container" key={index}>
                                                        <p className="ProductModal__result-item-label">{indexLang.lang !== undefined ? indexLang.lang[data.name][lang] : data.name}</p>
                                                        <p className="ProductModal__result-item-value">{data.base_values[values[index]]}</p>
                                                    </div>
                                                );
                                            }
                                        })}

                                        <img 
                                            src={editIcon} 
                                            className="ProductModal__result-item-edit"
                                            onClick={() => setStage(1)}
                                        />
                                        <div className="ProductModal__result-divider"/>
                                    </div>

                                    <div className="ProductModal__result-container">
                                        <p className="ProductModal__result-subheader">Адрес склада</p>

                                        <div className="ProductModal__result-item-container">
                                            <p className="ProductModal__result-item-label">{stocksData.length > 0 && stocksData[selectedStock].name}</p>
                                            <p className="ProductModal__result-item-value">{stocksData.length > 0 && parseStockAddress(stocksData[selectedStock])}</p>
                                        </div>

                                        <img 
                                            src={editIcon} 
                                            className="ProductModal__result-item-edit"
                                            onClick={() => setStage(2)}
                                        />
                                    </div>

                                    <div className="ProductModal__actions-container" style={{ marginTop: 24 }}>
                                        <GreyButton
                                            text={modal_lang[12][lang]}
                                            style={{
                                                width: 360,
                                                height: 40
                                            }}
                                            onClick={() => {
                                                setStage(3);
                                                setVerifying(false);
                                            }}
                                        />

                                        <BlueButton
                                            text={modal_lang[42][lang]}
                                            style={{
                                                width: 360,
                                                height: 40
                                            }}
                                            onClick={() => {
                                                //console.log("SAVE DATA");
                                                acceptChanges();
                                            }}
                                        />
                                    </div>
                                </>
                            )
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export function EditProductRequestModal({
    initialData,
    visible,
    onRequestClose,
    onComplete,
    token,
    lang=1
}) {
    const [displayed, setDisplayed] = useState(false);
    const [stage, setStage] = useState(3);
    const [indexesData, setIndexesData] = useState([]);

    const [verifying, setVerifying] = useState(true);

    const [indexesLang, setIndexesLang] = useState({});
    useEffect(() => {
        indexLanguage(function(r) {
            setIndexesLang(r);
        });
    }, []);

    useEffect(() => {
        if(initialData !== null && initialData !== undefined) {
            processIndexes();
            let periods = getPeriodItems();
            setPeriodItems(periods);

            setProductVolume(initialData.volume);
            setPriceMode(initialData.price_mode);
            setPrice(initialData.price);
            
            let _i = 0;
            for(const cur of currencies) {
                if(initialData.currency == cur) {
                    setSelectedCurrency(_i);
                }

                _i += 1;
            }

            let _i1 = 0;
            for(const bas of basisItems) {
                if(initialData.basis == bas) {
                    setBasis(_i1);
                }

                _i1 += 1;
            }

            setSelectedCountry(initialData.country);
            setCountrySearchValue(initialData.country);

            setStation(initialData.station);

            let _i2 = 0;
            for(const tt of transportType1Items) {
                if(initialData.ttype1 == tt) {
                    setTransportType1(_i2);
                }

                _i2 += 1;
            }

            let _i3 = 0;
            for(const tt of transportType2Items) {
                if(initialData.ttype2 == tt) {
                    setTransportType2(_i3);
                }

                _i3 += 1;
            }

            let _i4 = 0;
            for(const pck of productPackageItems) {
                if(initialData.package == pck) {
                    setProductPackage(_i4);
                }

                _i4 += 1;
            }
        }
        
    }, [initialData]);

    const [priceMode, setPriceMode] = useState(1); // 0 - своя , 1 - договор
    const [price, setPrice] = useState("");
    const [priceError, setPriceError] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(0);
    const currencies = [
        "₽",
        "$",
        "€",
        "¥"
    ];

    useEffect(() => {
        if(price !== "" && priceError) {
            setPriceError(false);
        }
    }, [price]);

    function resetValues() {
        setIndexesData([]);
        setBasis(-1);
        setSelectedCountry("");
        setCountrySearchValue("");
        setStation("");
        setTransportType1(-1);
        setTransportType2(-1);
        setProductPackage(-1);
        setPeriodFrom(-1);
        setPeriodTo(-1);
        setProductVolume("");
        setValues([""]);
        setPriceMode(1);
        setPrice("");
        setSelectedCurrency(0);
    }
    
    // stage 2 data
    const [basis, setBasis] = useState(-1);
    const [basisItems, setBasisItems] = useState([
        "FCA",
        "DAP",
        "FOB", 
        "FAS", 
        "CIF",
        "CFR", 
        "CPT", 
        "CIP"
    ]);

    const [selectedCountry, setSelectedCountry] = useState("");
    const [countrySearchValue, setCountrySearchValue] = useState("");
    const [countrySearchState, setCountrySearchState] = useState(false);
    const [countryItems, setCountryItems] = useState([]);

    useEffect(() => {
        if(selectedCountry !== countrySearchValue) {
            setSelectedCountry("");

            if(countrySearchValue !== "" && countrySearchValue.length > 1) {
                /*let url = `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/country`;
                axios.post(
                    url,
                    { query: countrySearchValue },
                    { 
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Token 11f0f146144bfa7088a1dbc10e3186e809ce48f2"
                        },
                        withCredentials: false
                    }
                ).then(response => {
                    //console.log(response.data.suggestions);
                    if(response.data.suggestions !== undefined) {
                        let countries = [];
                        for(const cntr of response.data.suggestions) {
                            countries.push(cntr.value);
                        }
    
                        setCountryItems(countries);
                    }
                });*/

                axios.get(
                    `https://restcountries.com/v3.1/name/${countrySearchValue}`,
                    {headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                    }}
                ).then(response => {
                    console.log(response);
                    if(response.data.length > 0) {
                        let countries = [];
                        for(const cntr of response.data) {
                            if(lang === 0) {
                                countries.push(cntr.name.nativeName.rus.common);
                            } else {
                                countries.push(cntr.name.common);
                            }
                        }

                        setCountryItems(countries);
                    }
                });
            } else {
                setCountryItems([]);
            }
        }
    }, [countrySearchValue]);

    const [station, setStation] = useState("");

    const [transportType1, setTransportType1] = useState(-1);
    const [transportType1Items, setTransportType1Items] = useState([
        ["Контейнерная", "Неконтейнерная"],
        ["Container", "Non-Container"],
        ["集装箱", "无集装箱"]
    ]);

    const [transportType2, setTransportType2] = useState(-1);
    const [transportType2Items, setTransportType2Items] = useState([
        ["Железнодорожная", "Автомобильная", "Морская"],
        ["Railway", "Car", "Sea"],
        ["铁路运输", "汽车运输", "海洋运输"]
    ]);

    const oilPackageItems = [["Налив", "Евробочки", "Бутылки"], ["Налив", "Евробочки", "Бутылки"], ["Налив", "Евробочки", "Бутылки"]];
    const defaultPackageItems = [["Насыпь", "Мешки", "Биг-бэги"], ["Embankment", "Bags", "Big bags"], ["散装", "麻袋", "吨袋"]];

    const [productPackage, setProductPackage] = useState(-1);
    const [productPackageItems, setProductPackageItems] = useState(defaultPackageItems);

    const [periodFrom, setPeriodFrom] = useState(-1);
    const [periodTo, setPeriodTo] = useState(-1);
    const [periodItems, setPeriodItems] = useState([]);

    const [stage2Errors, setStage2Errors] = useState([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
    ]);

    function getPeriodItems() {
        let periods = [];
        let date = new Date();
        let mon = date.getMonth();
        let year = date.getFullYear();

        let monthDict = [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь"
        ];

        for(let j = mon; j < 12; j++) {
            periods.push(monthDict[j] + " " + year);
        }

        for(let i = year+1; i < 2030; i++) {
            for(let j = 0; j < 12; j++) {
                periods.push(monthDict[j] + " " + i);
            }
        }

        for(let j = 0; j < mon; j++) {
            periods.push(monthDict[j] + " 2030");
        }

        return periods;
    }

    async function processIndexes() {
        setIndexesData(initialData.params.indexes);
        let _values = [];
        let _errors = [];

        for(const ind of initialData.params.indexes) {
            _values.push(ind.value);
            _errors.push(false);
        }

        setValues(_values);
        setErrors(_errors);
    }

    // stage 1
    const [productVolume, setProductVolume] = useState("");
    const [values, setValues] = useState(["", "", "", ""]);

    const [volError, setVolError] = useState(false);
    const [errors, setErrors] = useState([false, false, false, false]);

    const updateValue = (index, value) => {
        let val_old = values;
        val_old[index] = value;
        setValues(val_old);
        setTrigger(1-trigger);
        
        let err_old = errors;
        err_old[index] = false;
        setErrors(err_old);
        
        setTrigger(1-trigger);
    }

    function parseFilter(filter) {
        switch(filter) {
            case 'from':
                return modal_lang[34][lang];
            case 'to':
                return modal_lang[33][lang];
            default:
                return "";
        }
    }

    // errors
    const [trigger, setTrigger] = useState(0);

    useEffect(() => {
        if(visible) {
            toggleOpen();
        }
    }, [visible]);

    async function toggleOpen() {
        await timeout(10);
        setDisplayed(true);
    }

    const toggleClose = async () => {
        setDisplayed(false);
        resetValues();
        await timeout(200);
        //setStage(0);
        //setErrors([false, false, false]);
        onRequestClose();
    }

    const acceptChanges = () => {
        //onComplete();
        let indexes = [];
        indexesData.forEach((item, index) => {
            indexes.push(Object.assign(item, { value: values[index] }));
        });

        productionRequestService.patchProductionRequest(
            {
                "pk": initialData.pk,
                "volume": productVolume,
                "basis": basisItems[basis],
                "country": selectedCountry,
                "station": station,
                "ttype1": transportType1Items[lang][transportType1],
                "ttype2": transportType2Items[lang][transportType2],
                "package": productPackageItems[lang][productPackage],
                "period1": periodItems[periodFrom],
                "period2": periodItems[periodTo],
                "params": {
                    indexes
                },
                "price_mode": priceMode,
                "price": price,
                "currency": priceMode === 0 ? currencies[selectedCurrency] : ""
            },
            token,
            function(response) {
                //console.log(response);
                if(response.production_request !== null) {
                    toggleClose();
                    onComplete();
                }
            }
        );
    }

    function tryCommitValues() {
        let verr = false;
        if(productVolume === "") {
            setVolError(true);
            verr = true;
        }

        var index = 0;
        for(const value of values) {
            if(value === "") {
                let err_old = errors;
                err_old[index] = true;
                setErrors(err_old);
                setTrigger(1-trigger);
            }

            index += 1;
        }

        if(verr === true) {return;}
        for(const error of errors) {
            if(error) {return;}
        }

        if(priceMode===0 && price === "") {
            setPriceError(true);
            return;
        }

        setStage(2);
    }
    
    const countryRef = useOutsideClick(() => {setCountrySearchState(false)});

    const monthlang = {
        "Январь": [
            "Январь",
            "January",
            "1月"
        ],
        "Февраль": [
            "Февраль",
            "February",
            "2月"
        ],
        "Март": [
            "Март",
            "March",
            "3月"
        ],
        "Апрель": [
            "Апрель",
            "April",
            "4月"
        ],
        "Май": [
            "Май",
            "May",
            "5月"
        ],
        "Июнь": [
            "Июнь",
            "June",
            "6月"
        ],
        "Июль": [
            "Июль",
            "July",
            "7月"
        ],
        "Август": [
            "Август",
            "August",
            "8月"
        ],
        "Сентябрь": [
            "Сентябрь",
            "September",
            "9月"
        ],
        "Октябрь": [
            "Октябрь",
            "October",
            "10月"
        ],
        "Ноябрь": [
            "Ноябрь",
            "November",
            "11月"
        ],
        "Декабрь": [
            "Декабрь",
            "December",
            "12月"
        ]
    };

    if(visible) {
        return(
            <div className="InfoModal__root">
                <div 
                    className={"ProductModal__background"+(displayed ? "" : "-hidden")}
                    onClick={toggleClose}
                />

                <div className={"ProductRequestModal__container"+(displayed ? "" : "-hidden")}>
                    <div className="ProductModal__progress-container">
                        <div
                            className="ProductModal__progress-indicator-active"
                            style={{
                                marginLeft: 17,
                            }}
                        >
                            {stage>0?<img src={doneIcon} className="InfoModal__progress-icon" />:<div/>}
                        </div>
                        {stage === 0 && <p className="ProductModal__progress-title" style={{ width: 216, minWidth: 216 }}>Создание заявки на продукцию</p>}
                        
                        <div 
                            className="ProductModal__progress-stick"
                            style={stage>0?{backgroundColor:"#0F62FE"}:{}}
                        />

                        <div
                            className={"ProductModal__progress-indicator"+(stage>0?"-active":"")}
                        >
                            {stage>1?<img src={doneIcon} className="InfoModal__progress-icon" />:<div/>}
                        </div>
                        {stage === 1 && <p className="ProductModal__progress-title" style={{ width: 158, minWidth: 158 }}>{modal_lang[7][lang]}</p>}

                        <div 
                            className="ProductModal__progress-stick"
                            style={stage>1?{backgroundColor:"#0F62FE"}:{}}
                        />

                        <div
                            className={"ProductModal__progress-indicator"+(stage>1?"-active":"")}
                        >
                            {stage>2?<img src={doneIcon} className="InfoModal__progress-icon" />:<div/>}
                        </div>
                        {stage === 2 && <p className="ProductModal__progress-title" style={{ width: 122, minWidth: 122 }}>{modal_lang[19][lang]}</p>}

                        <div 
                            className="ProductModal__progress-stick"
                            style={stage>2?{backgroundColor:"#0F62FE"}:{}}
                        />

                        <div
                            className={"ProductModal__progress-indicator"+(stage>2?"-active":"")}
                            style={stage !== 3 ? {marginRight: 17} : {}}
                        />
                        {stage === 3 && <p className="ProductModal__progress-title" style={{ width: 112, minWidth: 112, marginRight: 17 }}>{modal_lang[39][lang]}</p>}
                    </div>

                    <div className="ProductModal__body">
                        {stage === 0 ?
                            <>
                                <p className="ProductModal__body-title">{modal_lang[62][lang]}</p>
                            </>    
                            :(stage === 1 ?
                            <>
                                <p className="ProductModal__body-title">{modal_lang[8][lang]}</p>
                                <TextField
                                    label={modal_lang[9][lang]}
                                    placeholder={modal_lang[10][lang]}
                                    style={{
                                        marginLeft: 32,
                                        marginBottom: 12,
                                    }}
                                    inputWidth={"720px"}
                                    value={productVolume}
                                    onChangeText={text => {
                                        setProductVolume(text);
                                        if(text !== "") {
                                            setVolError(false);
                                        }
                                    }}
                                    error={volError}
                                    errorMessage={modal_lang[32][lang]}
                                    indicatorShown={false}
                                />

                                <div className="ProductModal__inputs-wrapper">
                                    {indexesData.map((data, index) => {
                                        if(data.format !== "picker") {
                                            return(
                                                <TextField
                                                    inputWidth={"344px"}
                                                    style={index%2 !== 0 ? { marginLeft: 16 } : {}}
                                                    key={index}
    
                                                    label={data.name + parseFilter(data.filter) + ", " + data.format}
                                                    placeholder={profile_lang[62][lang] + data.name.toLowerCase()}
    
                                                    value={values[index]}
                                                    onChangeText={text => updateValue(index, text)}
                                                    error={errors[index]}
                                                    errorMessage={modal_lang[32][lang]}
                                                    indicatorShown={false}
                                                />  
                                            );
                                        } else {
                                            return(
                                                <div className="ProductModal__picker-input" key={index}  style={index%2 === 0 ? { marginLeft: 0 } : {}}>
                                                    <p className="ProductModal__picker-label">{data.name}</p>
                                                    <SliderPickerLarge
                                                        items={
                                                            indexesLang.lang[data.base_values[0]] !== undefined ? 
                                                            [indexesLang.lang[data.base_values[0]][lang], indexesLang.lang[data.base_values[1]][lang]]
                                                            :
                                                            data.base_values.slice(0, 2)
                                                        } // ONLY 2 CASES SUPPORTED!
                                                        style={{width:"360px"}}
                                                        labelStyles={[
                                                            {fontWeight:"400"},
                                                            {fontWeight:"400"}
                                                        ]}
                                                        value={values[index]}
                                                        onChange={value => updateValue(index, value)}
                                                    />
                                                </div>
                                            );
                                        }
                                    })}
                                </div>

                                <p className="ProductRequestModal__pricemode-label">{modal_lang[15][lang]}</p>
                                <div className="ProductRequestModal__pricemode-picker-container">
                                    <div className="ProductRequestModal__pricemode-picker-item" onClick={() => setPriceMode(0)}>
                                        <div className="ProductRequestModal__pricemode-item-indicator">
                                            {priceMode === 0 && <div className="ProductRequestModal__pricemode-item-indicator-inner"/>}
                                        </div>

                                        <p className="ProductRequestModal__pricemode-item-label" style={priceMode!==0?{color:"#6F6F6F"}:{}}>{modal_lang[13][lang]}</p>
                                    </div>

                                    <div className="ProductRequestModal__pricemode-picker-item" onClick={() => setPriceMode(1)}>
                                        <div className="ProductRequestModal__pricemode-item-indicator">
                                            {priceMode === 1 && <div className="ProductRequestModal__pricemode-item-indicator-inner"/>}
                                        </div>

                                        <p className="ProductRequestModal__pricemode-item-label" style={priceMode!==1?{color:"#6F6F6F"}:{}}>{modal_lang[14][lang]}</p>
                                    </div>
                                </div>

                                {priceMode === 0 &&
                                <div className="ProductRequestModal__price-container">
                                    <div className="ProductRequestModal__price-input-container">
                                        {/*
                                        <p className="ProductRequestModal__pricemode-label" style={{marginLeft:"0px"}}>Укажите цену за 1 т</p>
                                        <input
                                            value={price}
                                            onChange={event => setPrice(event.target.value)}
                                            className="ProductRequestModal__price-input"
                                            placeholder="Укажите цену"
                                        />
                                        */}

                                        <TextField
                                            label={modal_lang[16][lang]}
                                            value={price}
                                            onChangeText={setPrice}
                                            indicatorShown={false}
                                            inputWidth="calc(100% - 16px)"
                                            placeholder={modal_lang[17][lang]}
                                            errorMessage={modal_lang[72][lang]}
                                            error={priceError}
                                        />
                                    </div>

                                    <div className="ProductRequestModal__price-input-container">
                                        <p className="ProductRequestModal__pricemode-label" style={{marginLeft:"0px"}}>{modal_lang[18][lang]}</p>
                                        <div className="ProductRequestModal__price-currency-picker-container">
                                            {currencies.map((curr, index) => {
                                                return(
                                                    <div className="ProductRequestModal__price-currency-picker-item" onClick={() => setSelectedCurrency(index)}>
                                                        <div className="ProductRequestModal__pricemode-item-indicator">
                                                            <div className="ProductRequestModal__pricemode-item-indicator-inner" style={selectedCurrency === index?{opacity:1}:{opacity:0}}/>
                                                        </div>

                                                        <p className="ProductRequestModal__pricemode-item-label"  style={selectedCurrency!==index?{color:"#6F6F6F"}:{}}>{curr}</p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                }

                                <div className="ProductModal__actions-container" style={{ marginTop: 16 }}>
                                    <GreyButton
                                        text={modal_lang[12][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        //onClick={() => {setStage(0)}}
                                    />

                                    <BlueButton
                                        text={modal_lang[6][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        onClick={() => {
                                            if(verifying) {
                                                setStage(3);
                                            } else {
                                                tryCommitValues();
                                            }
                                        }}
                                    />
                                </div>
                            </>
                                
                            :(stage === 2 ?
                            <>
                                <p className="ProductModal__body-title">{modal_lang[20][lang]}</p>
                                
                                <p className="ProductRequestModal__picker-subtitle">{modal_lang[21][lang]}</p>
                                <div className="ProductRequestModal__picker-wrapper">
                                    <SquareDropdownPicker
                                        placeholder={modal_lang[22][lang]}
                                        value={basis}
                                        items={basisItems}
                                        onChange={(value) => {
                                            setBasis(value);
                                        }}

                                        style={{
                                            borderRight: "1px solid #E0E0E0"
                                        }}
                                        textStyle={{
                                            marginLeft: 0
                                        }}
                                    />

                                    <div 
                                        className="ProductRequestModal__station-input-container" 
                                        style={{borderRight: "1px solid #E0E0E0"}}
                                        onClick={() => setCountrySearchState(!countrySearchState)}
                                        ref={countryRef}
                                    >
                                        <input
                                            value={countrySearchValue}
                                            onChange={event => setCountrySearchValue(event.target.value)}
                                            className="ProductRequestModal__station-input"
                                            placeholder={modal_lang[23][lang]}
                                        />

                                        {countrySearchState &&
                                        <div className="ProductRequestModal__country-results-container">
                                            <div className="ProductRequestModal__country-results-wrapper">
                                            {
                                                countryItems.map((country, index) => {
                                                    return(
                                                        <div 
                                                            className="ProductRequestModal__country-result-item" 
                                                            key={index}
                                                            onClick={() => {
                                                                setSelectedCountry(country);
                                                                setCountrySearchValue(country);
                                                            }}
                                                        >
                                                            <p className="ProductRequestModal__country-result-item-label">{country}</p>
                                                        </div>
                                                    );
                                                })
                                            }
                                            </div>
                                        </div>
                                        }
                                    </div>

                                    <div className="ProductRequestModal__station-input-container">
                                        <input
                                            value={station}
                                            onChange={event => setStation(event.target.value)}
                                            className="ProductRequestModal__station-input"
                                            placeholder={modal_lang[24][lang]}
                                        />
                                    </div>
                                </div>

                                <p className="ProductRequestModal__picker-subtitle" style={{marginTop:"32px"}}>{modal_lang[25][lang]}</p>
                                <div className="ProductRequestModal__picker-wrapper">
                                    <SquareDropdownPicker
                                        placeholder={modal_lang[26][lang]}
                                        value={transportType1}
                                        items={transportType1Items[lang]}
                                        onChange={(value) => {
                                            setTransportType1(value);
                                        }}

                                        style={{
                                            borderRight: "1px solid #E0E0E0"
                                        }}
                                        textStyle={{
                                            marginLeft: 0
                                        }}
                                    />

                                    <SquareDropdownPicker
                                        placeholder={modal_lang[27][lang]}
                                        value={transportType2}
                                        items={transportType2Items[lang]}
                                        onChange={(value) => {
                                            setTransportType2(value);
                                        }}

                                        style={{
                                            borderRight: "1px solid #E0E0E0"
                                        }}
                                    />

                                    <SquareDropdownPicker
                                        placeholder={modal_lang[28][lang]}
                                        value={productPackage}
                                        items={productPackageItems[lang]}
                                        onChange={(value) => {
                                            setProductPackage(value);
                                        }}
                                    />
                                </div>

                                <p className="ProductRequestModal__picker-subtitle" style={{marginTop:"32px"}}>{modal_lang[71][lang]}</p>
                                <div className="ProductRequestModal__picker-wrapper">
                                    <SquareDropdownPicker
                                        placeholder={modal_lang[30][lang]}
                                        value={periodFrom}
                                        items={periodItems}
                                        onChange={(value) => {
                                            if(value > periodTo && periodTo !== -1) {
                                                let err2 = stage2Errors;
                                                err2[6] = true;
                                                setStage2Errors(err2);
                                                setPeriodFrom(-1);
                                            } else {
                                                setPeriodFrom(value);
                                                if(stage2Errors[6]) {
                                                    let err2 = stage2Errors;
                                                    err2[6] = false;
                                                    setStage2Errors(err2);
                                                    setTrigger(1-trigger);
                                                }
                                            }
                                        }}
                                        error={stage2Errors[6]}

                                        style={{
                                            borderRight: "1px solid #E0E0E0"
                                        }}
                                        textStyle={{
                                            marginLeft: 0
                                        }}
                                    />

                                    <SquareDropdownPicker
                                        placeholder={modal_lang[31][lang]}
                                        value={periodTo}
                                        items={periodItems}
                                        onChange={(value) => {
                                            if(value < periodFrom) {
                                                let err2 = stage2Errors;
                                                err2[7] = true;
                                                setStage2Errors(err2);
                                                setPeriodTo(-1);
                                            } else {
                                                setPeriodTo(value);
                                                if(stage2Errors[7]) {
                                                    let err2 = stage2Errors;
                                                    err2[7] = false;
                                                    setStage2Errors(err2);
                                                    setTrigger(1-trigger);
                                                }
                                            }
                                        }}
                                        error={stage2Errors[7]}
                                    />
                                </div>

                                <div className="ProductModal__actions-container" style={{ marginTop: 16 }}>
                                    <GreyButton
                                        text={modal_lang[12][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        onClick={() => {setStage(1)}}
                                    />

                                    <BlueButton
                                        text={modal_lang[6][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        disabled={!(basis !== -1 && selectedCountry !== "" && station !== "" && transportType1 !== -1 && transportType2 !== -1 && productPackage !== -1 && periodFrom !== -1 && periodTo !== -1)}
                                        onClick={() => {
                                            setStage(3);
                                            setVerifying(true);
                                        }}
                                    />
                                </div>
                            </>
                            :
                            <>
                                <p className="ProductModal__body-title">{modal_lang[40][lang]}</p>

                                <div className="ProductModal__result-container">
                                    <p className="ProductModal__result-subheader">{modal_lang[41][lang]}</p>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[2][lang]}</p>
                                        <p className="ProductModal__result-item-value">{initialData !== undefined && initialData.category}</p>
                                    </div>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[3][lang]}</p>
                                        <p className="ProductModal__result-item-value">{initialData !== undefined && initialData.name}</p>
                                    </div>
                                    <div className="ProductModal__result-divider"/>
                                </div>

                                <div className="ProductModal__result-container">
                                    <p className="ProductModal__result-subheader">{modal_lang[7][lang]}</p>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[9][lang]}</p>
                                        <p className="ProductModal__result-item-value">{productVolume}</p>
                                    </div>

                                    {indexesData.map((data, index) => {
                                        if(data.format !== "picker") {
                                            return(
                                                <div className="ProductModal__result-item-container" key={index}>
                                                    <p className="ProductModal__result-item-label">{data.name}</p>
                                                    <p className="ProductModal__result-item-value">{values[index]} {data.format}</p>
                                                </div>
                                            );
                                        } else {
                                            return(
                                                <div className="ProductModal__result-item-container" key={index}>
                                                    <p className="ProductModal__result-item-label">{data.name}</p>
                                                    <p className="ProductModal__result-item-value">{data.base_values[values[index]]}</p>
                                                </div>
                                            );
                                        }
                                    })}

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">Цена</p>
                                        <p className="ProductModal__result-item-value">{priceMode===1?"По согласованию":`${price}${currencies[selectedCurrency]} за т`}</p>
                                    </div>  

                                    <img 
                                        src={editIcon} 
                                        className="ProductModal__result-item-edit"
                                        onClick={() => setStage(1)}
                                    />
                                    <div className="ProductModal__result-divider"/>
                                </div>

                                <div className="ProductModal__result-container">
                                    <p className="ProductModal__result-subheader">{modal_lang[19][lang]}</p>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[22][lang]}</p>
                                        <p className="ProductModal__result-item-value">{basisItems[basis]}</p>
                                    </div>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[23][lang]}</p>
                                        <p className="ProductModal__result-item-value">{selectedCountry}</p>
                                    </div>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[24][lang]}</p>
                                        <p className="ProductModal__result-item-value">{station}</p>
                                    </div>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[26][lang]}</p>
                                        <p className="ProductModal__result-item-value">{transportType1Items[lang][transportType1]}</p>
                                    </div>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[27][lang]}</p>
                                        <p className="ProductModal__result-item-value">{transportType2Items[lang][transportType2]}</p>
                                    </div>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[28][lang]}</p>
                                        <p className="ProductModal__result-item-value">{productPackageItems[lang][productPackage]}</p>
                                    </div>

                                    <div className="ProductModal__result-item-container">
                                        <p className="ProductModal__result-item-label">{modal_lang[70][lang]}</p>
                                        <p className="ProductModal__result-item-value">{monthlang[periodItems[periodFrom].split(" ")[0]][lang]} {periodItems[periodFrom].split(" ")[1]} - {monthlang[periodItems[periodTo].split(" ")[0]][lang]} {periodItems[periodTo].split(" ")[1]}</p>
                                    </div>

                                    <img 
                                        src={editIcon} 
                                        className="ProductModal__result-item-edit"
                                        onClick={() => setStage(2)}
                                    />
                                </div>

                                <div className="ProductModal__actions-container" style={{ marginTop: 24 }}>
                                    <GreyButton
                                        text={modal_lang[12][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        onClick={() => {
                                            setStage(2);
                                        }}
                                    />

                                    <BlueButton
                                        text={modal_lang[42][lang]}
                                        style={{
                                            width: 360,
                                            height: 40
                                        }}
                                        onClick={() => {
                                            //console.log("SAVE DATA");
                                            acceptChanges();
                                        }}
                                    />
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}