import React, { useState, useEffect } from 'react';

import "../../pagestyles/RequestsView.css";

// LANGUAGE
import cab_file from "../../lang/cabinet.json";
import mod_file from "../../lang/modals.json";
import market_file from "../../lang/market.json";

// ICONS
import { IoSearch, IoChevronDown, IoClose, IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { BlueButton } from '../../components/Buttons';
import SliderPicker from '../../components/SilderPicker';
import editIcon from "../../assets/edit.png";
import deleteIcon from "../../assets/delete.png";

// MODALS
import { CompanyNotProvidedModal, DeleteModal, EditProductModal, EditProductRequestModal, ProductModal, ProductRequestModal } from '../../components/Modals';

// FILTERS VIEW
import FiltersView from '../main/FiltersView';

// API
import ProductionService from '../../services/ProductionService';
import ProductionRequestService from '../../services/ProductionRequestService';
import StockService from '../../services/StockService';
import UserService from '../../services/UserService';
import CompanyService from '../../services/CompanyService';
import { indexLanguage, translateCatAndItem } from '../../services/FirebaseApi';

const productionService = new ProductionService();
const productionRequestService = new ProductionRequestService();
const stockService = new StockService();
const userService = new UserService();
const companyService = new CompanyService();

const cab_lang = cab_file.lang;
const modal_lang = mod_file.lang;
const market_lang = market_file.lang;

export default function ProductsView({ token, lang }) {
    const [productRequests, setProductRequests] = useState([]);
    const [serviceRequests, setServiceRequests] = useState([]);
    const [trigger, setTrigger] = useState(0);

    const [productModalState, setProductModalState] = useState(false);
    const toggleClose = () => {
        setProductModalState(false);
    }

    const [tabSliderVisible, setTabSliderState] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0); // 0 , 1
    const switchTab = (value) => {
        setSelectedTab(value);
    }

    async function fetchCompanyPermissions() {
        companyService.getCompaniesList(token, function(response) {
            let data = response.companies[0];
            switch(data.role) {
                case "buyer":
                    setSelectedTab(0);
                    setTabSliderState(false);
                    break;
                case "seller":
                    setSelectedTab(0);
                    setTabSliderState(false);
                    break;
                case "service":
                    setSelectedTab(1);
                    setTabSliderState(false);
                    break;
                default:
                    let arr = data.role.split(" ");
                    if(arr.length > 1 && arr.includes("service")) {
                        setSelectedTab(0);
                        setTabSliderState(true);
                    }
                    break;
            }
        });
    }

    const [filterState, setFilterState] = useState(false);

    useEffect(() => {
        loadFirstPage();

        fetchCompanyPermissions();
    }, []);

    function parseEnding(value) {
        if(value === 0) {
            return "й";
        }

        if(value === 1) {
            return "я";
        }

        if(value < 5) {
            return "и";
        }

        return "й";
    }

    const onCreateRequest = () => {
        companyService.getCompaniesList(token, function(response) {
            if(response.companies.length > 0) {
                let role = response.companies[0].role;
                if(role.split(" ").includes("seller")) {
                    setProductModalState(true);
                } else {
                    setCompanyAlarmType("not-allowed");
                }
            } else {
                setCompanyAlarmType("not-provided");
            }
        });
    }

    const onCalledRefresh = () => {
        userService.getUserKey(token, function(selfUser) {
            productionService.getPagedFilteredProductionsList(token, Object.assign({"parent":selfUser.pk}, filters), currentPage, function(response) {
                setProductRequests(response.data);
                setCount(response.count);
                setCurrentPage(1);
                setPages(response.numpages);
                setPrevPage(response.prevpage);
                setNextPage(response.nextpage);
                return;
            });
        });
    }

    const [companyAlarmState, setCompanyAlarmState] = useState(false);
    const [companyAlarmType, setCompanyAlarmType] = useState("");
    const onCAlarmRequestClose = () => {
        setCompanyAlarmState(false);
    }

    useEffect(() => {
        if(companyAlarmType !== "") {
            setCompanyAlarmState(true);
        }
    }, [companyAlarmType]);

    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [prevPage, setPrevPage] = useState(1);
    const [nextPage, setNextPage] = useState(1);
    const [count, setCount] = useState(0);

    const [filters, setFilters] = useState({});

    function loadFirstPage() {
        userService.getUserKey(token, function(selfUser) {
            productionService.getPagedFilteredProductionsList(token, {"parent":selfUser.pk}, 1, function(response) {
                setProductRequests(response.data);
                setCount(response.count);
                setCurrentPage(1);
                setPages(response.numpages);
                setPrevPage(response.prevpage);
                setNextPage(response.nextpage);
            });
        });
    }
    
    function applyFilters(_filters) {
        setFilters(_filters);
        userService.getUserKey(token, function(selfUser) {
            console.log(selfUser);
            productionService.getPagedFilteredProductionsList(token, Object.assign({"parent":selfUser.pk}, _filters), 1, function(response) {
                setProductRequests(response.data);
                setCount(response.count);
                setCurrentPage(1);
                setPages(response.numpages);
                setPrevPage(response.prevpage);
                setNextPage(response.nextpage);
            });
        });
    }

    function loadPage(index) {
        userService.getUserKey(token, function(selfUser) {
            productionService.getPagedFilteredProductionsList(token, Object.assign({"parent":selfUser.pk}, filters), index, function(response) {
                setProductRequests(response.data);
                setCount(response.count);
                setCurrentPage(1);
                setPages(response.numpages);
                setPrevPage(response.prevpage);
                setNextPage(response.nextpage);
                return;
            });
        });
    }

    const [editProductModalState, setEditProductModalState] = useState(false);
    const toggleEditClose = () => {
        setEditProductModalState(false);
        setEditProdData(null);
    }

    const [editProdData, setEditProdData] = useState(null);
    const onProdEditCall = (index) => {
        setEditProdData(productRequests[index]);
    }

    useEffect(() => {
        if(editProdData !== null) {
            setEditProductModalState(true);
            console.log(editProdData);
        }
    }, [editProdData]);

    return(
        <div className="RequestsView__root">
            <ProductModal
                visible={productModalState}
                onRequestClose={toggleClose}
                token={token}
                onComplete={() => {
                    productionService.getProductionsList(token, function(response) {
                        setProductRequests(response);
                    });
                }}
                lang={lang}
            />

            <EditProductModal
                visible={editProductModalState}
                onRequestClose={toggleEditClose}
                token={token}
                initialData={editProdData}
                onComplete={() => {
                    productionService.getProductionsList(token, function(response) {
                        setProductRequests(response);
                    });
                }}
                lang={lang}
            />

            <CompanyNotProvidedModal
                visible={companyAlarmState}
                alarmType={companyAlarmType}
                onRequestClose={onCAlarmRequestClose}
                lang={lang}
            />
            
            <div className="RequestsView__header-container" style={{marginBottom:"16px"}}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="RequestsView__title-container">
                        <p className="RequestsView__title">{cab_lang[19][lang]}</p>
                        <p className="RequestsView__counter">{cab_lang[27][lang]} {selectedTab === 0 ? productRequests.length : serviceRequests.length} {/*  позици{parseEnding(selectedTab === 0 ? productRequests.length : serviceRequests.length)} */}</p>
                    </div>

                    {tabSliderVisible &&
                    <SliderPicker
                        items={["Товар", "Услуги"]}
                        value={selectedTab}
                        onChange={switchTab}
                        style={{
                            marginLeft: 24,
                            marginTop: 4
                        }}
                    />
                    }
                </div>

                <div className="RequestsView__action-container">
                    <div 
                        className="RequestsView__action-filter"
                        style={filterState ? {backgroundColor: "#e0e0e0"} : {}}
                        onClick={() => setFilterState(!filterState)}
                    >
                        <p className="RequestsView__action-filter-label" style={filterState ? {color: "#525252"} : {}}>{cab_lang[8][lang]}</p>
                        {filterState ? 
                        <IoClose
                            size={24}
                            color="#525252"
                            className="RequestsView__action-filter-icon"
                            style={{ cursor: 'pointer' }}
                        />
                        :
                        <IoSearch
                            size={20}
                            color="#C6C6C6"
                            className="RequestsView__action-filter-icon"
                            style={{ cursor: 'pointer' }}
                        />
                        }
                    </div>
                    
                    <div className="RequestsView__action-add"
                        onClick={onCreateRequest}
                    >
                        <p className="RequestsView__action-add-label">{cab_lang[9][lang]}</p>
                        <p className="RequestsView__action-add-icon">+</p>
                    </div>
                </div>
            </div>

            {selectedTab === 0 ? 
            <>
                {
                    filterState&&
                    <FiltersView
                        onResponse={_filters => {
                            console.log(_filters);
                            applyFilters(_filters);
                        }}
                        style={{
                            marginLeft:"64px"
                        }}
                    />
                }
                <ProductsListView filterState={filterState} requests={productRequests} token={token} onCreateRequest={onCreateRequest} onCalledRefresh={onCalledRefresh} trigger={trigger} onProdEditCall={onProdEditCall} lang={lang} />

                <p className="MainPage__pages-label" style={{marginTop:"0"}}>{(currentPage-1)*10+1}-{(currentPage-1)*10+productRequests.length} / {count}</p>
                <div className="MainPage__pages-control">
                    <IoChevronBack
                        color={prevPage !== currentPage ? "#161616" : "#A8A8A8"}
                        size={16}
                        style={prevPage !== currentPage ? {cursor:"pointer"} : {cursor:"default"}}
                        onClick={() => {
                            if(prevPage !== currentPage) {
                                loadPage(prevPage);
                            }
                        }}
                    />
                    <p
                        style={pages > 1 ? {color: "#161616"} : {color: "#A8A8A8"}}
                    >{currentPage} / {pages}</p>
                    <IoChevronForward
                        color={nextPage !== currentPage ? "#161616" : "#A8A8A8"}
                        size={16}
                        style={nextPage !== currentPage ? {cursor:"pointer"} : {cursor:"default"}}
                        onClick={() => {
                            if(nextPage !== currentPage) {
                                loadPage(nextPage);
                            }
                        }}
                    />
                </div>
            </>
            :
            <div/>
            }
        </div>
    );
}

function ProductsListView({ requests, token, onCreateRequest, onCalledRefresh, trigger, onProdEditCall, lang=1 }) {

    const [filterActivity, setFilterActivity] = useState(0); // 0-3
    const activityValues = ["active", "archived", "draft"];

    const [refreshTrigger, setRefreshTrigger] = useState(0);
    useEffect(() => {
        setRefreshTrigger(1-refreshTrigger);
    }, [trigger]);

    

    /*const [filterSubState, setFilterSubState] = useState(true);

    const [filerVolume, setFilterVolume] = useState(0);

    const [filterTrigger, setFilterTrigger] = useState(0);

    useEffect(() => {
        setFilteredRequests(requests);
    }, [requests]);

    useEffect(() => {
        setFilteredRequests(requests);
    }, [trigger]);

    useEffect(() => {
        let filtered = [];
        if(filterState) {
            requests.forEach(request => {
                if(request.category === filterCategory && request.name === filterNaming && request.state === activityValues[filterActivity]) {
                    filtered.push(request);
                }
            });

            setFilteredRequests(filtered);
        } else {
            requests.forEach(request => {
                //console.log(request.state);
                if(request.state === activityValues[filterActivity]) {
                    filtered.push(request);
                }
            });

            setFilteredRequests(filtered);
        }
    }, [filterTrigger]);

    useEffect(() => {
        setFilterTrigger(1-filterTrigger);
    }, [filterActivity]);*/

    return(
        <>
            {requests.length === 0 ? 
            <>
                <div className="RequestsView__products-table">
                    <div className="RequestsView__products-table-header">
                        <p 
                            className="RequestsView__products-table-column-label"
                            style={{
                                marginLeft: "32px"
                            }}
                        >{cab_lang[20][lang]}</p>

                        <p className="RequestsView__products-table-column-label">{cab_lang[14][lang]}</p>

                        <p className="RequestsView__products-table-column-label">{cab_lang[15][lang]}</p>

                        <p className="RequestsView__products-table-column-label">{cab_lang[23][lang]}</p>

                        <p className="RequestsView__products-table-column-label">{cab_lang[13][lang]}</p>

                        <SliderPicker
                            items={[cab_lang[10][lang], cab_lang[11][lang], cab_lang[12][lang]]}
                            value={filterActivity}
                            onChange={setFilterActivity}
                            style={{
                                width: "294px",
                                alignSelf: 'center',
                                justifySelf: 'flex-end',
                                marginRight: "4px"
                            }}
                            labelStyles={[
                                {
                                    color: (filterActivity===0?"#0F62FE":"#A8A8A8"),
                                    fontWeight: "700",
                                    letterSpacing: "0.32px"
                                },
                                {
                                    color: (filterActivity===1?"#0F62FE":"#A8A8A8"),
                                    fontWeight: "700",
                                    letterSpacing: "0.32px"
                                },
                                {
                                    color: "#525252",
                                    fontWeight: "700",
                                    letterSpacing: "0.32px"
                                }
                            ]}
                        />
                    </div>
                </div>

                <div className="RequestsView__nothing-container">
                    <p className="RequestsView__nothing-header">{cab_lang[16][lang]}</p>
                    <p className="RequestsView__nothing-text" style={{maxWidth:"60%"}}>
                        {cab_lang[17][lang]}
                    </p>
                    <BlueButton
                        text={cab_lang[18][lang]}
                        style={{
                            width: 288,
                            height: 40
                        }}
                        textStyle={{
                            fontWeight: "700",
                            lineHeight: 18,
                            letterSpacing: 0.32
                        }}
                        onClick={onCreateRequest}
                    />
                </div>
            </>
            
            :
            <div className="RequestsView__products-table">
                <div className="RequestsView__products-table-header">
                    <p 
                        className="RequestsView__products-table-column-label"
                        style={{
                            marginLeft: "32px"
                        }}
                    >{cab_lang[20][lang]}</p>

                    <p className="RequestsView__products-table-column-label">{cab_lang[14][lang]}</p>

                    <p className="RequestsView__products-table-column-label">{cab_lang[15][lang]}</p>

                    <p className="RequestsView__products-table-column-label">Название склада</p>

                    <p className="RequestsView__products-table-column-label">{cab_lang[13][lang]}</p>

                    <SliderPicker
                        items={[cab_lang[10][lang], cab_lang[11][lang], cab_lang[12][lang]]}
                        value={filterActivity}
                        onChange={setFilterActivity}
                        style={{
                            width: "294px",
                            alignSelf: 'center',
                            justifySelf: 'flex-end',
                            marginRight: "4px"
                        }}
                        labelStyles={[
                            {
                                color: (filterActivity===0?"#0F62FE":"#A8A8A8"),
                                fontWeight: "700",
                                letterSpacing: "0.32px"
                            },
                            {
                                color: (filterActivity===1?"#0F62FE":"#A8A8A8"),
                                fontWeight: "700",
                                letterSpacing: "0.32px"
                            },
                            {
                                color: "#525252",
                                fontWeight: "700",
                                letterSpacing: "0.32px"
                            }
                        ]}
                    />
                </div>

                <div className="RequestsView__wrapper">
                {requests.map((product, index) => {
                    if(product.state === activityValues[filterActivity]) {
                        return(
                            <TableLine product={product} key={index} token={token} last={index ===requests.length - 1} callRefresh={onCalledRefresh} onProdEditCall={() => onProdEditCall(index)} />
                        );
                    }
                })}
                </div>
            </div>
        }
        </>
    );
}

function TableLine({ product, token, last, callRefresh, onProdEditCall }) {
    const [opened, setOpenedState] = useState(false);
    const [deleteModalState, setDeleteModalState] = useState(false);
    const [deleted, setDeletedState] = useState(false);

    const [stockName, setStockName] = useState("");

    const handleCloseModal = () => {
        setDeleteModalState(false);
        //console.log(product);
    }

    useEffect(() => {
        stockService.getStockInfo(
            product.stock, 
            token, 
            function(response) {
                //console.log(response.stock);
                setStockName(response.stock.name);
            }
        );
    }, []);

    const handleAcceptModal = () => {
        let data = { pk: product.pk, state: "deleted" };
        console.log(data);
        console.log(token);
        productionService.patchProduction(
            data, 
            token,
            function(response) {
                if(response.pk !== undefined) {
                    setDeletedState(true);
                    callRefresh();
                }
            }
        );
    }

    function parseDate(date) {
        return `${date.slice(8, 10)}.${date.slice(5, 7)}.${date.slice(2, 4)}`
    }

    if(deleted) {
        return(<div/>);
    }

    function parseParamValue(param) {
        let str = "";
        if(param.format !== "picker") {
            switch(param.filter) {
                case "from":
                    str += "> ";
                    break;
                case "to":
                    str += "< ";
                    break;
                default:
                    break;
            }
    
            str += param.value;
            str += " " + param.format;
        } else {
            str += param.base_values[param.value];
        }

        return str;
    }

    const handleArchive = () => {
        let data = { pk: product.pk, state: "archived" };
        productionService.patchProduction(
            data, 
            token,
            function(response) {
                if(response.pk !== undefined) {
                    callRefresh();
                }
            }
        );
    }

    return(
        <>
            <DeleteModal
                label="Удалить продукцию"
                content="Вы действительно хотите безвозратно удалить заявку? Заявку можно снять с публикации, перенеся ее в архив"
                visible={deleteModalState}
                onClose={handleCloseModal}
                onAccept={handleAcceptModal}
                onCancel={handleArchive}
                style={{position: 'absolute'}}
                cancelLabel="Нет, архивировать"
                commitLabel="Да, удалить"
            />
        
            <div className={"RequestsView__product-line"+(opened?"-opened":"")} onClick={() => setOpenedState(!opened)}>
                <p className="RequestsView__product-volume">{product.volume} т</p>

                <div className="RequestsView__product-value-wrapper">
                    <p className="RequestsView__product-value">{product.category}</p>
                    {opened &&
                    (product.params.indexes[0] !== undefined &&
                    <div className="RequestsView__product-value-filter-wrapper">
                        {product.params.indexes[0].format !== "picker" && <p className="RequestsView__product-value-filter-label">{product.params.indexes[0].name.slice(0, 20) + (product.params.indexes[0].name.length > 20 ? "." : "")}:</p>}
                        <p className="RequestsView__product-value-filter-value">{parseParamValue(product.params.indexes[0])}</p>
                    </div>
                    )
                    }
                    {opened &&
                    (product.params.indexes[1] !== undefined &&
                    <div className="RequestsView__product-value-filter-wrapper" style={{marginTop: "66px", position:"absolute"}}>
                        {product.params.indexes[1].format !== "picker" && <p className="RequestsView__product-value-filter-label">{product.params.indexes[1].name.slice(0, 20) + (product.params.indexes[1].name.length > 20 ? "." : "")}:</p>}
                        <p className="RequestsView__product-value-filter-value">{parseParamValue(product.params.indexes[1])}</p>
                    </div>
                    )
                    }
                </div>

                <div className="RequestsView__product-value-wrapper">
                    <p className="RequestsView__product-value">{product.name}</p>
                    {opened &&
                    (product.params.indexes[2] !== undefined &&
                    <div className="RequestsView__product-value-filter-wrapper">
                        {product.params.indexes[2].format !== "picker" && <p className="RequestsView__product-value-filter-label">{product.params.indexes[2].name.slice(0, 20) + (product.params.indexes[2].name.length > 20 ? "." : "")}:</p>}
                        <p className="RequestsView__product-value-filter-value">{parseParamValue(product.params.indexes[2])}</p>
                    </div>
                    )
                    }
                    {opened &&
                    (product.params.indexes[3] !== undefined &&
                    <div className="RequestsView__product-value-filter-wrapper" style={{marginTop: "66px", position:"absolute"}}>
                        {product.params.indexes[3].format !== "picker" && <p className="RequestsView__product-value-filter-label">{product.params.indexes[3].name.slice(0, 20) + (product.params.indexes[3].name.length > 20 ? "." : "")}:</p>}
                        <p className="RequestsView__product-value-filter-value">{parseParamValue(product.params.indexes[3])}</p>
                    </div>
                    )
                    }
                </div>

                <div className="RequestsView__product-value-wrapper">
                    <p className="RequestsView__product-value">{stockName}</p>
                    {opened &&
                    (product.params.indexes[4] !== undefined &&
                    <div className="RequestsView__product-value-filter-wrapper">
                        {product.params.indexes[4].format !== "picker" && <p className="RequestsView__product-value-filter-label">{product.params.indexes[4].name.slice(0, 20) + (product.params.indexes[4].name.length > 20 ? "." : "")}:</p>}
                        <p className="RequestsView__product-value-filter-value">{parseParamValue(product.params.indexes[4])}</p>
                    </div>
                    )
                    }
                    {opened &&
                    (product.params.indexes[5] !== undefined &&
                    <div className="RequestsView__product-value-filter-wrapper" style={{marginTop: "66px", position:"absolute"}}>
                        {product.params.indexes[5].format !== "picker" && <p className="RequestsView__product-value-filter-label">{product.params.indexes[5].name.slice(0, 20) + (product.params.indexes[5].name.length > 20 ? "." : "")}:</p>}
                        <p className="RequestsView__product-value-filter-value">{parseParamValue(product.params.indexes[5])}</p>
                    </div>
                    )
                    }
                </div>

                <div className="RequestsView__product-value-wrapper">
                    <p className="RequestsView__product-value">{parseDate(product.created_at)}</p>
                    {opened &&
                    (product.params.indexes[6] !== undefined &&
                    <div className="RequestsView__product-value-filter-wrapper">
                        {product.params.indexes[6].format !== "picker" && <p className="RequestsView__product-value-filter-label">{product.params.indexes[6].name.slice(0, 20) + (product.params.indexes[6].name.length > 20 ? "." : "")}:</p>}
                        <p className="RequestsView__product-value-filter-value">{parseParamValue(product.params.indexes[6])}</p>
                    </div>
                    )
                    }
                    {opened &&
                    (product.params.indexes[7] !== undefined &&
                    <div className="RequestsView__product-value-filter-wrapper" style={{marginTop: "66px", position:"absolute"}}>
                        {product.params.indexes[7].format !== "picker" && <p className="RequestsView__product-value-filter-label">{product.params.indexes[7].name.slice(0, 20) + (product.params.indexes[7].name.length > 20 ? "." : "")}:</p>}
                        <p className="RequestsView__product-value-filter-value">{parseParamValue(product.params.indexes[7])}</p>
                    </div>
                    )
                    }
                </div>

                <div className="RequestsView__product-line-actions-wrapper">
                    <div className="RequestsView__product-line-action-icon-container" onClick={() => setDeleteModalState(true)}>
                        <img src={deleteIcon} className="RequestsView__product-line-action-icon" />
                    </div>

                    <div className="RequestsView__product-line-action-icon-container" onClick={onProdEditCall}>
                        <img src={editIcon} className="RequestsView__product-line-action-icon" />
                    </div>

                    <IoChevronDown
                        color="#0F62FE"
                        size={16}
                        className={"RequestsView__product-line-chevron"+(opened?"-rotated":"")}
                        onClick={() => setOpenedState(!opened)}
                    />
                </div>
            </div>

            {!last && <div className="RequestsView__product-line-divider"/>}
        </>
    );
}

export function RequestsView({ token, lang }) {
    const [productRequests, setProductRequests] = useState([]);
    const [serviceRequests, setServiceRequests] = useState([]);
    const [trigger, setTrigger] = useState(0);

    const [productModalState, setProductModalState] = useState(false);
    const toggleClose = () => {
        setProductModalState(false);
    }

    const [tabSliderVisible, setTabSliderState] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0); // 0 , 1
    const switchTab = (value) => {
        setSelectedTab(value);
    }

    const [filterState, setFilterState] = useState(false);

    useEffect(() => {
        productionRequestService.getProductionRequestsList(token, function(response) {
            setProductRequests(response);
        });

        fetchCompanyPermissions();
    }, []);

    const [pages, setPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [prevPage, setPrevPage] = useState(1);
    const [nextPage, setNextPage] = useState(1);
    const [count, setCount] = useState(0);

    const [filters, setFilters] = useState({});

    function loadFirstPage() {
        userService.getUserKey(token, function(selfUser) {
            productionRequestService.getPagedFilteredProductionsList(token, {"parent":selfUser.pk}, 1, function(response) {
                setProductRequests(response.data);
                setCount(response.count);
                setCurrentPage(1);
                setPages(response.numpages);
                setPrevPage(response.prevpage);
                setNextPage(response.nextpage);
            });
        });
    }
    
    function applyFilters(_filters) {
        setFilters(_filters);
        userService.getUserKey(token, function(selfUser) {
            console.log(selfUser);
            productionRequestService.getPagedFilteredProductionsList(token, Object.assign({"parent":selfUser.pk}, _filters), 1, function(response) {
                setProductRequests(response.data);
                setCount(response.count);
                setCurrentPage(1);
                setPages(response.numpages);
                setPrevPage(response.prevpage);
                setNextPage(response.nextpage);
            });
        });
    }

    function loadPage(index) {
        userService.getUserKey(token, function(selfUser) {
            productionRequestService.getPagedFilteredProductionsList(token, Object.assign({"parent":selfUser.pk}, filters), index, function(response) {
                setProductRequests(response.data);
                setCount(response.count);
                setCurrentPage(1);
                setPages(response.numpages);
                setPrevPage(response.prevpage);
                setNextPage(response.nextpage);
                return;
            });
        });
    }

    async function fetchCompanyPermissions() {
        companyService.getCompaniesList(token, function(response) {
            let data = response.companies[0];
            console.log(data.role);
            switch(data.role) {
                case "buyer":
                    setSelectedTab(0);
                    setTabSliderState(false);
                    console.log("falsed");
                    break;
                case "seller":
                    setSelectedTab(0);
                    setTabSliderState(false);
                    break;
                case "service":
                    setSelectedTab(1);
                    setTabSliderState(false);
                    break;
                default:
                    if(data.role.split(" ").length > 1 && data.role.split(" ").includes("service")) {
                        setSelectedTab(0);
                        setTabSliderState(true);
                    } else {
                        setSelectedTab(0);
                        setTabSliderState(false);
                    }
                    break;
            }
        });
    }

    function parseEnding(value) {
        if(value === 0) {
            return "ок";
        }

        if(value === 1) {
            return "ка";
        }

        if(value < 5) {
            return "ки";
        }

        return "ок";
    }

    const onCreateRequest = () => {
        companyService.getCompaniesList(token, function(response) {
            if(response.companies.length > 0) {
                let role = response.companies[0].role;
                if(role.split(" ").includes("buyer")) {
                    setProductModalState(true);
                } else {
                    setCompanyAlarmType("not-allowed");
                }
            } else {
                setCompanyAlarmType("not-provided");
            }
        });
    }

    const onCalledRefresh = () => {
        userService.getUserKey(token, function(selfUser) {
            productionRequestService.getPagedFilteredProductionsList(token, Object.assign({"parent":selfUser.pk}, filters), currentPage, function(response) {
                setProductRequests(response.data);
                setCount(response.count);
                setCurrentPage(1);
                setPages(response.numpages);
                setPrevPage(response.prevpage);
                setNextPage(response.nextpage);
                return;
            });
        });
    }

    const [companyAlarmState, setCompanyAlarmState] = useState(false);
    const [companyAlarmType, setCompanyAlarmType] = useState("");
    const onCAlarmRequestClose = () => {
        setCompanyAlarmState(false);
    }

    useEffect(() => {
        if(companyAlarmType !== "") {
            setCompanyAlarmState(true);
        }
    }, [companyAlarmType]);

    const [editProductModalState, setEditProductModalState] = useState(false);
    const toggleEditClose = () => {
        setEditProductModalState(false);
        setEditProdData(null);
    }

    const [editProdData, setEditProdData] = useState(null);
    const onProdEditCall = (index) => {
        setEditProdData(productRequests[index]);
    }

    useEffect(() => {
        if(editProdData !== null) {
            setEditProductModalState(true);
            console.log(editProdData);
        }
    }, [editProdData]);

    return(
        <div className="RequestsView__root">
            <ProductRequestModal
                visible={productModalState}
                onRequestClose={toggleClose}
                token={token}
                onComplete={() => {
                    productionRequestService.getProductionRequestsList(token, function(response) {
                        setProductRequests(response);
                    });
                }}
                lang={lang}
            />

            <EditProductRequestModal
                visible={editProductModalState}
                onRequestClose={toggleEditClose}
                token={token}
                initialData={editProdData}
                onComplete={() => {
                    productionRequestService.getProductionRequestsList(token, function(response) {
                        setProductRequests(response);
                    });
                }}
                lang={lang}
            />

            <CompanyNotProvidedModal
                visible={companyAlarmState}
                alarmType={companyAlarmType}
                onRequestClose={onCAlarmRequestClose}
                lang={lang}
            />
            
            <div className="RequestsView__header-container" style={{marginBottom:"16px"}}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className="RequestsView__title-container">
                        <p className="RequestsView__title">{cab_lang[3][lang]}</p>
                        <p className="RequestsView__counter">{cab_lang[27][lang]} {selectedTab === 0 ? productRequests.length : serviceRequests.length} {/* заяв{parseEnding(selectedTab === 0 ? productRequests.length : serviceRequests.length)} */}</p>
                    </div>

                    {tabSliderVisible &&
                    <SliderPicker
                        items={["Товар", "Услуги"]}
                        value={selectedTab}
                        onChange={switchTab}
                        style={{
                            marginLeft: 24,
                            marginTop: 4
                        }}
                    />
                    }
                </div>

                <div className="RequestsView__action-container">
                    <div 
                        className="RequestsView__action-filter"
                        style={filterState ? {backgroundColor: "#e0e0e0"} : {}}
                        onClick={() => setFilterState(!filterState)}
                    >
                        <p className="RequestsView__action-filter-label" style={filterState ? {color: "#525252"} : {}}>{cab_lang[8][lang]}</p>
                        {filterState ? 
                        <IoClose
                            size={24}
                            color="#525252"
                            className="RequestsView__action-filter-icon"
                            style={{ cursor: 'pointer' }}
                        />
                        :
                        <IoSearch
                            size={20}
                            color="#C6C6C6"
                            className="RequestsView__action-filter-icon"
                            style={{ cursor: 'pointer' }}
                        />
                        }
                    </div>
                    
                    <div className="RequestsView__action-add"
                        onClick={onCreateRequest}
                    >
                        <p className="RequestsView__action-add-label">{cab_lang[9][lang]}</p>
                        <p className="RequestsView__action-add-icon">+</p>
                    </div>
                </div>
            </div>

            {selectedTab === 0 ? 
            <>
                {
                    filterState&&
                    <FiltersView
                        onResponse={_filters => {
                            applyFilters(_filters);
                        }}
                        style={{marginLeft:"64px"}}
                    />
                }
                <ProductRequestsView filterState={filterState} requests={productRequests} token={token} onCreateRequest={onCreateRequest} onCalledRefresh={onCalledRefresh} trigger={trigger} onProdEditCall={onProdEditCall} lang={lang} />

                <p className="MainPage__pages-label" style={{marginTop:"0"}}>{(currentPage-1)*10+1}-{(currentPage-1)*10+productRequests.length} / {count}</p>
                <div className="MainPage__pages-control">
                    <IoChevronBack
                        color={prevPage !== currentPage ? "#161616" : "#A8A8A8"}
                        size={16}
                        style={prevPage !== currentPage ? {cursor:"pointer"} : {cursor:"default"}}
                        onClick={() => {
                            if(prevPage !== currentPage) {
                                loadPage(prevPage);
                            }
                        }}
                    />
                    <p
                        style={pages > 1 ? {color: "#161616"} : {color: "#A8A8A8"}}
                    >{currentPage} / {pages}</p>
                    <IoChevronForward
                        color={nextPage !== currentPage ? "#161616" : "#A8A8A8"}
                        size={16}
                        style={nextPage !== currentPage ? {cursor:"pointer"} : {cursor:"default"}}
                        onClick={() => {
                            if(nextPage !== currentPage) {
                                loadPage(nextPage);
                            }
                        }}
                    />
                </div>
            </>
            :
            <div/>
            }
        </div>
    );
}

function ProductRequestsView({ requests, token, onCreateRequest, onCalledRefresh, trigger, onProdEditCall, lang }) {
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    useEffect(() => {
        setRefreshTrigger(1-refreshTrigger);
    }, [trigger]);
    
    const [filterActivity, setFilterActivity] = useState(0); // 0-3
    const activityValues = ["active", "archived", "draft"];

    useEffect(() => {
        indexLanguage(function(r) {
            setIndexLang(r);
        });
    }, []);

    const [indexLang, setIndexLang] = useState({});

    return(
        <>
            {requests.length === 0 ? 
            <div className="RequestsView__nothing-container">
                <p className="RequestsView__nothing-header">{cab_lang[5][lang]}</p>
                <p className="RequestsView__nothing-text" style={{maxWidth:"70%"}}>
                    {cab_lang[6][lang]}
                </p>
                <BlueButton
                    text={cab_lang[7][lang]}
                    style={{
                        width: 288,
                        height: 40
                    }}
                    textStyle={{
                        fontWeight: "700",
                        lineHeight: 18,
                        letterSpacing: 0.32
                    }}
                    onClick={onCreateRequest}
                />
            </div>
            :
            <div className="RequestsView__products-table">
                <div className="ProdRequestsView__products-table-header">
                    <p 
                        className="RequestsView__products-table-column-label"
                        style={{
                            marginLeft: "32px"
                        }}
                    >{cab_lang[20][lang]}</p>

                    <p className="RequestsView__products-table-column-label">{cab_lang[14][lang]}</p>

                    <p className="RequestsView__products-table-column-label">{cab_lang[15][lang]}</p>

                    <p className="RequestsView__products-table-column-label">{market_lang[23][lang]}</p>

                    <p className="RequestsView__products-table-column-label">{modal_lang[22][lang]}</p>
                    
                    <p className="RequestsView__products-table-column-label">{market_lang[22][lang]}</p>

                    <SliderPicker
                        items={[cab_lang[10][lang], cab_lang[11][lang], cab_lang[12][lang]]}
                        value={filterActivity}
                        onChange={setFilterActivity}
                        style={{
                            width: "294px",
                            alignSelf: 'center',
                            justifySelf: 'flex-end',
                            marginRight: "4px"
                        }}
                        labelStyles={[
                            {
                                color: (filterActivity===0?"#0F62FE":"#A8A8A8"),
                                fontWeight: "700",
                                letterSpacing: "0.32px"
                            },
                            {
                                color: (filterActivity===1?"#0F62FE":"#A8A8A8"),
                                fontWeight: "700",
                                letterSpacing: "0.32px"
                            },
                            {
                                color: "#525252",
                                fontWeight: "700",
                                letterSpacing: "0.32px"
                            }
                        ]}
                    />
                </div>

                <div className="ProdRequestsView__wrapper">
                    {requests.map((product, index) => {
                        if(product.state === activityValues[filterActivity]) {
                            return(
                                <RequestTableLine product={product} key={index} token={token} last={index === requests.length - 1} callRefresh={onCalledRefresh} onProdEditCall={() => onProdEditCall(index)} lang={lang} indexLang={indexLang} />
                            );
                        }
                    })}
                </div>
            </div>
        }
        </>
    );
}

function RequestTableLine({ product, token, last, callRefresh, onProdEditCall, lang=1, indexLang }) {
    const [opened, setOpenedState] = useState(false);
    const [deleteModalState, setDeleteModalState] = useState(false);
    const [deleted, setDeletedState] = useState(false);

    const handleCloseModal = () => {
        setDeleteModalState(false);
        //console.log(product);
    }

    const handleAcceptModal = () => {
        let data = { pk: product.pk, state: "deleted" };
        productionRequestService.patchProductionRequest(
            data, 
            token,
            function(response) {
                if(response.pk !== undefined) {
                    setDeletedState(true);
                    callRefresh();
                }
            }
        );
    }

    const [translatedCat, setTCat] = useState("");
    const [translatedName, setTName] = useState("");

    useEffect(() => {
        translateCatAndItem(product.name, function(r) {
            setTCat(r.catLang[lang]);
            setTName(r.nameLang[lang]);
        });
    }, [product]);

    useEffect(() => {
        if(product !== undefined) {
            translateCatAndItem(product.name, function(r) {
                setTCat(r.catLang[lang]);
                setTName(r.nameLang[lang]);
            });
        }
    }, [lang]);

    function parseDate(date) {
        return `${date.slice(8, 10)}.${date.slice(5, 7)}.${date.slice(2, 4)}`
    }

    function parseShipmentPeriod(p1, p2) {
        let monthLangDict = [
            [
                "Янв",
                "Jan",
                "1月"
            ],
            [
                "Фев",
                "Feb",
                "2月"
            ],
            [
                "Мар",
                "Mar",
                "3月"
            ],
            [
                "Апр",
                "Apr",
                "4月"
            ],
            [
                "Май",
                "May",
                "5月"
            ],
            [
                "Июн",
                "Jun",
                "6月"
            ],
            [
                "Июл",
                "Jul",
                "7月"
            ],
            [
                "Авг",
                "Aug",
                "8月"
            ],
            [
                "Сен",
                "Sep",
                "9月"
            ],
            [
                "Окт",
                "Oct",
                "10月"
            ],
            [
                "Нояб",
                "Nov",
                "11月"
            ],
            [
                "Дек",
                "Dec",
                "12月"
            ]
        ];

        let _p1 = (p1.length < 9 ? p1 : (p1.slice(0, 3)+". "+p1.slice(p1.length-4, p1.length)));
        let _p2 = (p2.length < 9 ? p2 : (p2.slice(0, 3)+". "+p2.slice(p2.length-4, p2.length)));

        _p1 = _p1.replaceAll("Янв", monthLangDict[0][lang]);
        _p1 = _p1.replaceAll("Фев", monthLangDict[1][lang]);
        _p1 = _p1.replaceAll("Мар", monthLangDict[2][lang]);
        _p1 = _p1.replaceAll("Апр", monthLangDict[3][lang]);
        _p1 = _p1.replaceAll("Май", monthLangDict[4][lang]);
        _p1 = _p1.replaceAll("Июн", monthLangDict[5][lang]);
        _p1 = _p1.replaceAll("Июл", monthLangDict[6][lang]);
        _p1 = _p1.replaceAll("Авг", monthLangDict[7][lang]);
        _p1 = _p1.replaceAll("Сен", monthLangDict[8][lang]);
        _p1 = _p1.replaceAll("Окт", monthLangDict[9][lang]);
        _p1 = _p1.replaceAll("Ноя", monthLangDict[10][lang]);
        _p1 = _p1.replaceAll("Дек", monthLangDict[11][lang]);

        _p2 = _p2.replaceAll("Янв", monthLangDict[0][lang]);
        _p2 = _p2.replaceAll("Фев", monthLangDict[1][lang]);
        _p2 = _p2.replaceAll("Мар", monthLangDict[2][lang]);
        _p2 = _p2.replaceAll("Апр", monthLangDict[3][lang]);
        _p2 = _p2.replaceAll("Май", monthLangDict[4][lang]);
        _p2 = _p2.replaceAll("Июн", monthLangDict[5][lang]);
        _p2 = _p2.replaceAll("Июл", monthLangDict[6][lang]);
        _p2 = _p2.replaceAll("Авг", monthLangDict[7][lang]);
        _p2 = _p2.replaceAll("Сен", monthLangDict[8][lang]);
        _p2 = _p2.replaceAll("Окт", monthLangDict[9][lang]);
        _p2 = _p2.replaceAll("Ноя", monthLangDict[10][lang]);
        _p2 = _p2.replaceAll("Дек", monthLangDict[11][lang]);

        return _p1 + " - " + _p2;
    }

    if(deleted) {
        return(<div/>);
    }

    function parseParamValue(param) {
        let str = "";
        if(param.format !== "picker") {
            switch(param.filter) {
                case "from":
                    str += "> ";
                    break;
                case "to":
                    str += "< ";
                    break;
                default:
                    break;
            }
    
            str += param.value;
            str += param.format !== "%" && param.format !== "" ? indexLang.lang[param.format][lang] : (param.format);
        } else {
            if(indexLang.lang[param.base_values[0]] !== undefined) {
                str += indexLang.lang[param.base_values[param.value]][lang];
            } else {
                str += param.base_values[param.value];
            }
        }

        return str;
    }

    const handleArchive = () => {
        let data = { pk: product.pk, state: "archived" };
        console.log(data);
        console.log(token);
        productionRequestService.patchProductionRequest(
            data, 
            token,
            function(response) {
                if(response.pk !== undefined) {
                    callRefresh();
                }
            }
        );
    }

    return(
        <>
            <DeleteModal
                label="Удалить заявку"
                content="Вы действительно хотите безвозратно удалить заявку? Заявку можно снять с публикации, перенеся ее в архив"
                visible={deleteModalState}
                onClose={handleCloseModal}
                onAccept={handleAcceptModal}
                onCancel={handleArchive}
                style={{position: 'absolute'}}
                cancelLabel="Нет, архивировать"
                commitLabel="Да, удалить"
            />
        
            <div className={"ProdRequestsView__product-line"+(opened?"-opened":"")} onClick={() => setOpenedState(!opened)}>
                <p className="RequestsView__product-volume">{product.volume} т</p>

                <div className="RequestsView__product-value-wrapper">
                    <p className="RequestsView__product-value">{translatedCat}</p>
                    {opened &&
                    (product.params.indexes[0] !== undefined &&
                    <div className="RequestsView__product-value-filter-wrapper">
                        {product.params.indexes[0].format !== "picker" && <p className="RequestsView__product-value-filter-label">{indexLang.lang[product.params.indexes[0].name][lang].slice(0, 16) + (indexLang.lang[product.params.indexes[0].name][lang].length > 20 ? "." : "")}:</p>}
                        <p className="RequestsView__product-value-filter-value">{parseParamValue(product.params.indexes[0])}</p>
                    </div>
                    )
                    }
                    {opened &&
                    (product.params.indexes[1] !== undefined &&
                    <div className="RequestsView__product-value-filter-wrapper" style={{marginTop: "66px", position:"absolute"}}>
                        {product.params.indexes[1].format !== "picker" && <p className="RequestsView__product-value-filter-label">{indexLang.lang[product.params.indexes[1].name][lang].slice(0, 16) + (indexLang.lang[product.params.indexes[1].name][lang].length > 20 ? "." : "")}:</p>}
                        <p className="RequestsView__product-value-filter-value">{parseParamValue(product.params.indexes[1])}</p>
                    </div>
                    )
                    }
                </div>

                <div className="RequestsView__product-value-wrapper">
                    <p className="RequestsView__product-value">{translatedName}</p>
                    {opened &&
                    (product.params.indexes[2] !== undefined &&
                    <div className="RequestsView__product-value-filter-wrapper">
                        {product.params.indexes[2].format !== "picker" && <p className="RequestsView__product-value-filter-label">{indexLang.lang[product.params.indexes[2].name][lang].slice(0, 16) + (indexLang.lang[product.params.indexes[2].name][lang].length > 20 ? "." : "")}:</p>}
                        <p className="RequestsView__product-value-filter-value">{parseParamValue(product.params.indexes[2])}</p>
                    </div>
                    )
                    }
                    {opened &&
                    (product.params.indexes[3] !== undefined &&
                    <div className="RequestsView__product-value-filter-wrapper" style={{marginTop: "66px", position:"absolute"}}>
                        {product.params.indexes[3].format !== "picker" && <p className="RequestsView__product-value-filter-label">{indexLang.lang[product.params.indexes[3].name][lang].slice(0, 16) + (indexLang.lang[product.params.indexes[3].name][lang].length > 20 ? "." : "")}:</p>}
                        <p className="RequestsView__product-value-filter-value">{parseParamValue(product.params.indexes[3])}</p>
                    </div>
                    )
                    }
                </div>

                <div className="RequestsView__product-value-wrapper">
                    <p className="RequestsView__product-value">{parseShipmentPeriod(product.period1, product.period2)}</p>
                    {opened &&
                    <div className="RequestsView__product-value-filter-wrapper">
                        <p className="RequestsView__product-value-filter-label">{modal_lang[28][lang]}:</p>
                        <p className="RequestsView__product-value-filter-value">{indexLang.lang !== undefined ? (indexLang.lang[product.package] !== undefined ? indexLang.lang[product.package][lang] : product.package) : product.package}</p>
                    </div>
                    }
                </div>

                <div className="RequestsView__product-value-wrapper">
                    <p className="RequestsView__product-value" style={{fontWeight:"700"}}>{product.basis} {product.station}</p>
                    {opened &&
                    <>
                        <div className="RequestsView__product-value-filter-wrapper">
                            <p className="RequestsView__product-value-filter-label">{modal_lang[26][lang].slice(0, 6)}.:</p>
                            <p className="RequestsView__product-value-filter-value">{indexLang.lang !== undefined ? indexLang.lang[product.ttype1][lang] : product.ttype1}</p>
                        </div>

                        <div className="RequestsView__product-value-filter-wrapper" style={{marginTop: "66px", position:"absolute"}}>
                            <p className="RequestsView__product-value-filter-label" style={{width: "45px"}}>{modal_lang[27][lang].slice(0, 6)}.:</p>
                            <p className="RequestsView__product-value-filter-value">{indexLang.lang !== undefined ? indexLang.lang[product.ttype2][lang] : product.ttype2}</p>
                        </div>
                    </>
                    }
                </div>

                <div className="RequestsView__product-value-wrapper">
                    <p className="RequestsView__product-value">{parseDate(product.created_at)}</p>
                    
                </div>

                <div className="RequestsView__product-line-actions-wrapper">
                    <div className="RequestsView__product-line-action-icon-container" onClick={() => setDeleteModalState(true)}>
                        <img src={deleteIcon} className="RequestsView__product-line-action-icon" />
                    </div>

                    <div className="RequestsView__product-line-action-icon-container" onClick={onProdEditCall}>
                        <img src={editIcon} className="RequestsView__product-line-action-icon" />
                    </div>

                    <IoChevronDown
                        color="#0F62FE"
                        size={16}
                        className={"RequestsView__product-line-chevron"+(opened?"-rotated":"")}
                        onClick={() => setOpenedState(!opened)}
                    />
                </div>
            </div>

            {!last && <div className="RequestsView__product-line-divider"/>}
        </>
    );
}