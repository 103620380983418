import { Outlet, Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import "../pagestyles/AccountPage.css";

// IMPORT ICONS
import { BsArrowLeftShort } from 'react-icons/bs'; 
import warningIcon from "../assets/warning.png";
import infoIcon from "../assets/info.png";

// IMPORT COMPONENTS
import { BlueButton } from '../components/Buttons';

import profile_file from "../lang/profile.json";

// IMPORT API
import UserService from '../services/UserService';
const userService = new UserService();

const profile_lang = profile_file.lang;

export default function AccountPage({ token, lang=1 }) {
    const [selectedTab, setSelectedTab] = useState(0);
    const [name, setName] = useState("");
    const location = useLocation();
    useEffect(() => {
        userService.getUser(token, function(userData) {
            setName(userData.user.first_name);
        });
        let path = location.pathname.split("/");
        if(path.length < 3) {
            setSelectedTab(0);
        } else {
            switch(path[2]) {
                case "company":
                    setSelectedTab(1);
                    break;
                case "feedback":
                    setSelectedTab(2);
                    break;
                default:
                    break;
            }
        }
    }, [location]);

    

    return (
        <>
        <div className="AccountPage__root">
            <p className="AccountPage__greeting">{profile_lang[1][lang]}{name !== "" ? `, ${name}` : ""}!</p>

            <div className="AccountPage__content-container">
                <div className="AccoutPage__content-left">
                    <div className="AccoutPage__menu-container">
                        <Link to="/account" style={{ textDecoration: 'none' }}>
                            <p className="AccountPage__menu-item">{profile_lang[6][lang]}</p>
                        </Link>

                        <Link to="/account/company" style={{ textDecoration: 'none' }}>
                            <p className="AccountPage__menu-item">{profile_lang[7][lang]}</p>
                        </Link>
                        
                        <Link to="/account/feedback" style={{ textDecoration: 'none' }}>
                            <p className="AccountPage__menu-item">{profile_lang[4][lang]}</p>
                        </Link>
                        
                        <div 
                            className="AccountPage__menu-indicator"
                            style={{ top: 21 + selectedTab*32 }}
                        />
                    </div>

                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <div className="AccountPage__back-to-market">
                            <BsArrowLeftShort
                                color="#000"
                                size={34}
                                className="AccountPage__back-to-market-icon"
                            />
                            <p className="AccountPage__back-to-market-text">{profile_lang[8][lang]}</p>
                        </div>
                    </Link>
                </div>

                <div className="AccountPage__content-right">
                    {/*
                    <div className="AccountPage__verification-container">
                        <img src={warningIcon} className="AccountPage__verification-warning-icon"/>
                        <p className="AccountPage__verification-title">Ограниченный доступ</p>
                        <img src={infoIcon} className="AccountPage__verification-info-icon"/>
                        <BlueButton
                            text="Пройти верификацию"
                            style={{
                                width: 232,
                                position: 'absolute',
                                right: 16
                            }}
                        />
                    </div>
                    */}

                    <div className="AccountPage__outlet">
                        <Outlet context={token} />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}