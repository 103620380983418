import axios from "axios";
import { API_URL } from "./ApiCredentials";

export default class CompanyService {
    constructor() {}

    addCompany(data, token, _callback) {
        const url = `${API_URL}/api/companies/add`;
        const headers = {
            'content-type': 'application/json',
            'Authorization': `Token ${token}`
        };

        axios.post(
            url,
            { company: data },
            { headers: headers }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback({});
            return;
        });
    }

    patchCompany(data, token, _callback) {
        const url = `${API_URL}/api/company`;
        const headers = {
            'content-type': 'application/json',
            'Authorization': `Token ${token}`
        };

        axios.patch(
            url,
            { "company": data },
            { headers: headers }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback({});
            return;
        });
    }

    getCompaniesList(token, _callback) {
        const url = `${API_URL}/api/companies/list`;
        const headers = {
            'content-type': 'application/json',
            'Authorization': `Token ${token}`
        };

        axios.get(
            url,
            { headers: headers }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback({});
            return;
        });
    }

    getCompany(id, token, _callback) {
        const url = `${API_URL}/api/company`;
        const headers = {
            'content-type': 'application/json',
            'Authorization': `Token ${token}`
        };

        axios.get(
            url,
            { headers: headers, params: {"pk":id} }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback({});
            return;
        });
    }
}