import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { init } from '@emailjs/browser';

// NAVIGATION BAR IMPORT
import NavigationBar from './components/NavigationBar.js';
import { LogInModal, SignUpModal, ProfileModal, RestorePasswordModal } from './components/Modals.js';

// PAGES IMPORT :: MAIN
import MainPage from './pages/MainPage.js';

// PAGES IMPORT :: ACCOUNT and nested tabs
import CabinetPage from './pages/CabinetPage.js';
import AccountPage from './pages/AccountPage.js';
import PersonalView from './pages/account/PersonalView.js';
import CompanyView from './pages/account/CompanyView.js';
import FeedbackView from './pages/account/FeedbackView.js';

// API IMPORT :: JWT COOKIE TRY
import UserService from './services/UserService.js';

// ROOT STYLES
import "./App.css";

const userService = new UserService();

function App() {
  // BASIC APP STATES
  const [loggedIn, setLoggedIn] = useState(false);
  const [authUser, setAuthUser] = useState(""); //'' ivan.galkin5066@gmail.com
  const [authToken, setAuthToken] = useState("");

  useEffect(() => {
    init('44HQiApvTqq-ZFlP7');

    userService.signInWithCookie(function(response) {
      if(response.user !== undefined) {
        console.log(response.user);
        setAuthToken(response.user.token);
        setAuthUser(response.user.email);
      }
    });

    fetchLanguage();
  }, []);

  // LOG IN MODAL STATE CONTROLS
  const [logInModalState, setLogInModalState] = useState(false);

  const onLogInClick = () => {
    console.log("log in");
    setLogInModalState(true);
  }

  const onLogInClose = () => {
    setLogInModalState(false);
  }

  const onLogInSuccess = (email, token) => {
    setAuthUser(email);
    setAuthToken(token);
  }

  const onSignUpSuccess = (email, token) => {
    setAuthUser(email);
    setAuthToken(token);
    console.log(token);
  }

  // SIGN UP MODAL STATE CONTROLS
  const [signUpModalState, setSignUpModalState] = useState(false);

  const onSignUpClick = () => {
    console.log("sign up");
    setSignUpModalState(true);
  }

  const onSignUpClose = () => {
    setSignUpModalState(false);
  }

  // PROFILE MODAL STATE CONTROLS
  const [profileModalState, setProfileModalState] = useState(false);

  const onProfileClick = () => {
    setProfileModalState(true);
  }

  const onProfileClose = () => {
    setProfileModalState(false);
  }

  const onLogOut = () => {
    setAuthUser("");
    setAuthToken("");

    document.cookie = "auth=False; Max-Age=-99999999;";  
  }

  const [restorePasswordModalState, setRestorePasswordModalState] = useState(false);
  const onRestoreModalClose = () => {
    setRestorePasswordModalState(false);
  }

  const onRestoreModalCall = () => {
    setLogInModalState(false);
    setRestorePasswordModalState(true);
  }

  // TECH FUNCTIONS
  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // LANGUAGE
  const [lang, setLang] = useState(1); // 0 - ru, 1 - en, 2 - cn
  function fetchLanguage() {
    let lng = window.navigator.language.split("-")[0];
    if(lng === "ru") {
      setLang(0);
    } else if(lng === "cn") {
      setLang(2);
    }
  }

  return (
    <div className="App__root" style={(signUpModalState || logInModalState) ? {overflowY: 'hidden',height:"calc(100vh - 96px)"} : {}}>
      <Router>
        <NavigationBar
          onLogInClick={onLogInClick}
          onSignUpClick={onSignUpClick}
          onProfileClick={onProfileClick}
          authUser={authUser}
          lang={lang}
          onLangChange={(newl) => setLang(newl)}
        />

        <LogInModal visible={logInModalState} onRequestClose={onLogInClose} handleModeChange={onSignUpClick} onSuccess={onLogInSuccess} onRestorePassword={onRestoreModalCall} lang={lang} />
        <SignUpModal visible={signUpModalState} onRequestClose={onSignUpClose} handleModeChange={onLogInClick} onSuccess={onSignUpSuccess} lang={lang} />
        <ProfileModal visible={profileModalState} onRequestClose={onProfileClose} onLogOut={onLogOut} lang={lang} />

        <RestorePasswordModal
          visible={restorePasswordModalState}
          onRequestClose={onRestoreModalClose}
          lang={lang}
        />

        <Routes>
          <Route exact path="" element={<MainPage token={authToken} lang={lang}/>} />

          {authUser !== "" &&
          <>
            <Route path="account" element={<AccountPage token={authToken} lang={lang} />} >
              <Route index element={<PersonalView lang={lang}/>} />
              <Route path="company" element={<CompanyView lang={lang}/>} />
              <Route path="feedback" element={<FeedbackView lang={lang}/>} />
            </Route>

            <Route path="my" element={<CabinetPage token={authToken} lang={lang} />} />
          </>
          }
        </Routes>
      </Router>
    </div>
  );
}

export default App;
