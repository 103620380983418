import React, { useState } from 'react';

import "./FormComponents.css";

// IMPORT ICONS
import editIcon from "../assets/edit.png";
import deleteIcon from "../assets/delete.png";
import { IoChevronDown } from 'react-icons/io5';

export function InfoField({
    label,
    value,
    onClick,
    secure,
    style,
    editIconHidden=false
}) {
    return(
        <div className="InfoField__container" style={style}>
            <p className="InfoField__label">{label}</p>
            <p className="InfoField__value">{secure ? "**********" : value}</p>
            {!editIconHidden &&
            <img src={editIcon} className="InfoField__edit-icon" onClick={onClick}/>
            }
        </div>
    );
}

export function PlainField({
    value,
    onClick,
    style
}) {
    return(
        <div className="InfoField__container" style={style}>
            <p className="InfoField__value" style={{ marginTop: 11 }}>{value}</p>
            <img src={editIcon} className="InfoField__edit-icon" onClick={onClick}/>
        </div>
    );
}

export function StoreField({
    label,
    value,
    onEdit,
    onDelete,
    style
}) {
    return(
        <div className="StoreField__container" style={style}>
            <p className="InfoField__label">{label}</p>
            <p className="InfoField__value">{value}</p>
            <img src={deleteIcon} className="StoreField__delete-icon" onClick={onDelete}/>
            <img src={editIcon} className="InfoField__edit-icon" onClick={onEdit}/>
        </div>
    );
}

export function DropdownPicker({
    label,
    value,
    items,
    onChange,
    style
}) {
    const [focusState, setFocusState] = useState(false);

    return(
        <div className="DropdownPicker__container" style={style}>
            <p className="DropdownPicker__label">{label}</p>
            <div 
                className="DropdownPicker__wrapper"
                onClick={() => {
                    setFocusState(!focusState);
                }}
            >
                <p className={"DropdownPicker__value"+(value===-1?"-default":"")}>{value === -1 ? "Выберите из списка" : items[value]}</p>
                <IoChevronDown
                    color="#0F62FE"
                    size={16}
                    className={"DropdownPicker__chevron"+(focusState?"-rotated":"")}
                />

                {focusState &&
                <div className="DropdownPicker__list-container">
                    {items.map((item, index) => {
                        return(
                            <div 
                                className="DropdownPicker__list-item-container"
                                onClick={() => onChange(index)}
                                key={index}
                            >
                                <p className="DropdownPicker__item-label">{item}</p>

                                <div className="DropdownPicker__item-divider"/>
                            </div>
                        );
                    })}
                </div>
                }
            </div>
        </div>
    );
}

export function SquareDropdownPicker({
    placeholder,
    value,
    items,
    onChange,
    style,
    textStyle,
    error
}) {
    const [focusState, setFocusState] = useState(false);
    const pickerRef = useOutsideClick(() => setFocusState(false));

    return(
        <div 
            className={"SquarePicker__container"+(error?"-error":"")}
            style={style}
            onClick={() => {
                setFocusState(!focusState);
            }}
            ref={pickerRef}
        >
            {value === -1 ?
            <p className="SquarePicker__placeholder" style={textStyle}>{placeholder}</p>
            :
            <p className="SquarePicker__value" style={textStyle}>{items[value]}</p>
            }

            <IoChevronDown
                color="#0F62FE"
                size={16}
                className={"SquarePicker__chevron"+(focusState ? "-rotated" : "")}
            />

            {focusState &&
            <div className="SquarePicker__list-container">
                <div className="SquarePicker__item-wrapper">
                    {items.map((item, index) => {
                        return(
                            <div 
                                className="SquarePicker__list-item-container"
                                onClick={() => onChange(index)}
                                key={index}
                            >
                                <p className="SquarePicker__item-label">{item}</p>

                                <div className="SquarePicker__item-divider"/>
                            </div>
                        );
                    })}
                </div>
            </div>
            }
        </div>
    );
}

const useOutsideClick = (callback) => {
    const ref = React.useRef();
  
    React.useEffect(() => {
        const handleClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };
  
        document.addEventListener('click', handleClick, true);
  
        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [ref]);
  
    return ref;
};