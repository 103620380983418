import axios from "axios";
import { API_URL } from "./ApiCredentials";

export default class TransactionService {
    constructor() {}

    patchTransactionState(_id, state, token, _callback) {
        const url = `${API_URL}/api/transaction`;
        const headers = {
            'content-type': 'application/json',
            'Authorization': `Token ${token}`
        };

        axios.patch(
            url,
            { transaction: {
                _id: _id,
                state: state
            }},
            { headers: headers }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback([]);
            return;
        });
    }

    addTransaction(buyer, seller, started_by, data, token, _callback) {
        const url = `${API_URL}/api/transactions/add`;
        const headers = {
            'content-type': 'application/json',
            'Authorization': `Token ${token}`
        };

        axios.post(
            url,
            { transaction: {
                buyer: buyer,
                seller: seller,
                started_by: started_by,
                data: data
            } },
            { headers: headers }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback({});
            return;
        });
    }

    getPagedTransactionsList(companyID, page, token, _callback) {
        const url = `${API_URL}/api/transactions/paged_list`;
        const headers = {
            'content-type': 'application/json',
            'Authorization': `Token ${token}`
        };

        axios.get(
            url,
            { headers: headers, params: { page: page, companyID: companyID } }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback([]);
            return;
        });
    }
}