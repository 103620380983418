import React, { useState, useEffect } from 'react';

import "../pagestyles/CabinetPage.css";

// LANGUAGE
import cab_file from "../lang/cabinet.json";

// COMPONENTS
import SliderPicker from '../components/SilderPicker';

// SUBPAGES
import ProductsView, { RequestsView } from './my/RequestsView';
import TransactionsView from './my/TransactionsView';

const cab_lang = cab_file.lang;

export default function CabinetPage({ token, lang=1 }) {
    const [selectedTab, setSelectedTab] = useState(0); // 0 , 1
    const toggleTabSwitch = (value) => {
        setSelectedTab(value);
    }

    return(
        <div className="CabinetPage__root">
            <SliderPicker
                items={[cab_lang[0][lang], cab_lang[1][lang], cab_lang[2][lang]]}
                value={selectedTab}
                onChange={toggleTabSwitch}
                style={{
                    position: 'fixed',
                    alignSelf: 'center',
                    top: 27,
                    zIndex: 8,
                    width: 294
                }}
                labelStyles={[
                    {color: selectedTab===0?"#0F62FE":"#161616", fontWeight: "700"},
                    {color: selectedTab===1?"#0F62FE":"#161616", fontWeight: "700"},
                    {color: selectedTab===2?"#0F62FE":"#161616", fontWeight: "700"},
                ]}
            />

            {selectedTab === 0 ?
            <ProductsView token={token} lang={lang} />
            :
            (selectedTab === 1 ?
            <RequestsView token={token} lang={lang} />    
            :
            <TransactionsView token={token} lang={lang} />
            )
            }
        </div>
    );
}