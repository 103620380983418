import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// IMPORT LANGUAGE
import profile_file from "../lang/profile.json";
import auth_file from "../lang/auth.json";

// IMPORT STYLE
import "./NavigationBar.css";

// IMPORT COMPONENTS
import { BlueButton, GreyButton } from './Buttons.js';

// IMPORT LOGO
import logoImage from "../assets/logo.png";

// IMPORT IMAGES
import { IoPersonCircle } from 'react-icons/io5';
import { AiFillCaretRight } from 'react-icons/ai';

const profile_lang = profile_file.lang;
const auth_lang = auth_file.lang;

export default function NavigationBar({ 
    onLogInClick, 
    onSignUpClick, 
    onProfileClick,
    authUser,
    lang=1,
    onLangChange
}) {
    const location = useLocation();
    const navigate = useNavigate();
    const [locationStr, setLocationStr] = useState("");

    function translatePath(value) {
        switch(value) {
            case "my":
                return profile_lang[2][lang];
            case "account":
                return profile_lang[0][lang];
            case "":
                return profile_lang[3][lang];
            case "company":
                return profile_lang[7][lang];
            case "feedback":
                return profile_lang[4][lang];
            default:
                return "";
        }
    }

    useEffect(() => {
        parsePath(location.pathname);
    }, [location]);

    function parsePath(pathname) {
        let str = "";
        let patharr = pathname.split("/");
        //console.log(patharr);

        str += " / " + translatePath(patharr[1]);
        if(patharr.length > 2) {
            str += " / " + translatePath(patharr[2]);
        }

        if(patharr[patharr.length-1] === 'signup') {
            onSignUpClick();
            navigate('/');
        }

        setLocationStr(str);
        //console.log(str);
    }

    return(
        <div className="NavBar__container">
            

            {authUser === "" ? 
            <>
                <Link to="/" className="NavBar__logo-link">
                    <img src={logoImage} className="NavBar__logo" />
                </Link>

                <div className="NavBar__lang-selector">
                    <p style={{cursor:'pointer'}} onClick={() => onLangChange(0)}>ru</p>|<p style={{cursor:'pointer'}} onClick={() => onLangChange(1)}>en</p>|<p style={{cursor:'pointer'}} onClick={() => onLangChange(2)}>cn</p>
                </div>
                
                <GreyButton 
                    style={{
                        marginRight: "16px",
                        width: "160px"
                    }}
                    text={auth_lang[0][lang]}
                    onClick={onLogInClick} 
                />

                <BlueButton 
                    style={{
                        marginRight: "64px",
                        width: "160px"
                    }}
                    text={auth_lang[1][lang]}
                    onClick={onSignUpClick} 
                />
            </>
            :
            <>
                <Link to="/" className="NavBar__logo-link">
                    <img src={logoImage} className="NavBar__logo" />
                </Link>

                <p className="NavBar__location-content">{locationStr}</p>

                <div className="NavBar__lang-selector">
                    <p style={{cursor:'pointer',lineHeight:"14px"}} onClick={() => onLangChange(0)}>ru</p>|<p style={{cursor:'pointer',lineHeight:"14px"}} onClick={() => onLangChange(1)}>en</p>|<p style={{cursor:'pointer',lineHeight:"14px"}} onClick={() => onLangChange(2)}>cn</p>
                </div>
                
                <div className="NavBar__user-container" onClick={onProfileClick}>
                    <IoPersonCircle
                        color="#000"
                        size={24}
                    />
                    <p className="NavBar__user-name">{authUser}</p>
                    <AiFillCaretRight
                        color="#000"
                        size={15}
                        className="NavBar__chevron"
                    />
                </div>
            </>
            }
        </div>
    );
}