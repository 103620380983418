import React, { useEffect, useState } from 'react';

// IMPORT STYLES
import "./TextInputs.css";

// IMPORT IMAGES
import { IoEye, IoEyeOff, IoChevronDown, IoAlertCircle } from 'react-icons/io5';

export function TextField({
    label,
    placeholder,
    value,
    onChangeText,
    style,
    maxLength,
    errorMessage,
    error,
    disabled,
    onFocus,
    onBlur,
    inputWidth=null,
    indicatorShown=true
}) {
    return(
        <div className="TextField__container" style={style}>
            <p className="TextField__label">{label}</p>
            <input 
                disabled={disabled}
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={(event) => onChangeText(event.target.value)}
                className="TextField__input"
                maxLength={maxLength ? maxLength : 30}
                style={error ? {
                    backgroundColor: "#FFF1F1",
                    width: (inputWidth !== null ? inputWidth : "100%")
                }:{
                    width: (inputWidth !== null ? inputWidth : "100%")
                }}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            {indicatorShown && <IoAlertCircle size={"24px"} color="#DA1E28" className={"TextField__error-icon"+(error ? "" : "-hidden")} />}
            <p className={"TextField__error-message"+(error ? "" : "-hidden")}>{errorMessage}</p> 
        </div>
    );
}

export function SecureTextField({
    label,
    placeholder,
    value,
    onChangeText,
    style,
    maxLength,
    errorMessage,
    error,
    inputWidth=null
}) {
    const [visibility, setVisibility] = useState(false);

    const toggleVisibility = () => {
        setVisibility(!visibility);
    }

    return(
        <div className="SecureTextField__container" style={style}>
            <p className="SecureTextField__label">{label}</p>
            <div className={"SecureTextField__input-container"+(error ? "-error" : "")}>
                <input 
                    type={visibility ? "text" : "password"}
                    placeholder={placeholder}
                    value={value}
                    onChange={(event) => onChangeText(event.target.value)}
                    className="SecureTextField__input"
                    maxLength={maxLength ? maxLength : 30}
                    style={error ? {
                        backgroundColor: "#FFF1F1",
                        width: (inputWidth !== null ? inputWidth : "auto")
                    }:{}}
                />

                {!visibility ? 
                <IoEye
                    className="SecureTextField__eye"
                    onClick={toggleVisibility}
                    color="#525252"
                />
                :
                <IoEyeOff
                    className="SecureTextField__eye"
                    onClick={toggleVisibility}
                    color="#525252"
                />
                }
            </div>
            <p className={"TextField__error-message"+(error ? "" : "-hidden")}>{errorMessage}</p> 
        </div>
    );
}

export function NumberField({
    label,
    placeholder,
    value,
    onChangeText,
    countryCodes,
    onCodeChange,
    style,
    errorMessage,
    error
}) {
    const [countryCode, setCountryCode] = useState(0);
    const [selectorOpened, setSelectorOpened] = useState(false);
    const [selectorDisplayed, setSelectorDisplayed] = useState(false);

    // number listener
    useEffect(() => {
        if(value.length === 3) {
            onChangeText(`${value} `);
        } else if(value.length === 7) {
            onChangeText(`${value}-`);
        } else if(value.length === 10) {
            onChangeText(`${value}-`);
        }
    }, [value]);

    useEffect(() => {
        onCodeChangeFunc(countryCodes[countryCode].split(" ")[0]);
    }, [countryCode]);

    function onCodeChangeFunc(value) {
        onCodeChange(value);
    }

    const toggleSelector = async () => {
        if(selectorOpened) {
            setSelectorDisplayed(false);
            await timeout(400);
            setSelectorOpened(false);
        } else {
            setSelectorOpened(true);
            setSelectorDisplayed(true);
        }
    }

    function timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    return(
        <div className="NumberField__container" style={style}>
            <p className="NumberField__label">{label}</p>
            <div className={"NumberField__input-container"+(error ? "-error" : "")}>
                <div className="NumberField__code-button" onClick={toggleSelector}>
                    <p className="NumberField__code-value">{countryCodes[countryCode].split(" ")[0]}</p>
                    <IoChevronDown
                        className={selectorDisplayed ? "NumberField__code-button-chevron-rotate" : "NumberField__code-button-chevron"}
                    />
                </div>

                <input
                    className="NumberField__input"
                    type="text"
                    value={value}
                    onChange={(event) => onChangeText(event.target.value)}
                    placeholder={placeholder}
                    maxLength={13}
                    style={error ? {
                        backgroundColor: "#FFF1F1"
                    }:{}}
                />

                <div 
                    className={selectorDisplayed ? "NumberField__code-selector" : "NumberField__code-selector-hidden"}
                >
                    {countryCodes.map((code, index) => {
                        return(
                            <div 
                                key={index}
                                className="NumberField__code-selector-item"
                                onClick={() => {
                                    setCountryCode(index);
                                    toggleSelector();
                                }}
                            >
                                <p className="NumberField__code-selector-item-label">{code}</p>
                                <div className="NumberField__code-selector-divider"/>
                            </div>
                        );
                    })}
                </div>

                <IoAlertCircle size={"24px"} color="#DA1E28" className={"NumberField__error-icon"+(error ? "" : "-hidden")} />
            </div>
            <p className={"TextField__error-message"+(error ? "" : "-hidden")}>{errorMessage}</p> 
        </div>
    );
}

export function MultilineTextField({
    label,
    placeholder,
    value,
    onChangeText,
    style,
    errorMessage,
    error,
    disabled,
    onFocus,
    onBlur,
    inputWidth,
    indicatorShown=true
}) {
    return(
        <div className="MultilineTextField__container" style={style}>
            <p className="MultilineTextField__label">{label}</p>
            <textarea 
                disabled={disabled}
                placeholder={placeholder}
                value={value}
                onChange={(event) => onChangeText(event.target.value)}
                className="MultilineTextField__input"
                maxLength={10000}
                style={error ? {
                    backgroundColor: "#FFF1F1",
                    width: inputWidth
                }:{
                    width: inputWidth
                }}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            {indicatorShown && <IoAlertCircle size={"24px"} color="#DA1E28" className={"TextField__error-icon"+(error ? "" : "-hidden")} />}
            <p className={"TextField__error-message"+(error ? "" : "-hidden")}>{errorMessage}</p> 
        </div>
    );
}