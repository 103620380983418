import axios from "axios";
import { API_URL } from "./ApiCredentials";

export default class ProductionService {
    constructor() {}

    addProduction(data, token, _callback) {
        const url = `${API_URL}/api/productions/add`;
        const headers = {
            'content-type': 'application/json',
            'Authorization': `Token ${token}`
        };

        axios.post(
            url,
            { "production": data },
            { headers: headers }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback({});
            return;
        });
    }

    deleteProduction(data, token, _callback) {
        const url = `${API_URL}/api/production`;
        const headers = {
            'content-type': 'application/json',
            'Authorization': `Token ${token}`
        };

        axios.delete(
            url,
            { 
                headers: headers,
                data: { "production": data }
            }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback({});
            return;
        });
    }

    patchProduction(data, token, _callback) {
        const url = `${API_URL}/api/production`;
        const headers = {
            'content-type': 'application/json',
            'Authorization': `Token ${token}`
        };

        axios.patch(
            url,
            { "production": data },
            { 
                headers: headers
            }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback({});
            return;
        });
    }

    getProductionsList(token, _callback) {
        const url = `${API_URL}/api/productions/list`;
        let headers = {
            'content-type': 'application/json'
        };

        if(token !== "") {
            headers = Object.assign(headers, {'Authorization': `Token ${token}`});
        }

        axios.get(
            url,
            { headers: headers }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback([]);
            return;
        });
    }

    getPagedProductionsList(token, page, _callback) {
        const url = `${API_URL}/api/productions/paged_list?page=${page}`;
        let headers = {
            'content-type': 'application/json'
        };

        if(token !== "") {
            headers = Object.assign(headers, {'Authorization': `Token ${token}`});
        }

        axios.get(
            url,
            { headers: headers }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback([]);
            return;
        });
    }

    getPagedFilteredProductionsList(token, filters, page, _callback) {
        const url = `${API_URL}/api/productions/filtered_paged_list?page=${page}`;
        let headers = {
            'content-type': 'application/json'
        };

        if(token !== "") {
            headers = Object.assign(headers, {'Authorization': `Token ${token}`});
        }

        axios.post(
            url,
            { "filters": filters },
            { headers: headers }
        ).then(response => {
            _callback(response.data);
            return;
        }).catch(e => {
            console.log(e);
            _callback([]);
            return;
        });
    }
}